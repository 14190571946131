import React, { useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import "../../assets/scss/theme-base/ModelAddConstruction.css";
import { Paginator } from "primereact/paginator";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import Intersection from "../Plan/intersection";
import { DataTable } from "primereact/datatable";
import { Image } from "primereact/image";
import { statusFilterTemplate } from "../../constants/status";
import { statusTemplate } from "../../components/utils";
import FormatDate from "../Report/FormatDate";

const defaultImageUrl = "https://static.thenounproject.com/png/1269202-200.png";

export const imageTemplate = (rowData) => {
    return (
        <Image
            src={`${rowData?.categoryImage}` || defaultImageUrl}
            alt="Category Image"
            imageStyle={{ borderRadius: "20%", width: "40px", height: "40px" }}
            preview
            onError={(e) => (e.target.src = defaultImageUrl)}
        />
    );
};
export const teamImageTemplate = (rowData) => {
    return rowData.teamImage ? (
        <Image
            src={`${rowData?.teamImage}` || defaultImageUrl}
            alt="Category Image"
            imageStyle={{ borderRadius: "20%", width: "40px", height: "40px" }}
            preview
            onError={(e) => (e.target.src = defaultImageUrl)}
        />
    ) : (
        <></>
    );
};

const ModalFilterCategoryReportTechnical = ({ visible, onHide, data }) => {
    const [dialogLocationVisible, setDialogLocationVisible] = useState(false);
    const [selectFactoryCode, setSelectFactoryCode] = useState(null);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [selectedProducts, setSelectedProducts] = useState(null);

    const handleTeamProgress = (rowData) => {
        setSelectFactoryCode(rowData.factoryCode);
        setDialogLocationVisible(true);
    };
    const onPageChange = (event) => {
        const newFirst = event.first;
        const newRows = event.rows;

        setFirst(newFirst);
        setRows(newRows);
    };

    const paginatedData = data?.slice(first, first + rows);

    return (
        <Dialog
            visible={visible}
            onHide={() => {
                setFirst(0);
                onHide();
            }}
            className="responsive-dialog"
            header="Lọc theo hạng mục"
            modal
            style={{ width: "80vw", height: "80vh" }}
        >
            <div className="p-3 border-round-md">
                <div>
                    <DataTable
                        rowClassName="custom-row-class-factory-inprogress"
                        size="small"
                        value={paginatedData}
                        emptyMessage="Không có dữ liệu"
                        scrollable
                        scrollHeight="550px"
                    >
                        <Column
                            header="STT"
                            body={(rowData, options) => (
                                <div className="text-center">
                                    {first + options.rowIndex + 1}
                                </div>
                            )}
                            style={{ width: "3%" }}
                            alignHeader={"center"}
                            align={"center"}
                        ></Column>
                        <Column
                            header="Tên công trình"
                            field="Building.name"
                            style={{ minWidth: "150px" }}
                            filter
                        ></Column>
                        <Column
                            header="Mã ĐH"
                            style={{ minWidth: "100px" }}
                            filter
                            field="orderCode"
                        ></Column>
                        <Column
                            header="Mã nhà máy"
                            field="factoryCode"
                            style={{ minWidth: "120px" }}
                            filter
                        ></Column>
                        <Column
                            header="Hạng mục"
                            style={{
                                lineHeight: "1.5",
                                minWidth: "200px",
                            }}
                            field="categoryName"
                            filter
                        ></Column>
                        <Column
                            header="Hình ảnh"
                            style={{ minWidth: "100px" }}
                            body={imageTemplate}
                            alignHeader={"center"}
                            align={"center"}
                        ></Column>
                        <Column
                            header="SL"
                            field="quantity"
                            style={{ minWidth: "70px" }}
                        ></Column>
                        <Column
                            header="ĐVT"
                            field="unit"
                            filter
                            style={{ minWidth: "50px" }}
                        ></Column>
                        <Column
                            header="Mã SP"
                            field="productCode"
                            style={{ minWidth: "100px" }}
                            filter
                        ></Column>
                        <Column
                            header="Kế hoạch hoàn thành"
                            field="intendFinishDate"
                            style={{ minWidth: "120px" }}
                            body={(rowData) =>
                                FormatDate(rowData.intendFinishDate)
                            }
                            sortable
                            align="center"
                            alignHeader="center"
                        ></Column>
                        <Column
                            header="Trạng thái"
                            field="status"
                            body={statusTemplate}
                            alignHeader={"center"}
                            align={"center"}
                            style={{ minWidth: "150px" }}
                            filter
                            filterElement={statusFilterTemplate}
                            pt={{ filterButtonbar: { className: "hidden" } }}
                        ></Column>
                        <Column
                            header="Ngày đặt vật tư"
                            field="materialOrderingDate"
                            style={{ minWidth: "150px" }}
                            body={(rowData) =>
                                FormatDate(rowData.materialOrderingDate)
                            }
                            sortable
                            align="center"
                            alignHeader="center"
                        ></Column>
                        <Column
                            header="Ngày đặt hàng gia công"
                            field="machiningOrderingDate"
                            style={{ minWidth: "150px" }}
                            body={(rowData) =>
                                rowData.notMachining
                                    ? "Không cần hàng gia công"
                                    : FormatDate(rowData.machiningOrderingDate)
                            }
                            sortable
                            align="center"
                            alignHeader="center"
                        ></Column>
                        <Column
                            header="Ngày ra BOM"
                            field="bomProductionDate"
                            style={{ minWidth: "120px" }}
                            body={(rowData) =>
                                FormatDate(rowData.bomProductionDate)
                            }
                            sortable
                            align="center"
                            alignHeader="center"
                        ></Column>
                        <Column
                            header="Định vị"
                            body={(rowData) => (
                                <div className="text-center">
                                    <Button
                                        onClick={() =>
                                            handleTeamProgress(rowData)
                                        }
                                        icon="pi pi-map-marker"
                                        rounded
                                        text
                                        severity="secondary"
                                        aria-label="Bookmark"
                                        style={{ color: "black" }}
                                    />
                                    <p>{rowData.teamWorking}</p>
                                </div>
                            )}
                            alignHeader={"center"}
                            align={"center"}
                            style={{ minWidth: "100px" }}
                        ></Column>
                        <Column
                            header="Ghi chú"
                            field="technicalNote"
                            style={{ minWidth: "200px" }}
                        ></Column>
                    </DataTable>
                    <Paginator
                        first={first}
                        rows={rows}
                        totalRecords={data?.length}
                        rowsPerPageOptions={[10, 20, 30, 100, 200, 500]}
                        onPageChange={onPageChange}
                    />
                    <Intersection
                        visible={dialogLocationVisible}
                        onHide={() => setDialogLocationVisible(false)}
                        factoryCode={selectFactoryCode}
                    />
                </div>
            </div>
        </Dialog>
    );
};

export default ModalFilterCategoryReportTechnical;
