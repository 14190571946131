import FormatDate from '../Report/FormatDate';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import html2canvas from 'html2canvas';
import logo from '../../assets/images/logo.png';
import QRCodeWithBase64 from '../Report/QRCodeWithBase64';
import ReactDOM from 'react-dom';
import ValueQRCodeUrl from '../Report/ValueQR';

pdfMake.vfs = pdfFonts.pdfMake ? pdfFonts.pdfMake.vfs : pdfFonts.vfs;

const getBase64Image = (img) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(img);
  });
};
const fetchImageAsBase64 = async (imagePath) => {
  const response = await fetch(imagePath);
  const blob = await response.blob();
  const base64Image = await getBase64Image(blob);
  return base64Image;
};
const generateQRCodeBase64 = (value) => {
  return new Promise((resolve) => {
    const qrContainer = document.createElement('div');
    ReactDOM.render(
      <QRCodeWithBase64
        value={value}
        size={200}
        onRenderAsImage={resolve}
      />,
      qrContainer
    );
  });
};

const prepareDataTableWithImages = async (datatable) => {
  const updatedDataTable = await Promise.all(
    datatable.map(async (product) => {
      const qrImage = await generateQRCodeBase64(ValueQRCodeUrl(product));
      return {
        ...product,
        qrCode: qrImage,
      };
    })
  );
  return updatedDataTable;
};

export const exportTeamPDF = async (
  action,
  startDate,
  endDate,
  productInprogress,
  dataDetail,
  buildingName
) => {
  const imagePath = logo;

  const logoBase64 = await fetchImageAsBase64(imagePath);
  const columnChartElement = document.getElementById('column-chart');

  const updateProductInprogress = await prepareDataTableWithImages(
    productInprogress
  );
  const updateDetail = await prepareDataTableWithImages(dataDetail);
  const captureElementAsImage = async (element) => {
    if (!element) return null;
    const canvas = await html2canvas(element);
    return canvas.toDataURL('image/png');
  };

  const [columnChartImgData] = await Promise.all([
    captureElementAsImage(columnChartElement),
  ]);
  const docDefinition = {
    pageSize: { width: 1400, height: 900 },
    pageMargins: [-30, 60, 0, 60],
    content: [
      {
        image: logoBase64,
        width: 40,
        absolutePosition: { x: 40, y: 50 },
      },
      { text: buildingName, absolutePosition: { x: 100, y: 60 } },
      {
        text: 'Báo cáo tổ đội',
        style: 'header',
        fontSize: 28,
        margin: [0, 0, 0, 20],
      },
      {
        text: `Từ ngày: ${startDate} ~ Đến ngày: ${endDate}`,
        alignment: 'center',
        fontSize: 14,
        margin: [0, 0, 0, 0],
      },
      {
        image: columnChartImgData,
        margin: [0, 0, 0, 20],
        alignment: 'center',
      },
      {
        text: 'Đang thực hiện',
        style: 'subheader',
        margin: [0, 0, 0, 5],
        bold: true,
        fontSize: 14,
        alignment: 'center',
      },
      {
        table: {
          headerRows: 1,
          dontBreakRows: true,
          keepWithHeaderRows: 1,
          widths: ['auto', 100, 900, 100, 100],
          body: [
            [
              {
                text: 'STT',
                bold: true,
                alignment: 'center',
                fillColor: '#2980BA',
                color: '#ffffff',
              },
              {
                text: 'Tổ đội',
                bold: true,
                fillColor: '#2980BA',
                color: '#ffffff',
                alignment: 'center',
              },
              {
                text: 'Mã nhà máy',
                bold: true,
                fillColor: '#2980BA',
                color: '#ffffff',
              },
              {
                text: 'Định vị',
                bold: true,
                fillColor: '#2980BA',
                color: '#ffffff',
                alignment: 'center',
              },
              {
                text: 'QR Code', // Cột QR
                bold: true,
                fillColor: '#2980BA',
                color: '#ffffff',
                alignment: 'center',
              },
            ],
            ...updateProductInprogress?.map((product, index) => [
              { text: index + 1, alignment: 'center' },
              {
                text: product.name,
                alignment: 'center',
              },
              { text: product.factoryCode },
              { text: product.teamWorking },
              {
                image: product.qrCode,
                width: 60,
                height: 60,
                fit: [60, 60],
                alignment: 'center',
                margin: [0, 10, 0, 10],
              },
            ]),
          ],
        },

        margin: [100, 0, 0, 5],
      },
      {
        text: 'Chi tiết tổ đội sản xuất',
        style: 'subheader',
        pageBreak: 'before',
        margin: [0, 0, 0, 5],
        bold: true,
        fontSize: 14,
        alignment: 'center',
      },
      {
        table: {
          headerRows: 1,
          dontBreakRows: true,
          keepWithHeaderRows: 1,
          widths: ['auto', 150, 200, 100, 100, 100, 50, 50, 200, 100, 100],
          body: [
            [
              {
                text: 'STT',
                bold: true,
                alignment: 'center',
                fillColor: '#2980BA',
                color: '#ffffff',
              },
              {
                text: 'Công trình',
                alignment: 'center',
                bold: true,
                fillColor: '#2980BA',
                color: '#ffffff',
              },
              {
                text: 'Hạng mục',
                bold: true,
                fillColor: '#2980BA',
                color: '#ffffff',
              },
              {
                text: 'Mã nhà máy',
                bold: true,
                fillColor: '#2980BA',
                color: '#ffffff',
                alignment: 'center',
              },
              {
                text: 'Từ ngày',
                bold: true,
                fillColor: '#2980BA',
                color: '#ffffff',
                alignment: 'center',
              },
              {
                text: 'Đến ngày',
                bold: true,
                fillColor: '#2980BA',
                color: '#ffffff',
                alignment: 'center',
              },
              {
                text: 'SL',
                bold: true,
                fillColor: '#2980BA',
                color: '#ffffff',
                alignment: 'center',
              },
              {
                text: 'ĐVT',
                bold: true,
                fillColor: '#2980BA',
                color: '#ffffff',
                alignment: 'center',
              },
              {
                text: 'Trạng thái',
                bold: true,
                fillColor: '#2980BA',
                color: '#ffffff',
                alignment: 'center',
              },
              {
                text: 'Định vị',
                bold: true,
                fillColor: '#2980BA',
                color: '#ffffff',
                alignment: 'center',
              },
              {
                text: 'QR Code', // Cột QR
                bold: true,
                fillColor: '#2980BA',
                color: '#ffffff',
                alignment: 'center',
              },
            ],
            ...updateDetail?.map((product, index) => [
              { text: index + 1, alignment: 'center' },
              {
                text: product.buildingName,
                alignment: 'center',
              },
              { text: product.categoryName },
              { text: product.factoryCode, alignment: 'center' },
              {
                text: FormatDate(product.timeStart),
                alignment: 'center',
              },
              {
                text: FormatDate(product.timeEnd),
                alignment: 'center',
              },
              { text: product.quantityMade, alignment: 'center' },
              { text: product.unit, alignment: 'center' },
              { text: product.status, alignment: 'center' },
              { text: product.teamWorking, alignment: 'center' },
              {
                image: product.qrCode,
                width: 60,
                height: 60,
                fit: [60, 60],
                alignment: 'center',
                margin: [0, 10, 0, 10],
              },
            ]),
          ],
        },
        margin: [100, 0, 0, 5],
      },
    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        alignment: 'center',
      },
    },
    defaultStyle: {
      font: 'Roboto',
    },
  };

  const pdfDocGenerator = pdfMake.createPdf(docDefinition);

  if (action === 'print') {
    pdfDocGenerator.getBlob((blob) => {
      const url = URL.createObjectURL(blob);
      const newWindow = window.open(url, '_blank');
      if (newWindow) {
        newWindow.onload = () => {
          newWindow.print();
        };
      }
    });
  } else if (action === 'save') {
    pdfDocGenerator.download('team.pdf');
  }
};
