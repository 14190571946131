import React from "react";
import { MultiSelect } from "primereact/multiselect";

const CustomMultiSelect = ({
    value,
    onChange,
    placeholder = "Trạng thái",
    // style = { maxWidth: "36rem" },
    className = "p-column-filter",
    showClear = true,
    display = "chip",
}) => {
    const statuses = [
        { label: "Chưa có BOM", value: "Chưa có BOM" },
        { label: "Chưa sản xuất", value: "Chưa sản xuất" },
        { label: "Đang sản xuất", value: "Đang sản xuất" },
        { label: "Hoàn thành đúng tiến độ", value: "Hoàn thành đúng tiến độ" },
        { label: "Hoàn thành trễ tiến độ", value: "Hoàn thành trễ tiến độ" },
        { label: "Trễ tiến độ", value: "Trễ tiến độ" },
    ];
    // Hàm xử lý onChange
    const handleOnChange = (e) => {
        const selectedValue = e.value; // Lấy giá trị được chọn
        if (onChange) {
            onChange(selectedValue);
        }
    };

    return (
        <MultiSelect
            value={value}
            options={statuses}
            onChange={handleOnChange}
            placeholder={placeholder}
            // style={style}
            className={className}
            showClear={showClear}
            display={display}
        />
    );
};

export default CustomMultiSelect;
