import { all, fork } from 'redux-saga/effects';

import AuthSaga from './auth/saga';
import ProductionSaga from './order/saga';
import ConstructionSaga from './construction/saga';
import CategorySaga from './category/saga';
import TeamsSaga from './teams/saga';
import DashboardFactory from './dashboard/saga';
import DashboardTeams from './dashboardTeams/saga';
import DashboardPlan from './dashboardPlan/saga';
import DashboardTechnical from './dashboardTechnical/saga';
import DashboardQuantity from './dashboardQuantity/saga';
import PermissionSaga from './permission/saga';
import Product from './product/saga';
import ReportQualitySaga from './quality/saga';
import ExcelSaga from './Excels/saga';

export default function* rootSaga() {
  yield all([
    fork(AuthSaga),
    fork(ProductionSaga),
    fork(ConstructionSaga),
    fork(CategorySaga),
    fork(TeamsSaga),
    fork(DashboardFactory),
    fork(DashboardTeams),
    fork(DashboardPlan),
    fork(DashboardTechnical),
    fork(DashboardQuantity),
    fork(PermissionSaga),
    fork(Product),
    fork(ReportQualitySaga),
    fork(ExcelSaga),
  ]);
}
