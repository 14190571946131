import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import FormatDate from "./FormatDate";
import "./ReportFactory.scss";
import { statusTemplate } from "../../components/utils";
import LoadingSpinner from "./LoadingSpinner";
import { Button } from "primereact/button";
import Intersection from "../Plan/intersection";
import { Image } from "primereact/image";
import { FilterMatchMode } from "primereact/api";
import { Paginator } from "primereact/paginator";
import { statusFilterTemplate } from "../../constants/status";
import ModalFilterCategoryFactory from "./ModalFilterCategoryFactory";
import QR from "../Plan/QR";
import CustomMultiSelect from "../../constants/CustomMultiSelect";
import { resetPagination } from "../../store/dashboard/slice";
import { useDispatch } from "react-redux";

const defaultImageUrl = "https://static.thenounproject.com/png/1269202-200.png";

export const imageTemplate = (rowData) => {
    return (
        <Image
            src={`${rowData?.categoryImage}` || defaultImageUrl}
            alt="Category Image"
            imageStyle={{ borderRadius: "20%", width: "40px", height: "40px" }}
            preview
            onError={(e) => (e.target.src = defaultImageUrl)}
        />
    );
};

export const teamImageTemplate = (rowData) => {
    return rowData.teamImage ? (
        <Image
            src={`${rowData?.teamImage}` || defaultImageUrl}
            alt="Category Image"
            imageStyle={{ borderRadius: "20%", width: "40px", height: "40px" }}
            preview
            onError={(e) => (e.target.src = defaultImageUrl)}
        />
    ) : (
        <></>
    );
};

const ProductInprogress = ({
    productInprogressFactory,
    onPaginationChange,
    startDate,
    endDate,
    selectedStatusProductInprogress,
    setSelectedStatusProductInprogress,
}) => {
    const dispatch = useDispatch();
    const [dialogLocationVisible, setDialogLocationVisible] = useState(false);
    const [selectFactoryCode, setSelectFactoryCode] = useState(null);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [openModal, setOpenModal] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [qrDetail, setQrDetail] = useState(null);
    const [visible3, setVisible3] = useState(false);
    // const [selectedStatuses, setSelectedStatuses] = useState([]);

    const handleStatusChange = (newStatuses) => {
        dispatch(resetPagination());
        setSelectedStatusProductInprogress(newStatuses);
    };

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        "Building.buildingCode": {
            value: null,
            matchMode: FilterMatchMode.CONTAINS,
        },
        categoryName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        factoryCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
        unit: { value: null, matchMode: FilterMatchMode.CONTAINS },
        quantity: { value: null, matchMode: FilterMatchMode.CONTAINS },
        intendFinishDate: { value: null, matchMode: FilterMatchMode.DATE_IS },
        status: { value: [], matchMode: FilterMatchMode.IN },
    });

    const handleTeamProgress = (rowData) => {
        setSelectedRow(rowData);
        setSelectFactoryCode(rowData.factoryCode);
        setDialogLocationVisible(true);
    };

    const onPageChange = (event) => {
        const newFirst = event.first;
        const newRows = event.rows;

        setFirst(newFirst);
        setRows(newRows);
        if (onPaginationChange) {
            onPaginationChange(newFirst, newRows);
        }
    };

    const handleDetailScanned = (detail) => {
        setQrDetail(detail);
    };

    const handleSelectionChange = (e) => {
        const selected = e.value || [];
        const currentPageItems = productInprogressFactory?.data?.items || [];

        // Loại bỏ các phần tử thuộc trang hiện tại khỏi selectedProducts
        const filteredProducts =
            selectedProducts?.filter(
                (item) => !currentPageItems.some((row) => row.id === item.id)
            ) || [];

        // Gộp các phần tử không bị xóa với phần tử vừa chọn
        const updatedSelection = [...filteredProducts, ...selected];

        // Loại bỏ phần tử trùng lặp
        const uniqueSelection = Array.from(
            new Map(updatedSelection.map((item) => [item.id, item])).values()
        );

        setSelectedProducts(uniqueSelection);
    };

    return (
        <div className="py-4">
            <div className="flex md:align-items-center mb-2 md:justify-content-between flex-column md:flex-row gap-4">
                <p
                    style={{
                        color: "#5c5c5c",
                        fontWeight: "700",
                        // marginBottom: '2rem',
                    }}
                >
                    Danh sách hạng mục đang thực hiện
                </p>
                <div className="flex gap-4">
                    <CustomMultiSelect
                        value={selectedStatusProductInprogress}
                        onChange={handleStatusChange}
                    />
                    <Button
                        label="Lọc theo hạng mục"
                        onClick={() => setOpenModal(true)}
                    ></Button>
                </div>
            </div>

            {productInprogressFactory && productInprogressFactory.data ? (
                <div>
                    <DataTable
                        // paginator
                        // rows={10}
                        // rowsPerPageOptions={[10, 25, 50, 100, 500]}
                        rowClassName="custom-row-class-factory-inprogress"
                        size="small"
                        value={productInprogressFactory?.data?.items}
                        emptyMessage="Không có dữ liệu"
                        scrollable
                        scrollHeight="400px"
                        filters={filters}
                        onFilter={(e) => setFilters(e.filters)}
                        selection={selectedProducts || []}
                        onSelectionChange={handleSelectionChange}
                        dataKey="id"
                        selectionMode="checkbox"
                    >
                        <Column selectionMode="multiple" />
                        <Column
                            header="STT"
                            body={(rowData, options) => (
                                <div className="text-center">
                                    {first + options.rowIndex + 1}
                                </div>
                            )}
                            style={{ width: "3%" }}
                            alignHeader={"center"}
                            align={"center"}
                        ></Column>
                        <Column
                            header="Tên công trình"
                            field="Building.name"
                            style={{ minWidth: "150px" }}
                            filter
                        ></Column>
                        <Column
                            header="Hạng mục"
                            field="categoryName"
                            style={{ lineHeight: "1.5", minWidth: "200px" }}
                            filter
                        ></Column>
                        <Column
                            header="Hình ảnh"
                            body={imageTemplate}
                            alignHeader={"center"}
                            align={"center"}
                            style={{ minWidth: "100px" }}
                        ></Column>
                        <Column
                            header="Mã nhà máy"
                            field="factoryCode"
                            style={{ minWidth: "150px" }}
                            filter
                        ></Column>
                        <Column
                            header="ĐVT"
                            field="unit"
                            style={{ minWidth: "50px" }}
                            filter
                        ></Column>
                        <Column
                            header="SL"
                            field="quantity"
                            style={{ minWidth: "70px" }}
                        ></Column>
                        <Column
                            header="Kế hoạch hoàn thành"
                            field="intendFinishDate"
                            body={(rowData) => (
                                <div
                                    style={{
                                        backgroundColor: `${rowData.color}`,
                                        padding: "4px 0",
                                        margin: "0 26px",
                                        borderRadius: "5px",
                                    }}
                                >
                                    {FormatDate(rowData.intendFinishDate)}
                                </div>
                            )}
                            style={{ minWidth: "120px" }}
                            align="center"
                            alignHeader="center"
                            sortable
                        ></Column>
                        <Column
                            header="Trạng thái"
                            field="status"
                            body={statusTemplate}
                            alignHeader={"center"}
                            align={"center"}
                            style={{ minWidth: "100px" }}
                            // filter
                            // filterElement={statusFilterTemplate}
                            // pt={{ filterButtonbar: { className: "hidden" } }}
                            className="hidden-button-filter"
                        ></Column>
                        <Column
                            header="Hình ảnh SX"
                            body={teamImageTemplate}
                            alignHeader={"center"}
                            align={"center"}
                            style={{ minWidth: "100px" }}
                        ></Column>
                        <Column
                            header="Định vị"
                            body={(rowData) => (
                                <div className="text-center">
                                    <Button
                                        onClick={() =>
                                            handleTeamProgress(rowData)
                                        }
                                        icon="pi pi-map-marker"
                                        rounded
                                        text
                                        severity="secondary"
                                        aria-label="Bookmark"
                                        style={{ color: "black" }}
                                    />
                                    <p>
                                        {rowData.status !==
                                            "Hoàn thành đúng tiến độ" &&
                                        rowData.status !==
                                            "Hoàn thành trễ tiến độ"
                                            ? rowData.teamWorking
                                            : "Đã giao"}
                                    </p>
                                </div>
                            )}
                            alignHeader={"center"}
                            align={"center"}
                            style={{ minWidth: "100px" }}
                        ></Column>
                        <Column
                            header="In QR"
                            body={(rowData) => (
                                <div className="text-center">
                                    <Button
                                        onClick={() => {
                                            setQrDetail(rowData);
                                            setVisible3(true);
                                        }}
                                        icon="pi pi-qrcode"
                                        rounded
                                        text
                                        severity="secondary"
                                        aria-label="QR"
                                        style={{ color: "black" }}
                                    />
                                </div>
                            )}
                            style={{ width: "8%", minWidth: "100px" }}
                            alignHeader={"center"}
                            align={"center"}
                        ></Column>
                        <Column
                            header="Ghi chú"
                            field="productNote"
                            style={{ minWidth: "200px" }}
                        ></Column>
                    </DataTable>
                    <Paginator
                        first={first}
                        rows={rows}
                        totalRecords={
                            productInprogressFactory?.data?.totalItems
                        }
                        rowsPerPageOptions={[10, 20, 30, 100, 200, 500]}
                        onPageChange={onPageChange}
                    />
                    {dialogLocationVisible && (
                        <Intersection
                            visible={dialogLocationVisible}
                            onHide={() => setDialogLocationVisible(false)}
                            factoryCode={selectFactoryCode}
                            rowData={selectedRow}
                        />
                    )}
                    <ModalFilterCategoryFactory
                        visible={openModal}
                        onHide={() => setOpenModal(false)}
                        data={selectedProducts}
                    />
                    <QR
                        isShow={visible3}
                        hiden={() => setVisible3(false)}
                        detail={qrDetail}
                        onDetailScanned={handleDetailScanned}
                    />
                </div>
            ) : (
                <LoadingSpinner />
            )}
        </div>
    );
};

export default ProductInprogress;
