import Report from '../pages/Report';
import Order from '../pages/Plan';
import Construction from '../pages/Construction';
import Production from '../pages/Quantity';
import Teams from '../pages/Teams';
import Users from '../pages/Users';
import Login from '../pages/Authentication/Login';
import Permission from '../pages/Permission';
import ReportTeam from '../pages/ReportTeam';
import ReportTechnical from '../pages/ReportTechnical';
import Construction1 from '../pages/ReportConstruction';
import ReportPlan from '../pages/ReportPlan';
import ReportQuantity from '../pages/ReportQuantity';
import Technique from '../pages/Technique';
import Manufacture from '../pages/Production';
import ModalProjectDelivery from '../pages/Plan/ModalProjectDelivery';
import ModalProjectDeliveryTeam from '../pages/Teams/ModalProjectDeliveryTeam';
import ModalProjectDeliveryNote from '../pages/Teams/ModalProjectDeliveryNote';
// import Account from '../pages/Account';
import Import from '../pages/Import';
import QualityReportPage from '../pages/Import/QualityReportPage';
import Locate1 from '../pages/Plan/Locate1';
import ImportConstruction from '../pages/Import/ImportConstruction';
import CustomerPermission from '../pages/Permission/CustomerPermission';

//Public Routes
const publicRoutes = [
  { path: '/', component: Login },
  { path: '/modalprojectdeliveryteam', component: ModalProjectDeliveryTeam },
  { path: '/modalprojectdeliverynote', component: ModalProjectDeliveryNote },
  { path: '/QualityReportPage', component: QualityReportPage },
  { path: '/Locate1', component: Locate1 },
  { path: '/import', component: Import },
  { path: '/modalprojectdelivery', component: ModalProjectDelivery },
];

//Private Routes
const privateRoutes = [
  { path: '/importconstruction', component: ImportConstruction },
  { path: '/report-factory', component: Report },
  { path: '/report-team', component: ReportTeam },
  { path: '/report-technical', component: ReportTechnical },
  { path: '/report-construction', component: Construction1 },
  { path: '/plan', component: Order },
  { path: '/quantity', component: Production },
  { path: '/production', component: Manufacture },
  { path: '/technique', component: Technique },
  { path: '/report-quantity', component: ReportQuantity },
  { path: '/report-plan', component: ReportPlan },
  { path: '/construction', component: Construction },
  { path: '/teams', component: Teams },
  { path: '/users', component: Users },
  { path: '/permission', component: Permission },
  { path: '/customer', component: CustomerPermission },
];

export { publicRoutes, privateRoutes };
