import React, { useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
    deleteHistory,
    getPlan,
    setDataNull2,
    setHistory,
    updateHistory,
} from "../../store/order/slice";
import { formatDate } from "./ModalShowPlan";
import "../../assets/scss/theme-base/ModalHistory.css";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import ActionMenu from "../../components/ActionMenu";
import ModelDelete from "../../components/ModalDelete";
import { Toast } from "primereact/toast";

function ModalHistory({ isShow, onHide, productId, selectedRow }) {
    const dispatch = useDispatch();
    const toast = useRef(null);
    const [rowData, setRowData] = useState();
    const [showNewPlanFields, setShowNewPlanFields] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [modalDelete, setModalDelete] = useState(false);

    const {
        planData,
        addHistoryData,
        errorAddHistory,
        updateHistoryData,
        errorUpdateHistory,
        deleteHistoryData,
        errorDeleteHistory,
    } = useSelector(
        (state) => ({
            planData: state.Production.planData,
            addHistoryData: state.Production.addHistoryData,
            errorAddHistory: state.Production.errorAddHistory,
            updateHistoryData: state.Production.updateHistoryData,
            errorUpdateHistory: state.Production.errorUpdateHistory,
            deleteHistoryData: state.Production.deleteHistoryData,
            errorDeleteHistory: state.Production.errorDeleteHistory,
        }),
        shallowEqual
    );

    useEffect(() => {
        if (isShow && productId) {
            dispatch(
                getPlan({
                    page: 1,
                    limit: 100,
                    productId,
                    type: "actual-delivery",
                })
            );
        }
    }, [dispatch, isShow, productId]);

    useEffect(() => {
        if (addHistoryData || updateHistoryData || deleteHistoryData) {
            dispatch(
                getPlan({
                    page: 1,
                    limit: 100,
                    productId,
                    type: "actual-delivery",
                })
            );
            dispatch(setDataNull2());
        }
    }, [addHistoryData, updateHistoryData, deleteHistoryData, dispatch]);

    const validationSchema = Yup.object({
        time: Yup.string().required("Giá trị này là bắt buộc"),
        actualDelivery: Yup.string().required("Giá trị này là bắt buộc"),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            actualDelivery: "",
            productId: productId,
            note: "",
            type: "actual-delivery",
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            const formattedValues = {
                ...values,
                time: values.time ? values.time.toISOString() : "",
            };
            if (isEditMode && rowData) {
                dispatch(
                    updateHistory({ id: rowData.id, data: formattedValues })
                );
            } else {
                dispatch(setHistory(formattedValues));
            }
            setShowNewPlanFields(false);
            setIsEditMode(false);
            formik.resetForm();
        },
    });

    const handleEdit = (rowData) => {
        setRowData(rowData);
        setIsEditMode(true);
        formik.setValues({
            time: new Date(rowData.time),
            actualDelivery: rowData.actualDelivery,
            productId: productId,
            note: rowData.note,
            type: "actual-delivery",
        });
        setShowNewPlanFields(true);
    };

    const handleDelete = (rowData) => {
        setModalDelete(true);
        setRowData(rowData);
    };

    const acceptDelete = () => {
        if (rowData && rowData !== undefined) {
            const id = rowData.id;
            if (id !== undefined) {
                dispatch(deleteHistory({ id }));
            }
            setModalDelete(false);
        }
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <ActionMenu
                onDelete={() => handleDelete(rowData)}
                onEdit={() => handleEdit(rowData)}
            />
        );
    };

    useEffect(() => {
        if (addHistoryData) {
            toast.current?.show({
                severity: "success",
                summary: "Thành công",
                detail: "Thêm thành công",
                life: 3000,
            });
            dispatch(setDataNull2());
        }
    }, [dispatch, addHistoryData]);

    useEffect(() => {
        if (errorAddHistory) {
            toast.current?.show({
                severity: "error",
                summary: "Thất bại",
                detail: errorAddHistory?.response?.data?.message,
                life: 3000,
            });
            dispatch(setDataNull2());
        }
    }, [dispatch, errorAddHistory]);

    useEffect(() => {
        if (updateHistoryData) {
            toast.current?.show({
                severity: "success",
                summary: "Thành công",
                detail: "Sửa thành công",
                life: 3000,
            });
            dispatch(setDataNull2());
        }
    }, [dispatch, updateHistoryData]);

    useEffect(() => {
        if (errorUpdateHistory) {
            toast.current?.show({
                severity: "error",
                summary: "Thất bại",
                detail: errorUpdateHistory?.response?.data?.message,
                life: 3000,
            });
            dispatch(setDataNull2());
        }
    }, [dispatch, errorUpdateHistory]);

    useEffect(() => {
        if (deleteHistoryData) {
            toast.current?.show({
                severity: "success",
                summary: "Thành công",
                detail: "Xóa thành công",
                life: 3000,
            });
            dispatch(setDataNull2());
        }
    }, [dispatch, deleteHistoryData]);

    useEffect(() => {
        if (errorDeleteHistory) {
            toast.current?.show({
                severity: "error",
                summary: "Thất bại",
                detail: errorDeleteHistory.response.data.message,
                life: 3000,
            });
            dispatch(setDataNull2());
        }
    }, [dispatch, errorDeleteHistory]);

    return (
        <Dialog
            header={
                <div
                    style={{
                        backgroundColor: `${selectedRow?.color}`,
                        padding: "4px 8px",
                        borderRadius: "5px",
                        width: "fit-content",
                    }}
                >
                    Giao hàng thực tế
                </div>
            }
            visible={isShow}
            style={{ width: "35vw" }}
            className="responsive-dialog"
            onHide={() => {
                formik.resetForm();
                setIsEditMode(false);
                setShowNewPlanFields(false);
                onHide();
                dispatch(setDataNull2());
            }}
            footer={
                <div>
                    <Button
                        label="Hủy"
                        severity="secondary"
                        className="p-button-text"
                        onClick={() => {
                            formik.resetForm();
                            setIsEditMode(false);
                            setShowNewPlanFields(false);
                            onHide();
                            dispatch(setDataNull2());
                        }}
                    />
                    <Button
                        label="Lưu"
                        type="button"
                        onClick={() => formik.handleSubmit()}
                    />
                </div>
            }
        >
            <Toast ref={toast} />
            <DataTable
                emptyMessage="Không có dữ liệu"
                value={planData?.data?.items || []}
                tableStyle={{ minWidth: "30vw" }}
            >
                <Column
                    field="time"
                    header="Ngày"
                    className="pt-3 pb-2"
                    body={(rowData) => formatDate(rowData.time)}
                ></Column>
                <Column field="actualDelivery" header="SL giao"></Column>
                <Column field="note" header="Ghi chú"></Column>
                <Column
                    field="action"
                    body={actionBodyTemplate}
                    style={{ minWidth: "100px", padding: "0", height: "50px" }}
                />
            </DataTable>
            <ModelDelete
                visible={modalDelete}
                setVisible={setModalDelete}
                accept={acceptDelete}
            />
            {showNewPlanFields && (
                <div className="mt-2 flex justify-content-between gap-2">
                    <div className="flex flex-column" style={{ width: "30%" }}>
                        <Calendar
                            id="time"
                            name="time"
                            dateFormat="dd/mm/yy"
                            value={formik.values.time}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder="Chọn ngày"
                        />
                        {formik.touched.time && formik.errors.time ? (
                            <small className="p-error">
                                {formik.errors.time}
                            </small>
                        ) : null}
                    </div>
                    <div className="flex flex-column" style={{ width: "30%" }}>
                        <InputText
                            id="actualDelivery"
                            name="actualDelivery"
                            value={formik.values.actualDelivery}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder="Nhập số lượng"
                        />
                        {formik.touched.actualDelivery &&
                        formik.errors.actualDelivery ? (
                            <small className="p-error">
                                {formik.errors.actualDelivery}
                            </small>
                        ) : null}
                    </div>
                    <div className="flex flex-column" style={{ width: "40%" }}>
                        <InputText
                            id="note"
                            name="note"
                            value={formik.values.note}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder="Nhập ghi chú"
                        />
                        {formik.touched.note && formik.errors.note ? (
                            <small className="p-error">
                                {formik.errors.note}
                            </small>
                        ) : null}
                    </div>
                </div>
            )}
            {!showNewPlanFields && (
                <Button
                    type="button"
                    style={{ marginTop: "1rem" }}
                    label="Thêm dòng"
                    onClick={() => setShowNewPlanFields(true)}
                />
            )}
        </Dialog>
    );
}

export default ModalHistory;
