import { SHA256 } from "crypto-js";
import encHex from "crypto-js/enc-hex";
import { site_frontend } from "../../api/url_helper";

function ValueQRCodeUrl(detail) {
    if (detail) {
        const { factoryCode, categoryName } = detail;
        // Mã hóa categoryName sử dụng SHA-256
        const hashedCategoryName = SHA256(categoryName).toString(encHex);
        return `${site_frontend}/locate1?factoryCode=${encodeURIComponent(
            factoryCode
        )}`;
    }
    return `${site_frontend}/locate1`;
}

export default ValueQRCodeUrl;
