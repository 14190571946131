import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dataLogin: null,
  isAuthenticated: false,
  dataActive: null,
  dataVerify: null,
  errorVerify: null,
  errorLogin: null,
  errorActive: null,
  dataChangePassword: null,
  errorChangePassword: null,
  dataByUserId: null,
  errorDataByUserId: null,
  loading: false,
  dataAllUser: null,
  errorAllUser: null,
  userInsert: null,
  userInsertError: null,
  dataUpdateUser: null,
  errorUpdateUser: null,
  dataDeleteUser: null,
  errorDeleteUser: null,
  authen: {
    data: null,
    error: false,
    loading: false,
  },
  customers: {
    data: null,
    error: null,
    loading: false,
  },
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    getByUserId: (state) => {
      state.loading = true;
    },
    getByUserIdSuccess: (state, action) => {
      state.loading = false;
      state.dataByUserId = action.payload;
      state.errorDataByUserId = null;
    },
    getByUserIdFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.dataByUserId = null;
    },
    login: (state) => {
      state.loading = true;
    },
    loginSuccess: (state, action) => {
      state.loading = false;
      state.dataLogin = action.payload;
      state.isAuthenticated = true;
      state.errorLogin = null;
    },
    loginFail: (state, action) => {
      state.loading = false;
      state.dataLogin = null;
      state.errorLogin = action.payload;
    },
    active: (state) => {
      state.loading = true;
    },
    activeSuccess: (state, action) => {
      state.loading = false;
      state.dataActive = action.payload;
      state.errorActive = null;
    },
    activeFail: (state, action) => {
      state.loading = false;
      state.errorActive = action.payload;
      state.dataActive = null;
    },
    verify: (state) => {
      state.loading = true;
    },
    verifySuccess: (state, action) => {
      state.loading = false;
      state.dataVerify = action.payload;
      state.errorVerify = null;
    },
    verifyFail: (state, action) => {
      state.loading = false;
      state.dataVerify = null;
      state.errorVerify = action.payload;
    },
    changePassword: (state) => {
      state.loading = true;
    },
    changePasswordSuccess: (state, action) => {
      state.loading = false;
      state.dataChangePassword = action.payload;
      state.errorChangePassword = null;
    },

    changePasswordFail: (state, action) => {
      state.loading = false;
      state.errorChangePassword = action.payload;
      state.dataChangePassword = null;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.dataLogin = null;
      localStorage.removeItem('accessToken');
      localStorage.removeItem('userId');
      localStorage.removeItem('userPermissions');
    },

    getAllUser: (state) => {
      state.loading = true;
    },
    getAllUserSuccess: (state, action) => {
      state.loading = false;
      state.dataAllUser = action.payload;
      state.errorAllUser = null;
    },
    getAllUserFail: (state, action) => {
      state.loading = false;
      state.errorAllUser = action.payload;
      state.dataAllUser = null;
    },
    addUser: (state) => {
      state.loading = true;
    },
    addUserSuccess: (state, action) => {
      state.loading = false;
      state.userInsert = action.payload;
      state.userInsertError = null;
    },
    addUserFail: (state, action) => {
      state.loading = false;
      state.userInsert = null;
      state.userInsertError = action.payload;
    },
    updateUser: (state) => {
      state.loading = true;
    },
    updateUserSuccess: (state, action) => {
      state.loading = false;
      state.dataUpdateUser = action.payload;
      state.errorUpdateUser = null;
    },
    updateUserFail: (state, action) => {
      state.loading = false;
      state.dataUpdateUser = null;
      state.errorUpdateUser = action.payload;
    },
    deleteUser: (state) => {
      state.loading = true;
    },
    deleteUserSuccess: (state, action) => {
      state.loading = false;
      state.dataDeleteUser = action.payload;
      state.errorDeleteUser = null;
    },
    deleteUserFail: (state, action) => {
      state.loading = false;
      state.dataDeleteUser = null;
      state.errorDeleteUser = action.payload;
    },
    getCustomersRequest: (state) => {
      state.customers.loading = true;
    },
    getCustomersSuccess: (state, action) => {
      state.customers.loading = false;
      state.customers.data = action.payload;
    },
    getCustomersFail: (state, action) => {
      state.customers.loading = false;
      state.customers.error = action.payload;
    },
    getAuth: (state) => {
      state.authen.loading = true;
    },
    getAuthSuccess: (state, action) => {
      state.authen.loading = false;
      state.authen.data = action.payload;
      state.authen.error = false;
    },
    getAuthFail: (state, action) => {
      state.authen.loading = false;
      state.authen.data = null;
      state.authen.error = action.payload;
    },
    setDataInsertNull: (state) => {
      state.userInsert = null;
      state.userInsertError = null;
      state.dataUpdateUser = null;
      state.errorUpdateUser = null;
      state.dataDeleteUser = null;
      state.errorDeleteUser = null;
    },

    setDataChangePasswordNull: (state) => {
      state.dataChangePassword = null;
      state.errorChangePassword = null;
    },

    setDataNull: (state) => {
      state.dataLogin = null;
      state.errorLogin = null;
      state.dataActive = null;
      state.errorActive = null;
      state.dataVerify = null;
      state.errorVerify = null;
    },
  },
});
export const {
  login,
  loginSuccess,
  loginFail,
  active,
  activeSuccess,
  activeFail,
  verify,
  verifySuccess,
  verifyFail,
  changePassword,
  changePasswordSuccess,
  changePasswordFail,
  getByUserId,
  getByUserIdSuccess,
  getByUserIdFail,
  setDataNull,
  logout,
  getAllUser,
  getAllUserSuccess,
  getAllUserFail,
  addUser,
  addUserSuccess,
  addUserFail,
  updateUser,
  updateUserSuccess,
  updateUserFail,
  deleteUser,
  deleteUserSuccess,
  deleteUserFail,
  getCustomersRequest,
  getCustomersSuccess,
  getCustomersFail,
  setDataInsertNull,
  setDataChangePasswordNull,
  getAuth,
  getAuthSuccess,
  getAuthFail,
} = authSlice.actions;

export default authSlice.reducer;
