import { Dialog } from 'primereact/dialog';
import 'primeicons/primeicons.css';
import { getTeamChildren } from '../../store/order/slice';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { formatDate2 } from '../../components/utils';
import { Image } from 'primereact/image';
import '../../assets/scss/theme-base/Intersection.css';
import ModelDelete from '../../components/ModalDelete';
import ModalEditLocation from './ModalEditLocation';
import { Toast } from 'primereact/toast';
import { deleteLocation, resetData } from '../../store/product/slice';

const ModalDv = ({ visible, onHide, name, factoryCode }) => {
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [rowData, setRowData] = useState();
  const dispatch = useDispatch();
  const toast = useRef(null);
  const { data, deleteSuccess, errDelete } = useSelector(
    (state) => ({
      data: state.Production.teamChildrenData?.data?.items,
      deleteSuccess: state.Product.deleteLocation.data,
      errDelete: state.Product.deleteLocation.error,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (name && visible) {
      dispatch(getTeamChildren({ team: name, factoryCode: factoryCode }));
    }
  }, [dispatch, name, factoryCode, visible, deleteSuccess]);

  const pairs = data || [];

  const handleEdit = (data) => {
    setVisibleEdit(true);
    setRowData(data);
  };
  const handleDelete = (id) => {
    setVisibleDelete(true);
    setRowData(id);
  };

  const acceptDelete = () => {
    if (rowData && rowData !== undefined) {
      dispatch(deleteLocation(rowData));
      setVisibleDelete(false);
    }
  };

  useEffect(() => {
    if (deleteSuccess) {
      toast.current.show({
        severity: 'success',
        summary: 'Thành công',
        detail: 'Xóa định vị thành công',
        life: 3000,
      });
      dispatch(resetData());
    }
    if (errDelete) {
      toast.current.show({
        severity: 'error',
        summary: 'Thất bại',
        detail: errDelete.response.data.message,
        life: 3000,
      });
      dispatch(resetData());
    }
  }, [dispatch, rowData, deleteSuccess, errDelete]);

  useEffect(() => {
    if (data && data?.length === 0) {
      onHide();
    }
  }, [data, onHide, deleteSuccess]);

  return (
    <Dialog
      visible={visible}
      onHide={() => {
        onHide();
      }}
      className='p-fluid'
      header='Chi tiết sản xuất'
      blockScroll={false}
      style={{ minWidth: '400px', overflowX: 'auto' }}>
      <Toast ref={toast} />
      <div>
        {pairs.map((pair, index) => (
          <div
            key={index}
            className='mb-5 pb-2 flex justify-content-between'
            style={{ borderBottom: '1px solid #d8d8d8' }}>
            <div
              className='pr-2'
              style={{ width: '200px' }}>
              <div>
                <div className='mb-2'>
                  {pair.start && formatDate2(pair.start.time)}
                </div>
                <div className='mb-2'>
                  SL: {pair.start && pair.start.quantityMade}
                </div>
                <div className='mb-2'>{pair.start && pair.start.note}</div>
                <div
                  className='flex gap-3 overflow-x-auto'
                  style={{ maxWidth: '160px', minHeight: '40px' }}>
                  {pair.start &&
                    pair.start.images.map((img, imgIndex) => (
                      <Image
                        key={imgIndex}
                        src={`${img}`}
                        alt={`start-img-${imgIndex}`}
                        height='50'
                        width='50'
                        style={{ flex: '0 0 auto' }}
                        preview
                      />
                    ))}
                </div>
                <div className='mt-2 flex gap-2 align-items-end'>
                  <p
                    className='text-orange-500 font-bold cursor-pointer'
                    onClick={() =>
                      handleEdit({
                        quantityMade: pair.start.quantityMade,
                        note: pair.start.note,
                        images: pair.start.images,
                        id: pair.start.id,
                        name: name,
                        factoryCode: factoryCode,
                      })
                    }>
                    Chỉnh sửa
                  </p>
                  {!pair.end && (
                    <div className='flex align-items-center'>
                      |
                      <p
                        className='text-red-500 font-bold cursor-pointer ml-2'
                        onClick={() => handleDelete(pair.start.id)}>
                        Xóa
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div
              className='flex justify-content-center align-items-center'
              style={{ width: '50px' }}>
              <i
                className='pi pi-arrow-right'
                style={{ color: 'green' }}></i>
            </div>
            <div
              className='pl-2'
              style={{ width: '200px' }}>
              {pair.end && (
                <div>
                  <div className='mb-2'>
                    {pair.end && formatDate2(pair.end.time)}
                  </div>
                  <div className='mb-2'>
                    SL: {pair.end && pair.end.quantityMade}
                  </div>
                  <div className='mb-2'>{pair.end && pair.end.note}</div>
                  <div
                    className='flex gap-3 overflow-x-auto'
                    style={{ maxWidth: '160px', minHeight: '40px' }}>
                    {pair.end &&
                      pair.end.images.map((img, imgIndex) => (
                        <Image
                          key={imgIndex}
                          src={`${img}`}
                          alt={`end-img-${imgIndex}`}
                          width='50px'
                          height='50px'
                          style={{ flex: '0 0 auto' }}
                          preview
                        />
                      ))}
                  </div>
                  <div className='mt-2 flex gap-2 align-items-end'>
                    <p
                      className='text-orange-500 font-bold cursor-pointer'
                      onClick={() =>
                        handleEdit({
                          quantityMade: pair.end.quantityMade,
                          note: pair.end.note,
                          images: pair.end.images,
                          id: pair.end.id,
                          name: name,
                          factoryCode: factoryCode,
                        })
                      }>
                      Chỉnh sửa
                    </p>
                    |
                    <p
                      className='text-red-500 font-bold cursor-pointer'
                      onClick={() => handleDelete(pair.end.id)}>
                      Xóa
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      <ModalEditLocation
        visible={visibleEdit}
        onHide={() => setVisibleEdit(false)}
        data={rowData}
      />
      <ModelDelete
        visible={visibleDelete}
        setVisible={setVisibleDelete}
        accept={acceptDelete}
        msg={
          <div>
            <p className='text-center'>
              Bạn có chắc chắn muốn xóa định vị này?
            </p>
            <p className='text-center'>
              Sau khi xóa, bạn không thể khôi phục lại dữ liệu.
            </p>
          </div>
        }
      />
    </Dialog>
  );
};

export default ModalDv;
