import { call, put, takeEvery } from "redux-saga/effects";
import {
    getDashboardFactoryFail,
    getDashboardFactorySuccess,
    getProductInprogressFactorySuccess,
    getProductInprogressFactoryFail,
    getProductInlateFactorySuccess,
    getProductInlateFactoryFail,
    getProductIncompleteFactoryFail,
    getProductIncompleteFactorySuccess,
    downloadExcelFactoryFail,
    downloadExcelFactorySuccess,
    downloadExcelBuildingFail,
    downloadExcelBuildingSuccess,
    getAllProductInprogressFail,
    getAllProductInprogressSuccess,
    getAllProductInlateFail,
    getAllProductInlateSuccess,
} from "./slice";

import {
    getDashboardFactory,
    getProductInprogressFactory,
    getProductInlateFactory,
    getProductIncompleteFactory,
    exportExcelFactory,
    exportExcelBuilding,
} from "../../api/backend_helper";

function* getAllProductInprogressSaga(action) {
    try {
        const {
            page,
            limit,
            timeStartReq,
            timeEndReq,
            building,
            orderCode,
            category,
            factoryCode,
            current,
            filterStatus,
        } = action.payload;

        const data = yield call(getProductInprogressFactory, {
            page,
            limit,
            timeStartReq,
            timeEndReq,
            building,
            orderCode,
            category,
            factoryCode,
            current,
            filterStatus,
        });

        yield put(getAllProductInprogressSuccess(data));
    } catch (error) {
        yield put(getAllProductInprogressFail(error.message));
    }
}
function* getDashboardFactorySaga(action) {
    try {
        const {
            page,
            limit,
            timeStartReq,
            timeEndReq,
            building,
            orderCode,
            category,
            factoryCode,
            current,
        } = action.payload;
        const data = yield call(getDashboardFactory, {
            page,
            limit,
            timeStartReq,
            timeEndReq,
            building,
            orderCode,
            category,
            factoryCode,
            current,
        });
        yield put(getDashboardFactorySuccess(data));
    } catch (error) {
        yield put(getDashboardFactoryFail(error.message));
    }
}

function* getProductInprogressFactorySaga(action) {
    try {
        const {
            page,
            limit,
            timeStartReq,
            timeEndReq,
            building,
            orderCode,
            category,
            factoryCode,
            current,
            filterStatus,
        } = action.payload;

        const data = yield call(getProductInprogressFactory, {
            page,
            limit,
            timeStartReq,
            timeEndReq,
            building,
            orderCode,
            category,
            factoryCode,
            current,
            filterStatus,
        });

        yield put(getProductInprogressFactorySuccess(data));
    } catch (error) {
        yield put(getProductInprogressFactoryFail(error.message));
    }
}

function* getAllProductInlateSaga(action) {
    try {
        const {
            page,
            limit,
            timeStartReq,
            timeEndReq,
            type,
            building,
            orderCode,
            category,
            factoryCode,
            current,
        } = action.payload;

        const data = yield call(getProductInlateFactory, {
            page,
            limit,
            timeStartReq,
            timeEndReq,
            type,
            building,
            orderCode,
            category,
            factoryCode,
            current,
        });

        yield put(getAllProductInlateSuccess(data));
    } catch (error) {
        yield put(getAllProductInlateFail(error.message));
    }
}

function* getProductInlateFactorySaga(action) {
    try {
        const {
            page,
            limit,
            timeStartReq,
            timeEndReq,
            type,
            building,
            orderCode,
            category,
            factoryCode,
            current,
        } = action.payload;
        const data = yield call(getProductInlateFactory, {
            page,
            limit,
            timeStartReq,
            timeEndReq,
            type,
            building,
            orderCode,
            category,
            factoryCode,
            current,
        });
        yield put(getProductInlateFactorySuccess(data));
    } catch (error) {
        yield put(getProductInlateFactoryFail(error.message));
    }
}

function* getProductIncompleteFactorySaga(action) {
    try {
        const {
            page,
            limit,
            timeStartReq,
            timeEndReq,
            type,
            building,
            orderCode,
            category,
            factoryCode,
            current,
            filterStatus,
        } = action.payload;
        const data = yield call(getProductIncompleteFactory, {
            page,
            limit,
            timeStartReq,
            timeEndReq,
            type,
            building,
            orderCode,
            category,
            factoryCode,
            current,
            filterStatus,
        });
        yield put(getProductIncompleteFactorySuccess(data));
    } catch (error) {
        yield put(getProductIncompleteFactoryFail(error.message));
    }
}

function* downloadExcelFactorySaga(action) {
    try {
        const data = yield call(exportExcelFactory, action.payload);
        yield put(downloadExcelFactorySuccess(data));
    } catch (error) {
        yield put(downloadExcelFactoryFail(error));
    }
}

function* downloadExcelBuildingSaga(action) {
    try {
        const data = yield call(exportExcelBuilding, action.payload);
        yield put(downloadExcelBuildingSuccess(data));
    } catch (error) {
        yield put(downloadExcelBuildingFail(error));
    }
}

function* DashboardFactory() {
    yield takeEvery("dashboard/getDashboardFactory", getDashboardFactorySaga);
    yield takeEvery(
        "dashboard/getProductInprogressFactory",
        getProductInprogressFactorySaga
    );
    yield takeEvery(
        "dashboard/getProductInlateFactory",
        getProductInlateFactorySaga
    );
    yield takeEvery(
        "dashboard/getProductIncompleteFactory",
        getProductIncompleteFactorySaga
    );
    yield takeEvery("dashboard/downloadExcelFactory", downloadExcelFactorySaga);
    yield takeEvery(
        "dashboard/downloadExcelBuilding",
        downloadExcelBuildingSaga
    );
    yield takeEvery(
        "dashboard/getAllProductInprogress",
        getAllProductInprogressSaga
    );
    yield takeEvery("dashboard/getAllProductInlate", getAllProductInlateSaga);
}

export default DashboardFactory;
