import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { IconField } from 'primereact/iconfield';
import { Paginator } from 'primereact/paginator';
import { InputIcon } from 'primereact/inputicon';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ModelDelete from '../../components/ModalDelete';
import { Toast } from 'primereact/toast';
import ModelAddTeam from './ModelAddTeam';
import ActionMenu from '../../components/ActionMenu';
import ModelEditTeam from './ModelEditTeam';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { getDataAll, deleteTeams, setDataNull } from '../../store/teams/slice';
import QR from './QR';

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

function Teams() {
  const [dialogVisible, setDialogVisible] = useState(false);
  const [dialogEditVisible, setDialogEditVisible] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [rowData, setRowData] = useState();
  const [dataTable, setDataTable] = useState([]);
  const toast = useRef(null);
  const dispatch = useDispatch();
  const [qrDetail, setQrDetail] = useState(null);
  const [visible3, setVisible3] = useState(false);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [valueSearch, setValueSearch] = useState('');
  const debouncedSearchTerm = useDebounce(valueSearch, 500);

  const listPermissions =
    JSON.parse(localStorage.getItem('userPermissions')) || {};

  const { teamsData, deleteData, errorDelete, updateData, insertData } =
    useSelector(
      (state) => ({
        teamsData: state.Teams.teamsData,
        deleteData: state.Teams.deleteData,
        errorDelete: state.Teams.errorDelete,
        updateData: state.Teams.updateData,
        insertData: state.Teams.insertData,
      }),
      shallowEqual
    );

  useEffect(() => {
    dispatch(
      getDataAll({
        page: (first + rows) / rows,
        limit: rows,
        keyword: debouncedSearchTerm,
      })
    );
  }, [dispatch, first, rows, debouncedSearchTerm]);

  useEffect(() => {
    if (teamsData?.data?.items) {
      setDataTable(teamsData.data.items);
    }
  }, [teamsData]);

  useEffect(() => {
    if (deleteData || updateData || insertData) {
      dispatch(getDataAll({ page: (first + rows) / rows, limit: rows }));
      dispatch(setDataNull());
    }
  }, [deleteData, updateData, insertData, dispatch, first, rows]);

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const handleEdit = (rowData) => {
    setRowData(rowData);
    setDialogEditVisible(true);
  };

  const handleDelete = (rowData) => {
    setModalDelete(true);
    setRowData(rowData);
  };

  const acceptDelete = () => {
    if (rowData && rowData !== undefined) {
      const id = rowData.id;
      if (id !== undefined && id === '9b2d2e9c-c6fb-4cb1-8f15-3e350730be9c') {
        toast.current.show({
          severity: 'error',
          summary: 'Thất bại',
          detail: 'Không thể tổ đội này',
          life: 3000,
        });
      }
      if (id !== undefined && id === 'c048fe80-59fd-4ffe-b6d8-0307832dca0c') {
        toast.current.show({
          severity: 'error',
          summary: 'Thất bại',
          detail: 'Không thể tổ đội này',
          life: 3000,
        });
      }
      if (
        id !== undefined &&
        id !== '9b2d2e9c-c6fb-4cb1-8f15-3e350730be9c' &&
        id !== 'c048fe80-59fd-4ffe-b6d8-0307832dca0c'
      ) {
        dispatch(deleteTeams({ id, flag: true }));
      }
      setModalDelete(false);
    }
  };

  useEffect(() => {
    if (deleteData) {
      toast.current.show({
        severity: 'success',
        summary: 'Thành công',
        detail: 'Xóa tổ đội thành công',
        life: 3000,
      });
      dispatch(setDataNull());
    }
  }, [dispatch, deleteData]);

  useEffect(() => {
    if (errorDelete) {
      toast.current.show({
        severity: 'error',
        summary: 'Thất bại',
        detail: errorDelete.response.data.message,
        life: 3000,
      });
      dispatch(setDataNull());
    }
  }, [dispatch, errorDelete]);

  // const actionBodyTemplate = (rowData) => {
  //   return listPermissions['Tổ đội'] &&
  //     listPermissions['Tổ đội'].includes('Sửa tổ đội') &&
  //     listPermissions['Tổ đội'].includes('Xóa tổ đội') ? (
  //     <ActionMenu
  //       onEdit={() => handleEdit(rowData)}
  //       onDelete={() => handleDelete(rowData)}
  //     />
  //   ) : (
  //     <></>
  //   );
  // };

  const actionBodyTemplate = (rowData) => {
    // Điều kiện kiểm tra quyền hạn người dùng
    const hasPermission =
      listPermissions['Tổ đội'] &&
      listPermissions['Tổ đội'].includes('Sửa tổ đội') &&
      listPermissions['Tổ đội'].includes('Xóa tổ đội');

    if (hasPermission) {
      return (
        <ActionMenu
          onEdit={() => handleEdit(rowData)}
          onDelete={() => handleDelete(rowData)}
        />
      );
    }

    return <></>;
  };

  const sttBodyTemplate = (rowData, { rowIndex }) => {
    return <span>{first + rowIndex + 1}</span>;
  };

  const handleDetailScanned = (detail) => {
    setQrDetail(detail);
  };

  return (
    <div className='h-full flex flex-column justify-content-between'>
      <Toast ref={toast} />
      <div className='flex flex-row justify-content-between mb-3'>
        {listPermissions['Tổ đội'] &&
          listPermissions['Tổ đội'].includes('Thêm tổ đội') && (
            <Button
              label='Thêm mới'
              size='small'
              onClick={() => setDialogVisible(true)}
            />
          )}
        <IconField
          iconPosition='left'
          className='w-6'>
          <InputIcon className='pi pi-search'></InputIcon>
          <InputText
            placeholder='Tìm kiếm theo tên tổ đội'
            className='w-full border-0'
            value={valueSearch}
            onChange={(e) => setValueSearch(e.target.value)}
          />
        </IconField>
      </div>
      <div className='bg-white flex flex-column justify-content-between border-round-md shadow-1 p-3 h-full'>
          <DataTable
            value={dataTable}
            rowGroupMode='subheader'
            groupRowsBy='info.name_jp'
            emptyMessage='Không có dữ liệu'
            size='small'
            scrollable
            scrollHeight='75vh'>
            <Column
              header='STT'
              body={sttBodyTemplate}
              style={{ width: '5%' }}
              alignHeader={'center'}
              align={'center'}
            />
            <Column
              field='name'
              header='Tên tổ đội'
              style={{ minWidth: '120px' }}
              // alignHeader={'center'}
              // align={'center'}
            />
            <Column
              field='teamCode'
              header='Mã tổ đội'
              style={{ minWidth: '100px' }}
              // alignHeader={'center'}
              // align={'center'}
            />

            <Column
              header='Mã QR'
              body={(rowData) => (
                <div className='text-center'>
                  <Button
                    onClick={() => {
                      setQrDetail(rowData);
                      setVisible3(true);
                    }}
                    icon='pi pi-qrcode'
                    rounded
                    text
                    severity='secondary'
                    aria-label='QR'
                    style={{ color: 'black' }}
                  />
                </div>
              )}
              style={{ minWidth: '100px' }}
              alignHeader={'center'}
              align={'center'}></Column>
            <Column
              header='Mật khẩu'
              body={(rowData) => '********'}
              style={{ minWidth: '100px' }}
              // alignHeader={'center'}
              // align={'center'}
            />
            <Column
              field='description'
              header='Ghi chú'
              style={{ minWidth: '100px' }}
              // alignHeader={'center'}
              // align={'center'}
            />

            <Column
              field='action'
              body={actionBodyTemplate}
              style={{ minWidth: '100px', height: '50px' }}
            />
          </DataTable>
          <div className='card'>
            <Paginator
              first={first}
              rows={rows}
              totalRecords={teamsData?.data?.totalItems || 0}
              rowsPerPageOptions={[10, 20, 30]}
              onPageChange={onPageChange}
            />
          </div>
      </div>
      <ModelAddTeam
        visible={dialogVisible}
        onHide={() => setDialogVisible(false)}
        toast={toast}
      />
      <ModelEditTeam
        visible={dialogEditVisible}
        onHide={() => setDialogEditVisible(false)}
        toast={toast}
        rowData={rowData}
      />
      <ModelDelete
        visible={modalDelete}
        setVisible={setModalDelete}
        accept={acceptDelete}
        msg={
          <div style={{ textAlign: 'center' }}>
            Bạn chắc chắn muốn xóa tổ đội này?
            <br />
            Các thông tin liên quan đến tổ đội này sẽ bị xóa.
          </div>
        }
      />
      <QR
        isShow={visible3}
        hiden={() => setVisible3(false)}
        detail={qrDetail}
        onDetailScanned={handleDetailScanned}
      />
    </div>
  );
}

export default Teams;
