import { call, put, takeEvery } from "redux-saga/effects";
import {
    getDashboardPlanFail,
    getDashboardPlanSuccess,
    getAllDashboardPlanSuccess,
    getAllDashboarPlanFail,
    getUnitFail,
    getUnitSuccess,
    getHistoryFail,
    getHistorySuccess,
} from "./slice";
import {
    getDashboardPlan,
    getUnit,
    getHistory,
} from "../../api/backend_helper";

function* getDashboardPlanSaga(action) {
    try {
        const {
            page,
            limit,
            timeStartReq,
            timeEndReq,
            building,
            orderCode,
            category,
            factoryCode,
            status,
            filterStatus,
        } = action.payload;
        const data = yield call(getDashboardPlan, {
            page,
            limit,
            timeStartReq,
            timeEndReq,
            building,
            orderCode,
            category,
            factoryCode,
            status,
            filterStatus,
        });
        yield put(getDashboardPlanSuccess(data));
    } catch (error) {
        yield put(getDashboardPlanFail(error.message));
    }
}

function* getAllDashboardPlanSaga(action) {
    try {
        const {
            page,
            limit,
            timeStartReq,
            timeEndReq,
            building,
            orderCode,
            category,
            factoryCode,
            current,
            filterStatus,
        } = action.payload;

        const data = yield call(getDashboardPlan, {
            page,
            limit,
            timeStartReq,
            timeEndReq,
            building,
            orderCode,
            category,
            factoryCode,
            current,
            filterStatus,
        });

        yield put(getAllDashboardPlanSuccess(data));
    } catch (error) {
        yield put(getAllDashboarPlanFail(error.message));
    }
}

function* getUnitSaga() {
    try {
        const data = yield call(getUnit);
        yield put(getUnitSuccess(data));
    } catch (error) {
        yield put(getUnitFail(error.message));
    }
}

function* getHistorySaga(action) {
    try {
        const data = yield call(getHistory, action.payload);
        yield put(getHistorySuccess(data));
    } catch (error) {
        yield put(getHistoryFail(error.message));
    }
}

function* DashboardPlan() {
    yield takeEvery("dashboardPlan/getDashboardPlan", getDashboardPlanSaga);
    yield takeEvery(
        "dashboardPlan/getAllDashboardPlan",
        getAllDashboardPlanSaga
    );
    yield takeEvery("dashboardPlan/getUnit", getUnitSaga);
    yield takeEvery("dashboardPlan/getHistory", getHistorySaga);
}

export default DashboardPlan;
