import React, { useState, useEffect, useContext } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { getBuilding } from "../../store/order/slice";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import "../../assets/scss/theme-base/dashboard.css";
import { SplitButton } from "primereact/splitbutton";
import { DataContext } from "../../contexts/data/DataProvider";
import { resetPagination } from "../../store/dashboard/slice";
import { resetPaginationPlan } from "../../store/dashboardPlan/slice";
import { resetPaginationTechnical } from "../../store/dashboardTechnical/slice";
import { resetPaginationQuantity } from "../../store/dashboardQuantity/slice";
import { resetPaginationTeam } from "../../store/dashboardTeams/slice";
const HeaderReport = ({
    building,
    orderCode,
    category,
    factoryCode,
    setBuilding,
    setOrderCode,
    setCategory,
    setFactoryCode,
    setStartDateOutput,
    setEndDateOutput,
    exportClick,
    printReport,
    exportExcel,
    exportImage,
    exportQuality,
    type,
}) => {
    const { setBuildingName, setBuildingId, setStartTime, setEndTime } =
        useContext(DataContext);
    const dispatch = useDispatch();

    //lấy tuần tháng năm hiện tại

    const [startDate, setStartDate] = useState(new Date("2024-08-01"));
    const [endDate, setEndDate] = useState(new Date());
    const [buildingOptions, setBuildingOptions] = useState([]);
    const [dataBuilding, setDataBuilding] = useState(null);

    const { buildingData, loadingExcelFactory } = useSelector(
        (state) => ({
            buildingData: state.Production.dataBuilding,
            loadingExcelFactory: state.Dashboard.loadingExcelFactory,
        }),
        shallowEqual
    );
    useEffect(() => {
        setDataBuilding(buildingData);
    }, [buildingData, type]);

    function getNameByBuildingCode(buildingCode) {
        const result = dataBuilding?.data?.items.find(
            (item) => item.buildingCode === buildingCode
        );
        return result ? result.name : "";
    }

    useEffect(() => {
        setBuildingName(getNameByBuildingCode(building));
    }, [building]);

    useEffect(() => {
        const buildings = dataBuilding?.data?.items.map((item) => ({
            label: item.name,
            value: item.buildingCode,
        }));
        if (buildings && buildings.length > 0) {
            setBuildingOptions(buildings);
        } else {
            setBuildingOptions([]);
        }
    }, [dataBuilding, buildingData, type]);

    // Định dạng ngày theo định dạng YYYY-MM-DD
    const formatDate = (date) => {
        if (!date) return "";
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
    };

    let importItems = [
        {
            label: "Xuất PDF",
            icon: "pi pi-file-pdf",
            command: () => {
                exportClick();
                setBuildingName("");
            },
        },
        {
            label: "Xuất Excel",
            icon: "pi pi-file-excel",
            command: exportExcel,
        },
        {
            label: "Xuất hình ảnh sản xuất",
            icon: "pi pi-image",
            command: exportImage,
        },
        {
            label: "Xuất báo cáo chất lượng",
            icon: "pi pi-file-export",
            command: exportQuality,
        },
    ];
    if (type !== "construction") {
        importItems = importItems.filter(
            (item) =>
                item.label !== "Xuất hình ảnh sản xuất" &&
                item.label !== "Xuất báo cáo chất lượng"
        );
    }

    // }
    function formatDateWithOffset(dateStr, offsetDays = 0) {
        // Chuyển đổi chuỗi thành đối tượng Date
        const date = new Date(dateStr);

        // Thêm offset ngày
        const newDate = new Date(date);
        newDate.setDate(date.getDate() + offsetDays);

        // Định dạng ngày
        const year = newDate.getFullYear();
        const month = String(newDate.getMonth() + 1).padStart(2, "0"); // Tháng bắt đầu từ 0
        const day = String(newDate.getDate()).padStart(2, "0");

        return `${year}/${month}/${day}`;
    }
    // xóa bộ lọc
    const onClickDeleteFilter = () => {
        setStartDate(new Date("2024-08-01"));
        setEndDate(new Date());
        setBuilding(null);
        setCategory("");
        setFactoryCode("");
        setOrderCode("");
    };

    useEffect(() => {
        setStartDateOutput(formatDateWithOffset(startDate));
        setEndDateOutput(formatDateWithOffset(endDate));
    }, [startDate, endDate]);

    // const handleGetContruction = () => {
    //     if (type !== "construction")
    //         dispatch(getBuilding({ page: 1, limit: 100 }));
    //     if (type === "construction")
    //         dispatch(
    //             getBuilding({ page: 1, limit: 100, type: "buildingStatistics" })
    //         );
    // };
    useEffect(() => {
        if (type !== "construction")
            dispatch(getBuilding({ page: 1, limit: 100 }));
        if (type === "construction")
            dispatch(
                getBuilding({ page: 1, limit: 100, type: "buildingStatistics" })
            );
    }, [type]);

    return (
        <div>
            <div className="flex sm:justify-content-between flex-wrap align-items-start justify-content-center">
                <div className="flex gap-3 max-w-max mb-3 flex-column md:flex-row sm:ml-0">
                    <div className="flex gap-3 max-w-max h-2rem w-12 md:h-auto">
                        <div className="calendar-container">
                            <label htmlFor="startDate">Từ ngày:</label>
                            <Calendar
                                locale="vi"
                                id="startDate"
                                value={startDate}
                                onChange={(e) => {
                                    setStartDate(e.target.value);
                                    setStartDateOutput(
                                        formatDate(e.target.value)
                                    );
                                    setStartTime(formatDate(e.target.value));
                                    dispatch(resetPagination());
                                    dispatch(resetPaginationPlan());
                                    dispatch(resetPaginationTechnical());
                                    dispatch(resetPaginationQuantity());
                                    dispatch(resetPaginationTeam());
                                }}
                                dateFormat="dd/mm/yy"
                                className="calendar-custom"
                                style={{ height: "35px" }}
                            />
                        </div>
                        <div className="calendar-container">
                            <label htmlFor="endtDate">Đến ngày:</label>
                            <Calendar
                                locale="vi"
                                id="endDate"
                                value={endDate}
                                onChange={(e) => {
                                    setEndDate(e.target.value);
                                    setEndDateOutput(
                                        formatDate(e.target.value)
                                    );
                                    setEndTime(formatDate(e.target.value));
                                    dispatch(resetPagination());
                                    dispatch(resetPaginationPlan());
                                    dispatch(resetPaginationTechnical());
                                    dispatch(resetPaginationQuantity());
                                    dispatch(resetPaginationTeam());
                                }}
                                dateFormat="dd/mm/yy"
                                className="calendar-custom"
                                minDate={startDate}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex gap-3 mb-3 sm:mt-0 md:mt-0 btn-container">
                    <Button
                        label="Xóa bộ lọc"
                        icon="pi pi-filter-slash"
                        onClick={onClickDeleteFilter}
                        severity="secondary"
                        size="small"
                    ></Button>
                    <SplitButton
                        // className="bg-blue-500"
                        model={importItems}
                        icon="pi pi-print"
                        label={
                            loadingExcelFactory ? "Đang xuất..." : "In báo cáo"
                        }
                        size="small"
                        severity="danger"
                        outlined
                        onClick={(e) => {
                            printReport();
                            setBuildingName("");
                        }}
                    ></SplitButton>
                </div>
            </div>
            <div className="flex gap-3 w-full md:flex-row flex-column">
                <div className="flex gap-3 w-full">
                    <Dropdown
                        placeholder="Lọc công trình"
                        options={buildingOptions}
                        onChange={(e) => {
                            setBuilding(e.target.value);
                            setBuildingId(e.target.value);
                            dispatch(resetPagination());
                            dispatch(resetPaginationPlan());
                            dispatch(resetPaginationTechnical());
                            dispatch(resetPaginationQuantity());
                            dispatch(resetPaginationTeam());
                        }}
                        // onFocus={handleGetContruction}
                        value={building}
                        filter
                        showClear
                        style={{ height: "32px" }}
                        className="py-0 flex align-items-center border-0 w-6 md:w-6 md:max-w-sm"
                        emptyFilterMessage="Chưa có kết quả phù hợp"
                        emptyMessage="Chưa có công trình"
                    />
                    <InputText
                        type="text"
                        value={orderCode}
                        className="p-inputtext-sm text-base px-3 border-0 w-6 md:w-6 md:max-w-sm"
                        placeholder="Mã đơn hàng"
                        onChange={(e) => {
                            setOrderCode(e.target.value);
                            dispatch(resetPagination());
                            dispatch(resetPaginationPlan());
                            dispatch(resetPaginationTechnical());
                            dispatch(resetPaginationQuantity());
                            dispatch(resetPaginationTeam());
                        }}
                    />
                </div>
                <div className="flex gap-3 w-full">
                    <InputText
                        type="text"
                        value={category}
                        className="p-inputtext-sm text-base px-3 border-0 w-6 md:w-6 md:max-w-sm"
                        placeholder="Lọc hạng mục"
                        onChange={(e) => {
                            setCategory(e.target.value);
                            dispatch(resetPagination());
                            dispatch(resetPaginationPlan());
                            dispatch(resetPaginationTechnical());
                            dispatch(resetPaginationQuantity());
                            dispatch(resetPaginationTeam());
                        }}
                    />
                    <InputText
                        type="text"
                        value={factoryCode}
                        className="p-inputtext-sm text-base px-3 border-0 w-6 md:w-6 md:max-w-sm"
                        placeholder="Lọc mã nhà máy"
                        onChange={(e) => {
                            setFactoryCode(e.target.value);
                            dispatch(resetPagination());
                            dispatch(resetPaginationPlan());
                            dispatch(resetPaginationTechnical());
                            dispatch(resetPaginationQuantity());
                            dispatch(resetPaginationTeam());
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default HeaderReport;
