import { useRef, useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import * as Yup from "yup";
import { useFormik } from "formik";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
    getPlanCategoryMaterial,
    setPlanCategoryMaterial,
    updatePlanCategoryMaterial,
    deletePlanCategoryMaterial,
    setDataNull2,
} from "../../store/order/slice";
import ActionMenu from "../../components/ActionMenu";
import ModelDelete from "../../components/ModalDelete";
import { Toast } from "primereact/toast";
import "../../assets/scss/theme-base/ModalShowPlanLead.css";
import { Checkbox } from "primereact/checkbox";

export const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
};

function ModalShowCategoryMaterial({ isShow, onHide, productId }) {
    const [showNewPlanFields, setShowNewPlanFields] = useState(false);
    const toast = useRef(null);
    const [modalDelete, setModalDelete] = useState(false);
    const [rowData, setRowData] = useState();
    const [isEditMode, setIsEditMode] = useState(false);
    const [checked, setChecked] = useState(false);

    const dispatch = useDispatch();
    const {
        planCategoryMaterialData,
        updatePlanCategoryMaterialData,
        deletePlanCategoryMaterialData,
        errorDeletePlanCategoryMaterial,
        addPlanCategoryMaterialData,
        errorAddPlanCategoryMaterial,
        errorUpdatePlanCategoryMaterial,
    } = useSelector(
        (state) => ({
            planCategoryMaterialData: state.Production.planCategoryMaterialData,
            updatePlanCategoryMaterialData:
                state.Production.updatePlanCategoryMaterialData,
            deletePlanCategoryMaterialData:
                state.Production.deletePlanCategoryMaterialData,
            errorDeletePlanCategoryMaterial:
                state.Production.errorDeletePlanCategoryMaterial,
            addPlanCategoryMaterialData:
                state.Production.addPlanCategoryMaterialData,
            errorAddPlanCategoryMaterial:
                state.Production.errorAddPlanCategoryMaterial,
            errorUpdatePlanCategoryMaterial:
                state.Production.errorUpdatePlanCategoryMaterial,
        }),
        shallowEqual
    );

    const validationSchema = Yup.object({
        time: Yup.string().required("Giá trị này là bắt buộc"),
        machining: Yup.string().required("Giá trị này là bắt buộc"),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            time: "",
            machining: "",
            productId: productId,
            type: "machining",
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            const formattedValues = {
                ...values,
                time: values.time ? values.time.toISOString() : "",
            };

            if (isEditMode && rowData) {
                dispatch(
                    updatePlanCategoryMaterial({
                        id: rowData.id,
                        data: formattedValues,
                    })
                );
            } else {
                dispatch(setPlanCategoryMaterial(formattedValues));
            }
            setShowNewPlanFields(false);
            setIsEditMode(false);
            formik.resetForm();
        },
    });

    useEffect(() => {
        if (isShow && productId) {
            dispatch(
                getPlanCategoryMaterial({
                    page: 1,
                    limit: 100,
                    productId,
                    type: "machining",
                })
            );
        }
    }, [dispatch, isShow, productId]);

    useEffect(() => {
        if (
            deletePlanCategoryMaterialData ||
            updatePlanCategoryMaterialData ||
            addPlanCategoryMaterialData
        ) {
            dispatch(
                getPlanCategoryMaterial({
                    page: 1,
                    limit: 100,
                    productId,
                    type: "machining",
                })
            );
            dispatch(setDataNull2());
        }
    }, [
        deletePlanCategoryMaterialData,
        updatePlanCategoryMaterialData,
        addPlanCategoryMaterialData,
        dispatch,
    ]);

    const handleDelete = (rowData) => {
        setModalDelete(true);
        setRowData(rowData);
    };

    const acceptDelete = () => {
        if (rowData && rowData !== undefined) {
            const id = rowData.id;
            if (id !== undefined) {
                dispatch(deletePlanCategoryMaterial({ id }));
            }
            setModalDelete(false);
        }
    };

    useEffect(() => {
        if (addPlanCategoryMaterialData) {
            toast.current?.show({
                severity: "success",
                summary: "Thành công",
                detail: "Thêm thành công",
                life: 3000,
            });
            dispatch(setDataNull2());
        }
    }, [dispatch, addPlanCategoryMaterialData]);

    useEffect(() => {
        if (errorAddPlanCategoryMaterial) {
            toast.current?.show({
                severity: "error",
                summary: "Thất bại",
                detail: errorAddPlanCategoryMaterial?.response?.data?.message,
                life: 3000,
            });
            // dispatch(setDataNull2());
        }
    }, [dispatch, errorAddPlanCategoryMaterial]);

    useEffect(() => {
        if (updatePlanCategoryMaterialData) {
            toast.current?.show({
                severity: "success",
                summary: "Thành công",
                detail: "Sửa thành công",
                life: 3000,
            });
            dispatch(setDataNull2());
        }
    }, [dispatch, updatePlanCategoryMaterialData]);

    useEffect(() => {
        if (errorUpdatePlanCategoryMaterial) {
            toast.current?.show({
                severity: "error",
                summary: "Thất bại",
                detail: errorUpdatePlanCategoryMaterial?.response?.data
                    ?.message,
                life: 3000,
            });
            // dispatch(setDataNull2());
        }
    }, [dispatch, errorUpdatePlanCategoryMaterial]);

    useEffect(() => {
        if (deletePlanCategoryMaterialData) {
            toast.current?.show({
                severity: "success",
                summary: "Thành công",
                detail: "Xóa thành công",
                life: 3000,
            });
            dispatch(setDataNull2());
        }
    }, [dispatch, deletePlanCategoryMaterialData]);

    useEffect(() => {
        if (errorDeletePlanCategoryMaterial) {
            toast.current?.show({
                severity: "error",
                summary: "Thất bại",
                detail: errorDeletePlanCategoryMaterial.response.data.message,
                life: 3000,
            });
            // dispatch(setDataNull2());
        }
    }, [dispatch, errorDeletePlanCategoryMaterial]);

    const actionBodyTemplate = (rowData) => {
        return (
            <ActionMenu
                onDelete={() => handleDelete(rowData)}
                onEdit={() => handleEdit(rowData)}
            />
        );
    };

    const handleEdit = (rowData) => {
        setRowData(rowData);
        setIsEditMode(true);
        formik.setValues({
            time: new Date(rowData.time),
            machining: rowData.machining,
            productId: productId,
            type: "machining",
            note: rowData.note,
            finishMachining: rowData.finishMachining,
        });
        setShowNewPlanFields(true);
    };

    return (
        <Dialog
            header="Kế hoạch sản xuất Leadtime hạng mục gia công"
            visible={isShow}
            style={{ width: "35vw" }}
            className="responsive-dialog"
            onHide={() => {
                formik.resetForm();
                setIsEditMode(false);
                setShowNewPlanFields(false);
                dispatch(setDataNull2());
                setChecked(false);
                onHide();
            }}
            footer={
                <div>
                    <Button
                        label="Hủy"
                        severity="secondary"
                        className="p-button-text"
                        onClick={() => {
                            formik.resetForm();
                            setIsEditMode(false);
                            setShowNewPlanFields(false);
                            onHide();
                            dispatch(setDataNull2());
                        }}
                    />
                    <Button
                        label="Lưu"
                        type="button"
                        onClick={() => formik.handleSubmit()}
                    />
                </div>
            }
        >
            <Toast ref={toast} />
            <DataTable
                emptyMessage="Không có dữ liệu"
                value={planCategoryMaterialData?.data?.items || []}
                tableStyle={{ minWidth: "20rem" }}
            >
                <Column
                    field="time"
                    header="Ngày"
                    style={{ width: "40%", height: "40px" }}
                    body={(rowData) => formatDate(rowData.time)}
                ></Column>
                <Column
                    field="machining"
                    header="SL kế hoạch"
                    style={{ width: "20%" }}
                    alignHeader={"center"}
                    align={"center"}
                ></Column>
                <Column
                    field="note"
                    header="Ghi chú"
                    style={{ width: "40%" }}
                    alignHeader={"center"}
                    align={"center"}
                ></Column>
                <Column
                    field="finishMachining"
                    header="Đã về"
                    style={{ width: "20%" }}
                    alignHeader={"center"}
                    align={"center"}
                    body={(rowData) => (
                        <Checkbox checked={rowData.finishMachining} />
                    )}
                ></Column>
                <Column
                    field="action"
                    body={actionBodyTemplate}
                    style={{ minWidth: "100px", padding: "0", height: "50px" }}
                />
            </DataTable>
            <ModelDelete
                visible={modalDelete}
                setVisible={setModalDelete}
                accept={acceptDelete}
            />
            {showNewPlanFields && (
                <div className="mt-2 flex justify-content-between gap-2">
                    <div className="flex flex-column" style={{ width: "48%" }}>
                        <Calendar
                            id="time"
                            name="time"
                            dateFormat="dd/mm/yy"
                            value={formik.values.time}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder="Chọn ngày"
                        />
                        {formik.touched.time && formik.errors.time ? (
                            <small className="p-error">
                                {formik.errors.time}
                            </small>
                        ) : null}
                    </div>
                    <div className="flex flex-column" style={{ width: "18%" }}>
                        <InputText
                            id="machining"
                            name="machining"
                            value={formik.values.machining}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder="Nhập số lượng"
                        />
                        {formik.touched.machining && formik.errors.machining ? (
                            <small className="p-error">
                                {formik.errors.machining}
                            </small>
                        ) : null}
                    </div>
                    <div className="flex flex-column" style={{ width: "28%" }}>
                        <InputText
                            id="note"
                            name="note"
                            value={formik.values.note}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder="Ghi chú"
                        />
                    </div>
                    <div
                        className="flex align-items-center"
                        style={{ width: "36%" }}
                    >
                        <Checkbox
                            id="finishMachining"
                            name="finishMachining"
                            checked={formik.values.finishMachining}
                            onChange={(e) =>
                                formik.setFieldValue(
                                    "finishMachining",
                                    e.checked
                                )
                            }
                        />
                    </div>
                </div>
            )}
            {!showNewPlanFields && (
                <Button
                    type="button"
                    style={{ marginTop: "1rem" }}
                    label="Thêm dòng"
                    onClick={() => setShowNewPlanFields(true)}
                />
            )}
        </Dialog>
    );
}

export default ModalShowCategoryMaterial;
