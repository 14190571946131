import React, { useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import FormatDate from "../Report/FormatDate";

import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

function ModalHistoryPlan({ isShow, onHide }) {
    const { historyData } = useSelector(
        (state) => ({
            historyData: state.DashboardPlan.historyData,
        }),
        shallowEqual
    );

    console.log("historyData", historyData?.data);

    return (
        <Dialog
            header="Lịch sử"
            visible={isShow}
            style={{ width: "30vw" }}
            className="responsive-dialog"
            onHide={() => {
                onHide();
            }}
        >
            <DataTable
                value={historyData?.data}
                emptyMessage="Không có dữ liệu"
                size="small"
                scrollHeight="65vh"
                scrollable
                selectionMode="checkbox"
                dataKey="id"
                rowClassName="custom-row-class-history"
            >
                <Column
                    header="Ngày"
                    field="time"
                    style={{ width: "6%", minWidth: "100px" }}
                    body={(rowData) => <div>{FormatDate(rowData?.time)}</div>}
                ></Column>
                <Column
                    header="Số lượng"
                    field="quantity"
                    style={{ width: "6%", minWidth: "100px" }}
                ></Column>
                <Column
                    header="Ngày tạo"
                    field="createdAt"
                    style={{ width: "6%", minWidth: "100px" }}
                    body={(rowData) => (
                        <div>{FormatDate(rowData?.createdAt)}</div>
                    )}
                ></Column>
            </DataTable>
        </Dialog>
    );
}

export default ModalHistoryPlan;
