import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import LoadingSpinner from "../Report/LoadingSpinner";
import { Button } from "primereact/button";
import Intersection from "../Plan/intersection";
import { FilterMatchMode } from "primereact/api";
import { Paginator } from "primereact/paginator";
import { imageTemplate, teamImageTemplate } from "../Report/ProductInprogress";
import ModalFilterCategoryComplete from "./ModalFilterCategoryComplete";
import QR from "../Plan/QR";
import FormatDate from "../Report/FormatDate";
import { QuantityTemplate, statusTemplate } from "../../components/utils";
import { statusFilterTemplate } from "../../constants/status";
import CustomMultiSelect from "../../constants/CustomMultiSelect";

const Complete = ({
    productIncompleteFactory,
    onPaginationChange,
    selectedStatusProductInComplete,
    setSelectedStatusProductInComplete,
}) => {
    const [expandedRows, setExpandedRows] = useState([]);
    const [dialogLocationVisible, setDialogLocationVisible] = useState(false);
    const [selectFactoryCode, setSelectFactoryCode] = useState(null);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [rowClick, setRowClick] = useState(true);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [qrDetail, setQrDetail] = useState(null);
    const [visible3, setVisible3] = useState(false);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        "Building.buildingCode": {
            value: null,
            matchMode: FilterMatchMode.CONTAINS,
        },
        orderCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
        categoryName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        factoryCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
        unit: { value: null, matchMode: FilterMatchMode.CONTAINS },
        productCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
        quantity: { value: null, matchMode: FilterMatchMode.CONTAINS },
        intendFinishDate: { value: null, matchMode: FilterMatchMode.DATE_IS },
        status: { value: [], matchMode: FilterMatchMode.IN },
    });

    const handleStatusChange = (newStatuses) => {
        setSelectedStatusProductInComplete(newStatuses);
    };

    const handleTeamProgress = (rowData) => {
        setSelectFactoryCode(rowData.factoryCode);
        setDialogLocationVisible(true);
    };
    const onPageChange = (event) => {
        const newFirst = event.first;
        const newRows = event.rows;

        setFirst(newFirst);
        setRows(newRows);
        if (onPaginationChange) {
            onPaginationChange(newFirst, newRows);
        }
    };
    const handleDetailScanned = (detail) => {
        setQrDetail(detail);
    };

    const handleSelectionChange = (e) => {
        const selected = e.value || [];
        const currentPageItems = productIncompleteFactory?.data?.items || [];

        // Loại bỏ các phần tử thuộc trang hiện tại khỏi selectedProducts
        const filteredProducts =
            selectedProducts?.filter(
                (item) => !currentPageItems.some((row) => row.id === item.id)
            ) || [];

        // Gộp các phần tử không bị xóa với phần tử vừa chọn
        const updatedSelection = [...filteredProducts, ...selected];

        // Loại bỏ phần tử trùng lặp
        const uniqueSelection = Array.from(
            new Map(updatedSelection.map((item) => [item.id, item])).values()
        );

        setSelectedProducts(uniqueSelection);
    };

    return (
        <div className="p-3 bg-white border-round-lg h-full">
            <div className="flex justify-content-between align-items-center mb-2">
                <p
                    style={{
                        color: "#5c5c5c",
                        fontWeight: "700",
                    }}
                >
                    Hoàn thành
                </p>
                <div className="flex gap-4">
                    <CustomMultiSelect
                        value={selectedStatusProductInComplete}
                        onChange={handleStatusChange}
                    />
                    <Button
                        label="Lọc theo hạng mục"
                        onClick={() => setOpenModal(true)}
                    ></Button>
                </div>
            </div>
            {productIncompleteFactory && productIncompleteFactory.data ? (
                <div>
                    <DataTable
                        value={productIncompleteFactory?.data?.items}
                        rowClassName="custom-row-class-factory"
                        emptyMessage="Không có dữ liệu"
                        // paginator
                        // rows={10}
                        // rowsPerPageOptions={[10, 25, 50, 100, 500]}
                        onRowToggle={(e) => setExpandedRows(e.data)}
                        filters={filters}
                        onFilter={(e) => setFilters(e.filters)}
                        scrollable
                        scrollHeight="70vh"
                        selectionMode="checkbox"
                        selection={selectedProducts || []}
                        onSelectionChange={handleSelectionChange}
                        dataKey="id"
                    >
                        <Column selectionMode="multiple"></Column>
                        <Column
                            header="STT"
                            body={(rowData, options) => (
                                <div className="text-center">
                                    {first + options.rowIndex + 1}
                                </div>
                            )}
                            style={{ width: "3%" }}
                            alignHeader={"center"}
                            align={"center"}
                        ></Column>
                        <Column
                            header="Hạng mục"
                            field="categoryName"
                            style={{ lineHeight: "1.5", minWidth: "150px" }}
                            filter
                        />
                        <Column
                            header="Tên công trình"
                            field="Building.name"
                            style={{ minWidth: "150px" }}
                            filter
                        />
                        <Column
                            header="Hình ảnh"
                            style={{ minWidth: "100px" }}
                            body={imageTemplate}
                            alignHeader={"center"}
                            align={"center"}
                        ></Column>
                        <Column
                            header="Mã nhà máy"
                            field="factoryCode"
                            style={{ minWidth: "100px" }}
                            filter
                        />
                        <Column
                            header="ĐVT"
                            field="unit"
                            style={{ minWidth: "50px" }}
                            filter
                        />
                        <Column
                            header="Số lượng"
                            field="quantity"
                            style={{ minWidth: "50px" }}
                        />

                        <Column
                            header="Kế hoạch hoàn thành"
                            field="intendFinishDate"
                            body={(rowData) =>
                                rowData.intendFinishDate && (
                                    <div
                                        style={{
                                            backgroundColor: `${rowData.color}`,
                                            padding: "4px 0",
                                            margin: "0 26px",
                                            borderRadius: "5px",
                                        }}
                                    >
                                        {FormatDate(rowData.intendFinishDate)}
                                    </div>
                                )
                            }
                            style={{ minWidth: "120px" }}
                            sortable
                            align="center"
                            alignHeader="center"
                        ></Column>
                        <Column
                            header="Tiến độ giao hàng"
                            alignHeader={"center"}
                            align={"center"}
                            body={QuantityTemplate}
                            style={{ minWidth: "100px" }}
                        />

                        <Column
                            header="Định vị"
                            alignHeader={"center"}
                            align={"center"}
                            body={(rowData) => (
                                <div className="text-center">
                                    <Button
                                        onClick={() =>
                                            handleTeamProgress(rowData)
                                        }
                                        icon="pi pi-map-marker"
                                        rounded
                                        text
                                        severity="secondary"
                                        aria-label="Bookmark"
                                        style={{ color: "black" }}
                                    />
                                    <p>
                                        {rowData.status !==
                                            "Hoàn thành đúng tiến độ" &&
                                        rowData.status !==
                                            "Hoàn thành trễ tiến độ"
                                            ? rowData.teamWorking
                                            : "Đã giao"}
                                    </p>
                                </div>
                            )}
                            style={{ minWidth: "100px" }}
                        />
                        <Column
                            header="Trạng thái"
                            field="status"
                            body={statusTemplate}
                            alignHeader={"center"}
                            align={"center"}
                            style={{ minWidth: "120px" }}
                            // filter
                            // filterElement={statusFilterTemplate}
                            // pt={{ filterButtonbar: { className: "hidden" } }}
                        ></Column>
                        <Column
                            header="Hình ảnh SX"
                            body={teamImageTemplate}
                            alignHeader={"center"}
                            align={"center"}
                            style={{ minWidth: "100px" }}
                        ></Column>
                        <Column
                            header="In QR"
                            body={(rowData) => (
                                <div className="text-center">
                                    <Button
                                        onClick={() => {
                                            setQrDetail(rowData);
                                            setVisible3(true);
                                        }}
                                        icon="pi pi-qrcode"
                                        rounded
                                        text
                                        severity="secondary"
                                        aria-label="QR"
                                        style={{ color: "black" }}
                                    />
                                </div>
                            )}
                            style={{ width: "8%", minWidth: "100px" }}
                            alignHeader={"center"}
                            align={"center"}
                        ></Column>
                        <Column
                            header="Ghi chú"
                            field="productNote"
                            style={{ minWidth: "200px" }}
                        ></Column>
                    </DataTable>
                    <Paginator
                        first={first}
                        rows={rows}
                        totalRecords={
                            productIncompleteFactory?.data?.totalItems
                        }
                        rowsPerPageOptions={[10, 20, 30, 100, 200, 500]}
                        onPageChange={onPageChange}
                    />
                    <Intersection
                        visible={dialogLocationVisible}
                        onHide={() => setDialogLocationVisible(false)}
                    />
                    <ModalFilterCategoryComplete
                        visible={openModal}
                        onHide={() => setOpenModal(false)}
                        data={selectedProducts}
                    />
                    <QR
                        isShow={visible3}
                        hiden={() => setVisible3(false)}
                        detail={qrDetail}
                        onDetailScanned={handleDetailScanned}
                    />
                </div>
            ) : (
                <LoadingSpinner />
            )}
        </div>
    );
};

export default Complete;
