import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    dashboardFactoryData: null,
    productInprogressFactory: null,
    productInlateFactory: null,
    productIncompleteFactory: null,
    error: null,
    loading: false,
    excelDataFactory: null,
    errorExcelDataFactory: null,
    excelDataBuilding: null,
    errorExcelDataBuilding: null,
    loadingExcelFactory: false,
    allData: [], // Store accumulated data
    currentPage: 1, // Track the current page
    hasMoreData: true,
    allDataInlate: [],
    currentPageInlate: 1,
};

const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {
        getDashboardFactory: (state) => {
            state.loading = true;
        },
        getDashboardFactorySuccess: (state, action) => {
            state.loading = false;
            state.dashboardFactoryData = action.payload;
            state.error = null;
        },
        getDashboardFactoryFail: (state, action) => {
            state.loading = false;
            state.dashboardFactoryData = null;
            state.error = action.payload;
        },
        getProductInprogressFactory: (state) => {
            state.loading = true;
        },
        getProductInprogressFactorySuccess: (state, action) => {
            state.loading = false;
            state.productInprogressFactory = action.payload;
            state.error = null;
        },
        getProductInprogressFactoryFail: (state, action) => {
            state.loading = false;
            state.productInprogressFactory = null;
            state.error = action.payload;
        },
        getProductInlateFactory: (state) => {
            state.loading = true;
        },
        getProductInlateFactorySuccess: (state, action) => {
            state.loading = false;
            state.productInlateFactory = action.payload;
            state.error = null;
        },
        getProductInlateFactoryFail: (state, action) => {
            state.loading = false;
            state.productInlateFactory = null;
            state.error = action.payload;
        },
        getProductIncompleteFactory: (state) => {
            state.loading = true;
        },
        getProductIncompleteFactorySuccess: (state, action) => {
            state.loading = false;
            state.productIncompleteFactory = action.payload;
            state.error = null;
        },
        getProductIncompleteFactoryFail: (state, action) => {
            state.loading = false;
            state.productIncompleteFactory = null;
            state.error = action.payload;
        },
        downloadExcelFactory: (state, action) => {
            state.loadingExcelFactory = true;
        },
        downloadExcelFactorySuccess: (state, action) => {
            state.loadingExcelFactory = false;
            state.excelDataFactory = action.payload;
            state.errorExcelDataFactory = null;
        },
        downloadExcelFactoryFail: (state, action) => {
            state.loadingExcelFactory = false;
            state.excelDataFactory = null;
            state.errorExcelDataFactory = action.payload;
        },
        downloadExcelBuilding: (state, action) => {
            state.loading = true;
        },
        downloadExcelBuildingSuccess: (state, action) => {
            state.loading = false;
            state.excelDataBuilding = action.payload;
            state.errorExcelDataBuilding = null;
        },
        downloadExcelBuildingFail: (state, action) => {
            state.loading = false;
            state.excelDataBuilding = null;
            state.errorExcelDataBuilding = action.payload;
        },
        setExcelDataBuildingNull: (state) => {
            state.excelDataBuilding = null;
            state.errorExcelDataBuilding = null;
            state.excelDataFactory = null;
            state.errorExcelDataFactory = null;
        },
        getAllProductInprogress: (state) => {
            state.loading = true;
        },
        getAllProductInprogressSuccess: (state, action) => {
            state.loading = false;
            state.allData = action.payload;
            state.hasMoreData =
                state.currentPage < action.payload.data.totalPages;
            if (state.hasMoreData) {
                state.currentPage += 1;
            }
            state.error = null;
        },
        getAllProductInprogressFail: (state, action) => {
            state.loading = false;
            state.allData = null;
            state.error = action.payload;
        },
        getAllProductInlate: (state) => {
            state.loading = true;
        },
        getAllProductInlateSuccess: (state, action) => {
            state.loading = false;
            state.allDataInlate = action.payload;

            state.hasMoreData = action.payload.data.length === 100;
            if (state.currentPageInlate < action.payload.data.totalPages) {
                state.currentPageInlate += 1;
            }
            state.error = null;
        },
        getAllProductInlateFail: (state, action) => {
            state.loading = false;
            state.allDataInlate = null;
            state.error = action.payload;
        },
        resetPagination: (state) => {
            state.allData = [];
            state.allDataInlate = [];
            state.currentPage = 1;
            state.currentPageInlate = 1;
            state.hasMoreData = true;
            state.error = null;
        },
    },
});

export const {
    getDashboardFactory,
    getDashboardFactorySuccess,
    getDashboardFactoryFail,
    getProductInprogressFactory,
    getProductInprogressFactorySuccess,
    getProductInprogressFactoryFail,
    getProductInlateFactory,
    getProductInlateFactorySuccess,
    getProductInlateFactoryFail,
    getProductIncompleteFactory,
    getProductIncompleteFactoryFail,
    getProductIncompleteFactorySuccess,
    downloadExcelFactory,
    downloadExcelFactoryFail,
    downloadExcelFactorySuccess,
    downloadExcelBuilding,
    downloadExcelBuildingFail,
    downloadExcelBuildingSuccess,
    setExcelDataBuildingNull,
    getAllProductInprogress,
    getAllProductInprogressSuccess,
    getAllProductInprogressFail,
    getAllProductInlate,
    getAllProductInlateFail,
    getAllProductInlateSuccess,
    resetPagination,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
