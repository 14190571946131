import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  planSampleExcel: {
    data: null,
    error: null,
    loading: null,
  },
  planResultUpload: {
    data: null,
    error: null,
    loading: null,
  },
};

const excelSlice = createSlice({
  name: 'excel',
  initialState,
  reducers: {
    downloadSampleFileRequest: (state, action) => {
      state.planSampleExcel.loading = true;
    },
    downloadSampleFileSuccess: (state, action) => {
      state.planSampleExcel.loading = false;
      state.planSampleExcel.data = action.payload;
    },
    downloadSampleFileFailure: (state, action) => {
      state.planSampleExcel.loading = false;
      state.planSampleExcel.error = action.payload;
    },
    uploadPlanExcelRequest: (state) => {
      state.planResultUpload.loading = true;
    },
    uploadPlanExcelSuccess: (state, action) => {
      state.planResultUpload.loading = false;
      state.planResultUpload.data = action.payload;
    },
    uploadPlanExcelFailure: (state, action) => {
      state.planResultUpload.loading = false;
      state.planResultUpload.error = action.payload;
    },
    clearDataExcel: (state) => {
      state.planSampleExcel.data = null;
      state.planSampleExcel.error = null;
      state.planResultUpload.data = null;
      state.planResultUpload.error = null;
    },
  },
});

export const {
  downloadSampleFileRequest,
  downloadSampleFileSuccess,
  downloadSampleFileFailure,
  uploadPlanExcelRequest,
  uploadPlanExcelSuccess,
  uploadPlanExcelFailure,
  clearDataExcel,
} = excelSlice.actions;
export default excelSlice.reducer;
