import React, { useEffect, useState, useContext, useRef } from "react";
import HeaderReport from "../../layout/HeaderReport/HeaderReport";
import ColumnChart from "./ColumnChart";
import Progress from "./Progress";
import TeamDetail from "./TeamDetail";
import ColumnChartPDF from "./ColumnChartPDF";
import {
    getDashboardTeams,
    getDashboardTeamsDetail,
    getDashboardTeamsWorking,
    setExcelDataTeamNull,
    getDashboardAllTeamsWorking,
    getDashboardAllTeamsDetail,
} from "../../store/dashboardTeams/slice";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import "../../assets/scss/theme-base/dashboard.css";
import { exportTeamPDF } from "./ExportTeamPDF";
import FormatDate from "../Report/FormatDate";
import { DataContext } from "../../contexts/data/DataProvider";
import { downloadExcelTeam } from "../../store/dashboardTeams/slice";
import { Toast } from "primereact/toast";
import { site } from "../../api/url_helper";
import { formatDateApi } from "../../feature/formatDateApi";
import { resetPaginationTeam } from "../../store/dashboardTeams/slice";
import { useLocation } from "react-router-dom";
import axios from "axios";

export default function ReportTeam() {
    const dispatch = useDispatch();
    const toast = useRef(null);
    const downloadLink = useRef(null);
    const location = useLocation();

    const [building, setBuilding] = useState(null);
    const [orderCode, setOrderCode] = useState("");
    const [category, setCategory] = useState("");
    const [factoryCode, setFactoryCode] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [selectedStatus, setSelectedStatus] = useState({
        name: "",
        code: "",
    });
    const [team, setTeam] = useState("");
    const [nameBuilding, setNameBuilding] = useState("");
    const [pagination, setPagination] = useState({ first: 0, rows: 10 });
    const [pagination1, setPagination1] = useState({ first: 0, rows: 10 });
    const [selectedStatuses, setSelectedStatuses] = useState([]);
    const [allDataTeamWorking, setAllDataTeamWorking] = useState([]);
    const [allDataTeamDetail, setAllDataTeamDetail] = useState([]);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [page, setPage] = useState(1);
    const token = localStorage.getItem("accessToken");

    useEffect(() => {
        dispatch(resetPaginationTeam());
    }, [location.pathname, dispatch]);

    const {
        buildingData,
        excelDataTeam,
        errorExcelDataTeam,
        currentPageWorking,
        allTeamWorkingData,
        allDetailWorkingData,
        currentPageDetailWorking,
    } = useSelector(
        (state) => ({
            excelDataTeam: state.DashboardTeams.excelDataTeam,
            errorExcelDataTeam: state.DashboardTeams.errorExcelDataTeam,
            buildingData: state.Production.dataBuilding,
            currentPageWorking: state.DashboardTeams.currentPageTeamWorking,
            allTeamWorkingData: state.DashboardTeams.allTeamWorkingData,
            allDetailWorkingData: state.DashboardTeams.allDetailWorkingData,
            currentPageDetailWorking:
                state.DashboardTeams.currentPageDetailWorking,
        }),
        shallowEqual
    );

    useEffect(() => {
        if (
            building ||
            orderCode ||
            category ||
            factoryCode ||
            startDate ||
            endDate
        ) {
            setAllDataTeamWorking([]);
        }
    }, [building, orderCode, category, factoryCode, startDate, endDate]);
    useEffect(() => {
        if (
            building ||
            orderCode ||
            category ||
            factoryCode ||
            startDate ||
            endDate ||
            selectedStatuses
        ) {
            setAllDataTeamDetail([]);
        }
    }, [
        building,
        orderCode,
        category,
        factoryCode,
        startDate,
        endDate,
        selectedStatuses,
    ]);

    // useEffect(() => {
    //     if (allTeamWorkingData?.data?.items) {
    //         setAllDataTeamWorking((prevData) => {
    //             const existingIds = new Set(prevData.map((item) => item.name));

    //             const newItems = allTeamWorkingData.data.items.filter(
    //                 (item) => !existingIds.has(item.name)
    //             );

    //             return [...prevData, ...newItems];
    //         });
    //     }
    // }, [
    //     allTeamWorkingData,
    //     currentPageWorking,
    //     startDate,
    //     endDate,
    //     building,
    //     orderCode,
    //     category,
    //     factoryCode,
    // ]);

    // useEffect(() => {
    //     if (allDetailWorkingData?.data?.items) {
    //         setAllDataTeamDetail((prevData) => {
    //             const existingIds = new Set(prevData.map((item) => item.id));

    //             const newItems = allDetailWorkingData.data.items.filter(
    //                 (item) => !existingIds.has(item.id)
    //             );

    //             return [...prevData, ...newItems];
    //         });
    //     }
    // }, [
    //     allDetailWorkingData,
    //     currentPageWorking,
    //     startDate,
    //     endDate,
    //     building,
    //     orderCode,
    //     category,
    //     factoryCode,
    // ]);

    useEffect(() => {
        const isLoaded =
            allDataTeamWorking.length ===
            allTeamWorkingData?.data?.result?.totalItems;

        if (isLoaded) {
            setIsDataLoaded(true);
        } else {
            setIsDataLoaded(false);
        }
    }, [allDataTeamWorking, allTeamWorkingData]);

    // useEffect(() => {
    //     if (startDate && endDate) {
    //         dispatch(
    //             getDashboardAllTeamsWorking({
    //                 page: currentPageWorking,
    //                 limit: 100,
    //                 timeStartReq: startDate,
    //                 timeEndReq: endDate,
    //                 building,
    //                 orderCode,
    //                 category,
    //                 factoryCode,
    //             })
    //         );
    //     }
    // }, [
    //     dispatch,
    //     currentPageWorking,
    //     startDate,
    //     endDate,
    //     building,
    //     orderCode,
    //     category,
    //     factoryCode,
    // ]);

    const handlePaginationChange = (first, rows) => {
        setPagination({ first, rows });
    };
    const handlePaginationChange1 = (first, rows) => {
        setPagination1({ first, rows });
    };

    const { buildingName } = useContext(DataContext);
    useEffect(() => {
        if (buildingName) setNameBuilding(buildingName);
    }, [buildingName]);

    const handleStatusChange = (status) => {
        setSelectedStatus(status);
    };

    const handleTeamChange = (teamName) => {
        setTeam(teamName);
    };

    const dashboardTeams = useSelector(
        (state) => state.DashboardTeams.dashboardTeamsData,
        shallowEqual
    );

    const dashboardTeamsDetail = useSelector(
        (state) => state.DashboardTeams.dashboardTeamsDetailData,
        shallowEqual
    );

    const dashboardTeamsWorking = useSelector(
        (state) => state.DashboardTeams.dashboardTeamsWorkingData,
        shallowEqual
    );

    useEffect(() => {
        if (startDate && endDate) {
            dispatch(
                getDashboardTeams({
                    timeStartReq: startDate,
                    timeEndReq: endDate,
                    building,
                    orderCode,
                    category,
                    factoryCode,
                })
            );
        }
    }, [
        dispatch,
        startDate,
        endDate,
        building,
        orderCode,
        category,
        factoryCode,
    ]);
    //get All
    // useEffect(() => {
    //     if ((startDate, endDate)) {
    //         dispatch(
    //             getDashboardAllTeamsDetail({
    //                 page: currentPageDetailWorking,
    //                 limit: 100,
    //                 timeStartReq: startDate,
    //                 timeEndReq: endDate,
    //                 building,
    //                 orderCode,
    //                 category,
    //                 factoryCode,
    //                 team,
    //                 status: selectedStatus?.code,
    //                 filterStatus: JSON.stringify(selectedStatuses),
    //             })
    //         );
    //     }
    // }, [
    //     dispatch,
    //     currentPageDetailWorking,
    //     startDate,
    //     endDate,
    //     building,
    //     orderCode,
    //     category,
    //     factoryCode,
    //     team,
    //     selectedStatus,
    //     selectedStatuses,
    // ]);

    useEffect(() => {
        if ((startDate, endDate)) {
            dispatch(
                getDashboardTeamsDetail({
                    page:
                        (pagination.first + pagination.rows) / pagination.rows,
                    limit: pagination.rows,
                    timeStartReq: startDate,
                    timeEndReq: endDate,
                    building,
                    orderCode,
                    category,
                    factoryCode,
                    team,
                    status: selectedStatus?.code,
                    filterStatus: JSON.stringify(selectedStatuses),
                })
            );
        }
    }, [
        dispatch,
        startDate,
        endDate,
        building,
        orderCode,
        category,
        factoryCode,
        team,
        selectedStatus,
        pagination,
        selectedStatuses,
    ]);

    useEffect(() => {
        if (startDate && endDate) {
            dispatch(
                getDashboardTeamsWorking({
                    page:
                        (pagination1.first + pagination1.rows) /
                        pagination1.rows,
                    limit: pagination1.rows,
                    timeStartReq: startDate,
                    timeEndReq: endDate,
                    building,
                    orderCode,
                    category,
                    factoryCode,
                })
            );
        }
    }, [
        dispatch,
        startDate,
        endDate,
        building,
        orderCode,
        category,
        factoryCode,
        pagination1,
    ]);

    //export excel

    const exportExcelHandle = () => {
        const id = buildingData?.data?.items.find(
            (item) => item.buildingCode === building
        )?.id;

        if (id) {
            dispatch(
                downloadExcelTeam({
                    building_id: id,
                    timeStartReq: formatDateApi(startDate),
                    timeEndReq: formatDateApi(endDate),
                })
            );
        } else {
            dispatch(
                downloadExcelTeam({
                    timeStartReq: formatDateApi(startDate),
                    timeEndReq: formatDateApi(endDate),
                })
            );
        }
    };

    useEffect(() => {
        if (excelDataTeam) {
            const url = `${site}/${excelDataTeam.file}`;
            toast.current.show({
                severity: "success",
                summary: "Thành công",
                detail: "Xuất excel thành công.",
                life: 3000,
            });
            dispatch(setExcelDataTeamNull());

            // Tự động tải file
            downloadLink.current.href = url;
            downloadLink.current.download = "exported_data.xlsx";
            downloadLink.current.click();
        }
    }, [dispatch, excelDataTeam]);

    useEffect(() => {
        if (errorExcelDataTeam) {
            toast.current.show({
                severity: "error",
                summary: "Thất bại",
                detail: errorExcelDataTeam.response.data.message,
                life: 3000,
            });
            dispatch(setExcelDataTeamNull());
        }
    }, [dispatch, errorExcelDataTeam]);

    const handleExportClick = async (type) => {
        if (startDate && endDate) {
            try {
                // Fetch the first page's data to get the total pages for pagination
                const response = await axios.get(
                    `${site}/api/dashboard/team-statistics/working`,
                    {
                        params: {
                            page: page,
                            limit: 100,
                            timeStartReq: startDate,
                            timeEndReq: endDate,
                            building,
                            orderCode,
                            category,
                            factoryCode,
                            filterStatus: JSON.stringify(selectedStatuses),
                        },
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json", // Fix header key
                        },
                        withCredentials: true,
                    }
                );
                const responseDetail = await axios.get(
                    `${site}/api/dashboard/team-statistics/details`,
                    {
                        params: {
                            page: page,
                            limit: 100,
                            timeStartReq: startDate,
                            timeEndReq: endDate,
                            building,
                            orderCode,
                            category,
                            factoryCode,
                            filterStatus: JSON.stringify(selectedStatuses),
                        },
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json", // Fix header key
                        },
                        withCredentials: true,
                    }
                );

                const totalPages = response?.data?.data?.totalPages;
                const totalPagesDetail = responseDetail?.data?.data?.totalPages;

                if (!totalPages) {
                    throw new Error("Total pages data is missing.");
                }
                if (!totalPagesDetail) {
                    throw new Error("Total pages data is missing.");
                }

                const allDataArray = [];
                const requests = [];
                const allDataArrayDetail = [];
                const requestsDetail = [];

                // Fetch data for all pages concurrently
                for (let page = 1; page <= totalPages; page++) {
                    requests.push(
                        axios.get(
                            `${site}/api/dashboard/team-statistics/working`,
                            {
                                params: {
                                    page: page,
                                    limit: 100,
                                    timeStartReq: startDate,
                                    timeEndReq: endDate,
                                    building,
                                    orderCode,
                                    category,
                                    factoryCode,
                                    filterStatus:
                                        JSON.stringify(selectedStatuses),
                                },
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                    "Content-Type": "application/json", // Fix header key
                                },
                                withCredentials: true,
                            }
                        )
                    );
                }
                for (let page = 1; page <= totalPagesDetail; page++) {
                    requestsDetail.push(
                        axios.get(
                            `${site}/api/dashboard/team-statistics/details`,
                            {
                                params: {
                                    page: page,
                                    limit: 100,
                                    timeStartReq: startDate,
                                    timeEndReq: endDate,
                                    building,
                                    orderCode,
                                    category,
                                    factoryCode,
                                    filterStatus:
                                        JSON.stringify(selectedStatuses),
                                },
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                    "Content-Type": "application/json", // Fix header key
                                },
                                withCredentials: true,
                            }
                        )
                    );
                }

                const responses = await Promise.all(requests);
                const responsesDetail = await Promise.all(requestsDetail);

                responses.forEach((res) => {
                    allDataArray.push(...res.data?.data?.items);
                });
                responsesDetail.forEach((res) => {
                    allDataArrayDetail.push(...res.data?.data?.items);
                });

                // Trigger the export logic
                exportTeamPDF(
                    type,
                    FormatDate(startDate),
                    FormatDate(endDate),
                    allDataArray,
                    allDataArrayDetail,
                    nameBuilding
                );
            } catch (error) {
                console.error("Error fetching data:", error);
                toast.current.show({
                    severity: "error",
                    summary: "Lỗi",
                    detail: "Có lỗi xảy ra khi tải dữ liệu.",
                    life: 3000,
                });
            }
        } else {
            console.log("Start Date or End Date is missing");
        }
    };

    return (
        <>
            <div>
                <Toast ref={toast} />
                <a ref={downloadLink} style={{ display: "none" }} />
                <HeaderReport
                    building={building}
                    orderCode={orderCode}
                    category={category}
                    factoryCode={factoryCode}
                    setBuilding={setBuilding}
                    setOrderCode={setOrderCode}
                    setCategory={setCategory}
                    setFactoryCode={setFactoryCode}
                    setStartDateOutput={setStartDate}
                    setEndDateOutput={setEndDate}
                    exportClick={(e) => handleExportClick("save")}
                    printReport={(e) => handleExportClick("print")}
                    exportExcel={exportExcelHandle}
                />
            </div>
            <div
                id="column-chart"
                style={{
                    width: "100%",
                    height: "400px",
                    position: "absolute",
                    left: "-9999999999px",
                    transform: "scale(0.5)",
                }}
            >
                <ColumnChartPDF dashboardTeams={dashboardTeams} />
            </div>
            <div className="mt-3 border-round-lg shadow-1">
                <ColumnChart dashboardTeams={dashboardTeams} />
            </div>
            <div className="flex team-container flex-column mt-3 gap-3 ">
                <div className="w-full shadow-1 border-round-lg">
                    <Progress
                        dashboardTeamsWorking={dashboardTeamsWorking}
                        onPaginationChange={handlePaginationChange1}
                    />
                </div>
                <div className="w-full shadow-1 border-round-lg">
                    <TeamDetail
                        dashboardTeamsDetail={dashboardTeamsDetail}
                        onStatusChange={handleStatusChange}
                        onTeamChange={handleTeamChange}
                        onPaginationChange={handlePaginationChange}
                        selectedStatuses={selectedStatuses}
                        setSelectedStatuses={setSelectedStatuses}
                    />
                </div>
            </div>
        </>
    );
}
