import { useRef, useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import * as Yup from "yup";
import { useFormik } from "formik";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
    getPlan,
    setPlan,
    updatePlan,
    deletePlan,
    setDataNull2,
} from "../../store/order/slice";
import ActionMenu from "../../components/ActionMenu";
import ModelDelete from "../../components/ModalDelete";
import { Toast } from "primereact/toast";
import "../../assets/scss/theme-base/ModalShowPlan.css";
import ModalHistoryPlan from "./ModalHistoryPlan";

function formatDateYMD(isoDate) {
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const day = String(date.getDate()).padStart(2, "0"); // Lấy ngày theo múi giờ địa phương
    const month = String(date.getMonth() + 1).padStart(2, "0");
    return `${year}-${month}-${day}`;
}

export const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
};

function ModalShowPlan({ isShow, onHide, productId, noChange, selectedRow }) {
    const [showNewPlanFields, setShowNewPlanFields] = useState(false);
    const toast = useRef(null);
    const [modalDelete, setModalDelete] = useState(false);
    const [rowData, setRowData] = useState();
    const [isEditMode, setIsEditMode] = useState(false);
    const [openModalHistory, setOpenModalHistory] = useState(false);
    const dispatch = useDispatch();
    const {
        planData,
        updatePlanData,
        deletePlanData,
        errorDeletePlan,
        addPlanData,
        errorAddPlan,
        errorUpdatePlan,
    } = useSelector(
        (state) => ({
            planData: state.Production.planData,
            updatePlanData: state.Production.updatePlanData,
            deletePlanData: state.Production.deletePlanData,
            errorDeletePlan: state.Production.errorDeletePlan,
            addPlanData: state.Production.addPlanData,
            errorAddPlan: state.Production.errorAddPlan,
            errorUpdatePlan: state.Production.errorUpdatePlan,
        }),
        shallowEqual
    );

    const validationSchema = Yup.object({
        time: Yup.string().required("Giá trị này là bắt buộc"),
        productionPlan: Yup.string().required("Giá trị này là bắt buộc"),
    });

    const remainingQuantity =
        planData?.data?.items.length > 0
            ? planData.data.items[0].Product.quantity -
              planData.data.items[0].Product.totalProductionPlan
            : 0;

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            time: "",
            productionPlan: "",
            productId: productId,
            type: "production-plan",
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            const formattedValues = {
                ...values,
                time: values.time
                    ? formatDateYMD(values.time.toISOString())
                    : "",
            };

            if (isEditMode && rowData) {
                dispatch(updatePlan({ id: rowData.id, data: formattedValues }));
            } else {
                dispatch(setPlan(formattedValues));
            }
            setShowNewPlanFields(false);
            setIsEditMode(false);
            formik.resetForm();
        },
    });

    useEffect(() => {
        if (isShow && productId) {
            dispatch(
                getPlan({
                    page: 1,
                    limit: 100,
                    productId,
                    type: "production-plan",
                })
            );
        }
    }, [dispatch, isShow, productId]);

    useEffect(() => {
        if (deletePlanData || updatePlanData || addPlanData) {
            dispatch(
                getPlan({
                    page: 1,
                    limit: 100,
                    productId,
                    type: "production-plan",
                })
            );
            dispatch(setDataNull2());
        }
    }, [deletePlanData, updatePlanData, addPlanData, dispatch]);

    const handleDelete = (rowData) => {
        setModalDelete(true);
        setRowData(rowData);
    };

    const acceptDelete = () => {
        if (rowData && rowData !== undefined) {
            const id = rowData.id;
            if (id !== undefined) {
                dispatch(deletePlan({ id }));
            }
            setModalDelete(false);
        }
    };

    useEffect(() => {
        if (addPlanData) {
            toast.current?.show({
                severity: "success",
                summary: "Thành công",
                detail: "Thêm thành công",
                life: 3000,
            });
            dispatch(setDataNull2());
        }
    }, [dispatch, addPlanData]);

    useEffect(() => {
        if (errorAddPlan) {
            toast.current?.show({
                severity: "error",
                summary: "Thất bại",
                detail: errorAddPlan?.response?.data?.message,
                life: 3000,
            });
        }
    }, [dispatch, errorAddPlan]);

    useEffect(() => {
        if (updatePlanData) {
            toast.current?.show({
                severity: "success",
                summary: "Thành công",
                detail: "Sửa thành công",
                life: 3000,
            });
            dispatch(setDataNull2());
        }
    }, [dispatch, updatePlanData]);

    useEffect(() => {
        if (errorUpdatePlan) {
            toast.current?.show({
                severity: "error",
                summary: "Thất bại",
                detail: errorUpdatePlan?.response?.data?.message,
                life: 3000,
            });
            // dispatch(setDataNull2());
        }
    }, [dispatch, errorUpdatePlan]);

    useEffect(() => {
        if (deletePlanData) {
            toast.current?.show({
                severity: "success",
                summary: "Thành công",
                detail: "Xóa thành công",
                life: 3000,
            });
            dispatch(setDataNull2());
        }
    }, [dispatch, deletePlanData]);

    useEffect(() => {
        if (errorDeletePlan) {
            toast.current?.show({
                severity: "error",
                summary: "Thất bại",
                detail: errorDeletePlan.response.data.message,
                life: 3000,
            });
            dispatch(setDataNull2());
        }
    }, [dispatch, errorDeletePlan]);

    const actionBodyTemplate = (rowData) => {
        return !noChange ? (
            <ActionMenu
                onDelete={() => handleDelete(rowData)}
                onEdit={() => handleEdit(rowData)}
            />
        ) : (
            <></>
        );
    };

    const handleEdit = (rowData) => {
        setRowData(rowData);
        setIsEditMode(true);
        formik.setValues({
            time: new Date(rowData.time),
            productionPlan: rowData.productionPlan,
            productId: productId,
            type: "production-plan",
        });
        setShowNewPlanFields(true);
    };

    const handleHistory = () => {
        setOpenModalHistory(true);
    };

    return (
        <Dialog
            header={
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <div
                        style={{
                            backgroundColor: `${selectedRow?.color}`,
                            padding: "4px 8px",
                            borderRadius: "5px",
                            width: "fit-content",
                        }}
                    >
                        Kế hoạch sản xuất
                    </div>
                    <Button
                        label="Lịch sử"
                        type="button"
                        onClick={handleHistory}
                    />
                </div>
            }
            visible={isShow}
            style={{ width: "30vw" }}
            className="responsive-dialog"
            onHide={() => {
                formik.resetForm();
                setIsEditMode(false);
                setShowNewPlanFields(false);
                onHide();
                dispatch(setDataNull2());
            }}
            footer={
                <div>
                    <Button
                        label="Hủy"
                        severity="secondary"
                        className="p-button-text"
                        onClick={() => {
                            formik.resetForm();
                            setIsEditMode(false);
                            setShowNewPlanFields(false);
                            onHide();
                            dispatch(setDataNull2());
                        }}
                    />
                    <Button
                        label="Lưu"
                        type="button"
                        onClick={() => formik.handleSubmit()}
                    />
                </div>
            }
        >
            <Toast ref={toast} />
            <DataTable
                emptyMessage="Không có dữ liệu"
                value={planData?.data?.items || []}
                tableStyle={{ minWidth: "20rem" }}
            >
                <Column
                    field="time"
                    header="Ngày"
                    style={{ width: "70%", height: "40px" }}
                    body={(rowData) => formatDate(rowData.time)}
                ></Column>
                <Column
                    field="productionPlan"
                    header="SL kế hoạch"
                    style={{ width: "20%", height: "40px" }}
                    alignHeader={"center"}
                    align={"center"}
                ></Column>
                <Column
                    field="action"
                    body={actionBodyTemplate}
                    style={{ minWidth: "100px", padding: "0", height: "50px" }}
                />
            </DataTable>
            <ModelDelete
                visible={modalDelete}
                setVisible={setModalDelete}
                accept={acceptDelete}
            />
            <div className="pt-2">Số lượng còn lại: {remainingQuantity}</div>
            {showNewPlanFields && (
                <div className="mt-2 flex justify-content-between gap-2">
                    <div className="flex flex-column" style={{ width: "48%" }}>
                        <Calendar
                            id="time"
                            name="time"
                            dateFormat="dd/mm/yy"
                            value={formik.values.time}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder="Chọn ngày"
                        />
                        {formik.touched.time && formik.errors.time ? (
                            <small className="p-error">
                                {formik.errors.time}
                            </small>
                        ) : null}
                    </div>
                    <div className="flex flex-column" style={{ width: "48%" }}>
                        <InputText
                            id="productionPlan"
                            name="productionPlan"
                            value={formik.values.productionPlan}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder="Nhập số lượng"
                        />
                        {formik.touched.productionPlan &&
                        formik.errors.productionPlan ? (
                            <small className="p-error">
                                {formik.errors.productionPlan}
                            </small>
                        ) : null}
                    </div>
                </div>
            )}
            {!showNewPlanFields && !noChange && (
                <Button
                    type="button"
                    style={{ marginTop: "1rem" }}
                    label="Thêm dòng"
                    onClick={() => setShowNewPlanFields(true)}
                />
            )}
            <ModalHistoryPlan
                isShow={openModalHistory}
                onHide={() => setOpenModalHistory(false)}
            />
        </Dialog>
    );
}

export default ModalShowPlan;
