import React, { useState, useRef, useEffect } from "react";
import { Button } from "primereact/button";
import { IconField } from "primereact/iconfield";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { TabMenu } from "primereact/tabmenu";
import "../../assets/scss/theme-base/QuantityTemplate.css";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import tabMenuTemplate from "../../assets/templete/tabMenuTemplete";
import ModalAddPlan from "./ModalAddPlan";
import ModalAddComplete from "./ModalAddComplete";
import ActualComplete from "./ActualComplete";
import ModalAddPrice from "./ModalAddPrice";
import { statusTemplate, formatNumber } from "../../components/utils";
import {
    getOrder,
    getBuilding,
    setDataNull2,
    exportExcel4,
} from "../../store/order/slice";
import Intersection from "../Plan/intersection";
import { site } from "../../api/url_helper";
import { Paginator } from "primereact/paginator";
import LoadingSpinner from "../Report/LoadingSpinner";
import ModalExcel from "./ModalExcel";
import { exportQuantityPDF } from "./exportQuantityPDF";
import { Image } from "primereact/image";
import { Menu } from "primereact/menu";
import { FilterMatchMode } from "primereact/api";
import { statusFilterTemplate } from "../../constants/status";
import { MultiSelect } from "primereact/multiselect";
import ModalFilterCategoryQuantity from "./ModalFilterCategoryQuantity";
import QR from "../Plan/QR";
import CustomMultiSelect from "../../constants/CustomMultiSelect";
import { getUnit } from "../../store/dashboardPlan/slice";

export default function Production() {
    const [dialogLocationVisible, setDialogLocationVisible] = useState(false);
    const [tabMenuActive, setTabmenuActive] = useState(0);
    const [dataTable, setDataTable] = useState([]);
    const toast = useRef(null);
    const dispatch = useDispatch();
    const [selectedProductId, setSelectedProductId] = useState(null);
    const [visible, setVisible] = useState(false);
    const [visible2, setVisible2] = useState(false);
    const [visible3, setVisible3] = useState(false);
    const [visible4, setVisible4] = useState(false);
    const [selectFactoryCode, setSelectFactoryCode] = useState(null);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [rows, setRows] = useState(10);
    const [first, setFirst] = useState(0);
    const [categoryValue, setCategoryValue] = useState("");
    const [factoryCodeValue, setFactoryCodeValue] = useState("");
    const [buildingValue, setBuildingValue] = useState([]);
    const [category, setCategory] = useState(null);
    const [factoryCode, setFactoryCode] = useState(null);
    const [building, setBuilding] = useState([]);
    const [buildingOptions, setBuildingOptions] = useState([]);
    const [debounceTimeouts, setDebounceTimeouts] = useState({});
    const [selectedProductPrice, setSelectedProductPrice] = useState(null);
    const [dialogImportVisible, setDialogImportVisible] = useState(false);
    const [rowClick, setRowClick] = useState(true);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const downloadLink = useRef(null);
    const menuRight = useRef(null);
    const [qrDetail, setQrDetail] = useState(null);
    const [visible5, setVisible5] = useState(false);
    const [selectedStatuses, setSelectedStatuses] = useState([]);
    const [selectedUnit, setSelectedUnit] = useState([]);
    const [optionUnit, setOptionUnit] = useState([]);
    const [nameUnit, setNameUnit] = useState([]);

    const handleStatusChange = (newStatuses) => {
        setSelectedStatuses(newStatuses);
    };
    const defaultImageUrl =
        "https://static.thenounproject.com/png/1269202-200.png";

    const listPermissions =
        JSON.parse(localStorage.getItem("userPermissions")) || {};

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        "Building.name": { value: null, matchMode: FilterMatchMode.CONTAINS },
        categoryName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        quantity: { value: null, matchMode: FilterMatchMode.CONTAINS },
        unit: { value: null, matchMode: FilterMatchMode.CONTAINS },
        productCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
        factoryCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
        status: { value: [], matchMode: FilterMatchMode.IN },
        price: { value: null, matchMode: FilterMatchMode.CONTAINS },
        priceNote: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const {
        orderData,
        dataBuilding,
        addPriceData,
        exportExcelData4,
        exportExcelError4,
        loadingExportExcel,
        importData4,
        unitData,
    } = useSelector(
        (state) => ({
            orderData: state.Production.orderData,
            dataBuilding: state.Production.dataBuilding,
            addPriceData: state.Production.addPriceData,
            exportExcelData4: state.Production.quantityExportExcel.data,
            exportExcelError4: state.Production.quantityExportExcel.error,
            loadingExportExcel: state.Production.quantityExportExcel.loading,
            importData4: state.Production.importData4,
            unitData: state.DashboardPlan.unitData,
        }),
        shallowEqual
    );
    useEffect(() => {
        if (unitData) {
            setOptionUnit(
                unitData.data.map((item) => ({
                    label: item.name,
                    value: item.id,
                }))
            );
        }
    }, [unitData]);

    useEffect(() => {
        dispatch(getUnit());
    }, []);
    const handleUnitChange = (e) => {
        const selectedIds = e.target.value;
        setSelectedUnit(selectedIds);
    };
    useEffect(() => {
        setNameUnit(
            optionUnit
                .filter((unit) => selectedUnit.includes(unit.value))
                .map((unit) => unit.label)
        );
    }, [selectedUnit, optionUnit]);

    useEffect(() => {
        let status = "Tất cả";
        if (orderData) {
            status = orderData.data.listStatus[tabMenuActive]?.label;
        }
        if (!visible3 && !dialogLocationVisible) {
            dispatch(
                getOrder({
                    page: (first + rows) / rows,
                    limit: rows,
                    status,
                    category: category,
                    factoryCode: factoryCode,
                    // building: building,
                    current: "quantity",
                    filterStatus: JSON.stringify(selectedStatuses),
                    filterUnit: JSON.stringify(nameUnit),
                    filterBuildings: JSON.stringify(buildingValue),
                })
            );
        }
    }, [
        dispatch,
        first,
        tabMenuActive,
        rows,
        category,
        factoryCode,
        // building,
        visible3,
        dialogLocationVisible,
        selectedStatuses,
        buildingValue,
    ]);

    useEffect(() => {
        let status = "Tất cả";
        if (orderData) {
            status = orderData.data.listStatus[tabMenuActive]?.label;
        }
        if (addPriceData || importData4) {
            dispatch(
                getOrder({
                    page: (first + rows) / rows,
                    limit: rows,
                    status,
                    category: category,
                    factoryCode: factoryCode,
                    building: building,
                    current: "quantity",
                })
            );
            dispatch(setDataNull2());
        }
    }, [
        dispatch,
        first,
        rows,
        addPriceData,
        importData4,
        tabMenuActive,
        category,
        factoryCode,
        building,
    ]);

    useEffect(() => {
        const updateDataTable = () => {
            if (orderData) {
                setDataTable(orderData.data.products.items);
            }
        };
        updateDataTable();
    }, [orderData]);

    useEffect(() => {
        dispatch(
            getBuilding({
                page: 1,
                limit: 1000,
            })
        );
    }, [dispatch]);

    useEffect(() => {
        if (dataBuilding?.data?.items) {
            const buildings = dataBuilding.data.items.map((item) => ({
                label: item.name,
                value: item.id,
            }));
            setBuildingOptions(removeDuplicates(buildings, "value"));
        }
    }, [dataBuilding]);

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
    };

    const handleRowSelect = (rowData) => {
        setSelectedProductId(rowData.id);
        setSelectedProductPrice(rowData.price);
        setVisible(true);
    };

    const handleRowSelect2 = (rowData) => {
        setSelectedProductId(rowData.id);
        setSelectedProductPrice(rowData.price);
        setVisible2(true);
    };

    const handleRowSelect4 = (rowData) => {
        setSelectedProductId(rowData.id);
        setSelectedProductPrice(rowData.price);
        setVisible4(true);
    };

    const handleTeamProgress = (rowData) => {
        setSelectFactoryCode(rowData.factoryCode);
        setDialogLocationVisible(true);
        setSelectedRow(rowData);
    };

    const priceTemplate = (rowData) => {
        return formatNumber(rowData.price);
    };

    const quantityTemplate = (rowData) => {
        return formatNumber(rowData.quantity);
    };

    const handleCategoryChange = (e) => {
        setCategoryValue(e.target.value);
        debounce(() => setCategory(e.target.value), "category");
    };

    const handleBuildingChange = (e) => {
        setBuilding(e.target.value);
        setBuildingValue(e.target.value);
    };

    const handleFactoryCodeChange = (e) => {
        setFactoryCodeValue(e.target.value);
        debounce(() => setFactoryCode(e.target.value), "factoryCode");
    };

    const debounce = (callback, field) => {
        if (debounceTimeouts[field]) {
            clearTimeout(debounceTimeouts[field]);
        }

        const timeout = setTimeout(() => {
            callback();
            setDebounceTimeouts((prev) => ({ ...prev, [field]: null }));
        }, 400);

        setDebounceTimeouts((prev) => ({ ...prev, [field]: timeout }));
    };

    const removeDuplicates = (array, key) => {
        return array.filter(
            (item, index, self) =>
                index === self.findIndex((t) => t[key] === item[key])
        );
    };

    const logDataTable = () => {
        const ID = dataBuilding?.data?.items?.find(
            (item) => item.buildingCode === building
        )?.id;
        if (ID) {
            dispatch(
                exportExcel4({
                    type: "quantity",
                    building_id: ID,
                })
            );
        } else {
            dispatch(
                exportExcel4({
                    type: "quantity",
                })
            );
        }
    };

    useEffect(() => {
        if (exportExcelData4) {
            const url = `${site}/${exportExcelData4.file}`;
            toast.current.show({
                severity: "success",
                summary: "Thành công",
                detail: "Xuất excel thành công.",
                life: 3000,
            });
            dispatch(setDataNull2());

            // Tự động tải file
            downloadLink.current.href = url;
            // downloadLink.current.download = 'exported_data.xlsx';
            downloadLink.current.click();
        }
    }, [dispatch, exportExcelData4]);

    useEffect(() => {
        if (exportExcelError4) {
            toast.current.show({
                severity: "error",
                summary: "Thất bại",
                detail: exportExcelError4.response.data.message,
                life: 3000,
            });
            dispatch(setDataNull2());
        }
    }, [dispatch, exportExcelError4]);

    const items = [];

    if (listPermissions["Sản lượng"]?.includes("Cập nhật thông tin")) {
        items.push({
            label: "Nhập từ excel",
            icon: "pi pi-file-excel",
            command: () => setDialogImportVisible(true),
        });
    }

    items.push({
        label: "Xuất ra excel",
        icon: "pi pi-file-excel",
        command: logDataTable,
    });

    items.push({
        label: "In báo cáo",
        icon: "pi pi-print",
        command: () => exportQuantityPDF(dataTable),
    });

    const handleClick = (event) => {
        menuRight.current.show(event);
    };

    const imageTemplate = (rowData) => {
        return (
            <Image
                src={rowData?.categoryImage || defaultImageUrl}
                alt="Category Image"
                imageStyle={{
                    borderRadius: "20%",
                    width: "40px",
                    height: "40px",
                }}
                preview
                onError={(e) => (e.target.src = defaultImageUrl)}
            />
        );
    };

    const width = window.innerWidth;

    const handleDetailScanned = (detail) => {
        setQrDetail(detail);
    };

    const handleSelectionChange = (e) => {
        const selected = e.value || [];
        const currentPageItems = dataTable || [];

        // Loại bỏ các phần tử thuộc trang hiện tại khỏi selectedProducts
        const filteredProducts =
            selectedProducts?.filter(
                (item) => !currentPageItems.some((row) => row.id === item.id)
            ) || [];

        // Gộp các phần tử không bị xóa với phần tử vừa chọn
        const updatedSelection = [...filteredProducts, ...selected];

        // Loại bỏ phần tử trùng lặp
        const uniqueSelection = Array.from(
            new Map(updatedSelection.map((item) => [item.id, item])).values()
        );

        setSelectedProducts(uniqueSelection);
    };
    console.log("buildingValue", buildingValue);

    return (
        <div className="h-full flex flex-column justify-content-between">
            <Toast ref={toast} />
            <a ref={downloadLink} style={{ display: "none" }} />
            <div className="flex mb-3 xl:flex-row flex-column w-full gap-3">
                <div className="flex flex-column md:flex-row gap-3 w-full">
                    <IconField className="search-item">
                        <MultiSelect
                            placeholder="Lọc công trình"
                            options={buildingOptions}
                            onChange={handleBuildingChange}
                            emptyFilterMessage="Không có dữ liệu"
                            emptyMessage="Không có dữ liệu"
                            value={buildingValue}
                            filter
                            showClear
                            className="border-0 w-full"
                        />
                    </IconField>
                    <IconField className="search-item">
                        <InputText
                            value={categoryValue}
                            onChange={handleCategoryChange}
                            placeholder="Lọc hạng mục"
                            className="border-0"
                        />
                    </IconField>
                    <IconField className="search-item">
                        <InputText
                            value={factoryCodeValue}
                            onChange={handleFactoryCodeChange}
                            placeholder="Lọc mã nhà máy"
                            className="border-0"
                        />
                    </IconField>
                    <IconField className="search-item">
                        <MultiSelect
                            options={optionUnit}
                            value={selectedUnit}
                            onChange={handleUnitChange}
                            placeholder="Lọc theo ĐVT"
                            className="w-full border-0"
                            showClear
                            filter
                            emptyFilterMessage="Không có dữ liệu"
                        />
                    </IconField>
                    <IconField className="search-item">
                        <CustomMultiSelect
                            value={selectedStatuses}
                            onChange={handleStatusChange}
                            className="w-full border-0"
                        />
                    </IconField>
                </div>
                <div className="flex justify-content-end">
                    <Button
                        label={loadingExportExcel ? "Đang xuất..." : "Thao tác"}
                        popup="true"
                        icon={
                            loadingExportExcel
                                ? "pi pi-spin pi-spinner"
                                : "pi pi-sort-down-fill"
                        }
                        disabled={loadingExportExcel}
                        onClick={handleClick}
                        aria-controls="popup_menu_right"
                        aria-haspopup="true"
                        iconPos="right"
                        style={{ width: "120px" }}
                    />
                    <Menu
                        model={items}
                        popup
                        ref={menuRight}
                        id="popup_menu_right"
                        popupAlignment="right"
                    />
                </div>
            </div>

            <div className="bg-white border-round-md shadow-1 w-full px-3 h-full flex flex-column justify-content-between">
                {orderData && orderData.data ? (
                    <div className="card h-full flex flex-column justify-content-between">
                        <div className="card h-full">
                            {orderData.data.listStatus && (
                                <div className="flex md:justify-content-between md:align-items-center flex-column md:flex-row gap-4">
                                    <TabMenu
                                        model={orderData.data.listStatus.map(
                                            (item, index) => ({
                                                ...item,
                                                template: () =>
                                                    tabMenuTemplate(
                                                        item,
                                                        index,
                                                        tabMenuActive,
                                                        setTabmenuActive
                                                    ),
                                            })
                                        )}
                                        activeIndex={tabMenuActive}
                                        onTabChange={(e) =>
                                            setTabmenuActive(e.index)
                                        }
                                        pt={{ menuitem: "p-0" }}
                                        style={{ maxWidth: "70%" }}
                                    />
                                    <div className="flex gap-4">
                                        <Button
                                            label="Lọc theo hạng mục"
                                            onClick={() => setOpenModal(true)}
                                        ></Button>
                                    </div>
                                </div>
                            )}
                            <DataTable
                                value={dataTable}
                                emptyMessage="Không có dữ liệu"
                                size="small"
                                scrollable
                                className="h-full flex flex-column justify-content-between"
                                scrollHeight="74vh"
                                filters={filters}
                                pt={{ wrapper: { style: { height: "90%" } } }}
                                onFilter={(e) => setFilters(e.filters)}
                                selectionMode="checkbox"
                                selection={selectedProducts || []}
                                onSelectionChange={handleSelectionChange}
                                dataKey="id"
                                rowClassName="custom-row-class"
                            >
                                <Column selectionMode="multiple"></Column>
                                <Column
                                    header="STT"
                                    body={(rowData, options) => (
                                        <div className="text-center">
                                            {first + options.rowIndex + 1}
                                        </div>
                                    )}
                                    frozen
                                    style={{
                                        width: "3%",
                                        minWidth: "60px",
                                        height: "40px",
                                    }}
                                    alignHeader="center"
                                    align="center"
                                />
                                <Column
                                    header="Tên công trình"
                                    field="Building.name"
                                    style={{ minWidth: "200px" }}
                                    filter
                                    frozen
                                    filterPlaceholder="Tìm kiếm mã công trình"
                                />
                                <Column
                                    header="Hạng mục"
                                    field="categoryName"
                                    style={{ minWidth: "150px" }}
                                    filter
                                    frozen={width > 768}
                                    filterPlaceholder="Tìm kiếm theo hạng mục"
                                />
                                <Column
                                    header="Hình ảnh"
                                    body={imageTemplate}
                                    style={{ minWidth: "100px" }}
                                    align="center"
                                    frozen={width > 768}
                                />
                                <Column
                                    header="Mã SP"
                                    field="productCode"
                                    style={{ minWidth: "100px" }}
                                    frozen
                                    filter
                                    filterPlaceholder="Tìm kiếm mã sản phẩm"
                                />
                                <Column
                                    header="Mã nhà máy"
                                    field="factoryCode"
                                    filterPlaceholder="Tìm kiếm mã nhà máy"
                                    style={{ minWidth: "120px" }}
                                    frozen
                                    filter
                                />
                                <Column
                                    header="Số lượng"
                                    field="quantity"
                                    body={quantityTemplate}
                                    style={{ minWidth: "90px" }}
                                />
                                <Column
                                    header="ĐVT"
                                    field="unit"
                                    style={{ minWidth: "50px" }}
                                    filter
                                    filterPlaceholder="Tìm kiếm đơn vị tính"
                                />

                                <Column
                                    header="Trạng thái"
                                    field="status"
                                    body={statusTemplate}
                                    style={{ minWidth: "150px" }}
                                    filter
                                    filterElement={statusFilterTemplate}
                                    pt={{
                                        filterButtonbar: {
                                            className: "hidden",
                                        },
                                    }}
                                    alignHeader="center"
                                    align="center"
                                />
                                <Column
                                    header="Đơn giá"
                                    field="price"
                                    style={{ minWidth: "100px" }}
                                    body={priceTemplate}
                                    filter
                                    filterPlaceholder="Tìm kiếm đơn giá"
                                />
                                <Column
                                    header="Sản lượng kế hoạch"
                                    body={(rowData) => (
                                        <div className="text-center">
                                            <i
                                                className="pi pi-calendar cursor-pointer"
                                                onClick={() =>
                                                    handleRowSelect(rowData)
                                                }
                                            ></i>
                                        </div>
                                    )}
                                    field="keHoach"
                                    style={{ minWidth: "150px" }}
                                    alignHeader="center"
                                    align="center"
                                />
                                <Column
                                    header="Sản lượng hoàn thành"
                                    body={(rowData) => (
                                        <div className="text-center py-2">
                                            <i
                                                className="pi pi-calendar cursor-pointer"
                                                onClick={() =>
                                                    handleRowSelect2(rowData)
                                                }
                                            ></i>
                                        </div>
                                    )}
                                    field="keHoach"
                                    style={{ minWidth: "160px" }}
                                    alignHeader="center"
                                    align="center"
                                />
                                <Column
                                    header="Sản lượng thực tế"
                                    body={(rowData) => (
                                        <div className="text-center py-2">
                                            <i
                                                className="pi pi-calendar cursor-pointer"
                                                onClick={() =>
                                                    handleRowSelect4(rowData)
                                                }
                                            ></i>
                                        </div>
                                    )}
                                    field="keHoach"
                                    style={{ minWidth: "160px" }}
                                    alignHeader="center"
                                    align="center"
                                />
                                <Column
                                    header="In QR"
                                    body={(rowData) => (
                                        <div className="text-center">
                                            <Button
                                                onClick={() => {
                                                    setQrDetail(rowData);
                                                    setVisible5(true);
                                                }}
                                                icon="pi pi-qrcode"
                                                rounded
                                                text
                                                severity="secondary"
                                                aria-label="QR"
                                                style={{ color: "black" }}
                                            />
                                        </div>
                                    )}
                                    style={{ width: "8%", minWidth: "100px" }}
                                    alignHeader={"center"}
                                    align={"center"}
                                ></Column>
                                <Column
                                    header="Ghi chú"
                                    field="priceNote"
                                    style={{ minWidth: "150px" }}
                                />
                                <Column
                                    header="Định vị"
                                    body={(rowData) => (
                                        <div className="text-center">
                                            <i
                                                onClick={() =>
                                                    handleTeamProgress(rowData)
                                                }
                                                className="pi pi-map-marker cursor-pointer"
                                                style={{ color: "black" }}
                                            ></i>
                                            <p>
                                                {rowData.status !==
                                                    "Hoàn thành đúng tiến độ" &&
                                                rowData.status !==
                                                    "Hoàn thành trễ tiến độ"
                                                    ? rowData.teamWorking
                                                    : "Đã giao"}
                                            </p>
                                        </div>
                                    )}
                                    style={{ minWidth: "150px" }}
                                    alignHeader="center"
                                    align="center"
                                />
                                {listPermissions["Sản lượng"]?.includes(
                                    "Cập nhật thông tin"
                                ) && (
                                    <Column
                                        header="Thêm đơn giá"
                                        body={(rowData) => (
                                            <div className="text-center">
                                                <i
                                                    onClick={() => {
                                                        setVisible3(true);
                                                        setSelectedRowData(
                                                            rowData
                                                        );
                                                    }}
                                                    className="pi pi-file-import cursor-pointer"
                                                    style={{ color: "black" }}
                                                ></i>
                                            </div>
                                        )}
                                        style={{ minWidth: "100px" }}
                                        alignHeader="center"
                                        align="center"
                                    ></Column>
                                )}
                            </DataTable>
                        </div>

                        <div className="card ">
                            <Paginator
                                first={first}
                                rows={rows}
                                totalRecords={
                                    orderData?.data?.products?.totalItems
                                }
                                rowsPerPageOptions={[10, 20, 30, 100, 200, 500]}
                                onPageChange={onPageChange}
                                className="mt-2"
                            />
                        </div>
                        <ModalAddPlan
                            price={selectedProductPrice}
                            isShow={visible}
                            productId={selectedProductId}
                            hiden={() => setVisible(false)}
                        />
                        <ModalExcel
                            onHide={() => setDialogImportVisible(false)}
                            visible={dialogImportVisible}
                            id={
                                dataBuilding?.data?.items?.find(
                                    (item) => item.buildingCode === building
                                )?.id
                            }
                        />
                        <ModalAddComplete
                            price={selectedProductPrice}
                            productId={selectedProductId}
                            isShow={visible2}
                            hiden={() => setVisible2(false)}
                        />
                        <ActualComplete
                            isShow={visible4}
                            price={selectedProductPrice}
                            productId={selectedProductId}
                            hiden={() => setVisible4(false)}
                            toast={toast}
                        />
                        <ModalAddPrice
                            isShow={visible3}
                            rowData={selectedRowData}
                            hiden={() => setVisible3(false)}
                            toast={toast}
                        />
                        {dialogLocationVisible && (
                            <Intersection
                                visible={dialogLocationVisible}
                                onHide={() => setDialogLocationVisible(false)}
                                factoryCode={selectFactoryCode}
                                rowData={selectedRow}
                            />
                        )}
                        <ModalFilterCategoryQuantity
                            visible={openModal}
                            onHide={() => setOpenModal(false)}
                            data={selectedProducts}
                        />
                        <QR
                            isShow={visible5}
                            hiden={() => setVisible5(false)}
                            detail={qrDetail}
                            onDetailScanned={handleDetailScanned}
                        />
                    </div>
                ) : (
                    <LoadingSpinner />
                )}
            </div>
        </div>
    );
}
