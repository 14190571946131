import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    dashboardQuantityData: null,
    error: null,
    loading: false,
    excelData: null,
    errorExcelData: null,
    allData: [],
    currentPage: 1,
};

const dashboardQuantitySlice = createSlice({
    name: "dashboardQuantity",
    initialState,
    reducers: {
        getDashboardQuantity: (state) => {
            state.loading = true;
        },
        getDashboardQuantitySuccess: (state, action) => {
            state.loading = false;
            state.dashboardQuantityData = action.payload;
            state.error = null;
        },
        getDashboardQuantityFail: (state, action) => {
            state.loading = false;
            state.dashboardQuantityData = null;
            state.error = action.payload;
        },
        downloadExcelQuantity: (state, action) => {
            state.loading = true;
        },
        downloadExcelQauntitySuccess: (state, action) => {
            state.loading = false;
            state.excelData = action.payload;
            state.errorExcelData = null;
        },
        downloadExcelQuantityFail: (state, action) => {
            state.loading = false;
            state.excelData = null;
            state.errorExcelData = action.payload;
        },
        setExcelDataQuantityNull: (state) => {
            state.excelData = null;
            state.errorExcelData = null;
        },
        getAllDashboardQuantity: (state) => {
            state.loading = true;
        },
        getAllDashboardQuantitySuccess: (state, action) => {
            state.loading = false;
            state.allData = action.payload;

            if (state.currentPage < action.payload.data.plans.totalPages) {
                state.currentPage += 1;
            }
            state.error = null;
        },
        getAllDashboardQuantityFail: (state, action) => {
            state.loading = false;
            state.allData = null;
            state.error = action.payload;
        },
        resetPaginationQuantity: (state) => {
            state.allData = [];
            state.currentPage = 1;
            state.error = null;
        },
    },
});

export const {
    getDashboardQuantity,
    getDashboardQuantityFail,
    getDashboardQuantitySuccess,
    downloadExcelQauntitySuccess,
    downloadExcelQuantity,
    downloadExcelQuantityFail,
    setExcelDataQuantityNull,
    getAllDashboardQuantityFail,
    getAllDashboardQuantity,
    getAllDashboardQuantitySuccess,
    resetPaginationQuantity,
} = dashboardQuantitySlice.actions;

export default dashboardQuantitySlice.reducer;
