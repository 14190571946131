import { QRCodeCanvas } from "qrcode.react";
import { useEffect, useRef } from "react";

function QRCodeWithBase64({ value, size = 200, onRenderAsImage }) {
    const qrCanvasRef = useRef();

    useEffect(() => {
        if (qrCanvasRef.current) {
            const canvas = qrCanvasRef.current.querySelector("canvas");
            if (canvas) {
                const base64Image = canvas.toDataURL("image/png");
                if (onRenderAsImage) {
                    onRenderAsImage(base64Image);
                }
            }
        }
    }, [value]);

    return (
        <div ref={qrCanvasRef}>
            <QRCodeCanvas value={value} size={size} />
        </div>
    );
}

export default QRCodeWithBase64;
