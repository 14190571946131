import React, { useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import "../../assets/scss/theme-base/ModelAddConstruction.css";
import { Paginator } from "primereact/paginator";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import Intersection from "../Plan/intersection";
import FormatDate from "../Report/FormatDate";
import { DataTable } from "primereact/datatable";
import { Image } from "primereact/image";
import { statusFilterTemplate } from "../../constants/status";
import { QuantityTemplate, statusTemplate } from "../../components/utils";
import { isMobile, isTablet, isDesktop } from "react-device-detect";
import ModalShowPlan, { formatDate } from "./ModalShowPlan";
import ActionMenu2 from "../../components/ActionMenu2";
import QR from "./QR";
import ModalAssignment from "./ModalAssignment";
import ModalShowPlanLead from "./ModalShowPlanLead";
import ModalShowCategoryMaterial from "./ModalShowCategoryMaterial";
import ModalHistory from "./ModalHistory";

const defaultImageUrl = "https://static.thenounproject.com/png/1269202-200.png";

export const imageTemplate = (rowData) => {
    return (
        <Image
            src={`${rowData?.categoryImage}` || defaultImageUrl}
            alt="Category Image"
            imageStyle={{ borderRadius: "20%", width: "40px", height: "40px" }}
            preview
            onError={(e) => (e.target.src = defaultImageUrl)}
        />
    );
};
export const teamImageTemplate = (rowData) => {
    return rowData.teamImage ? (
        <Image
            src={`${rowData?.teamImage}` || defaultImageUrl}
            alt="Category Image"
            imageStyle={{ borderRadius: "20%", width: "40px", height: "40px" }}
            preview
            onError={(e) => (e.target.src = defaultImageUrl)}
        />
    ) : (
        <></>
    );
};

const ModalFilterCategoryPlan = ({ visible, onHide, data }) => {
    const [dialogLocationVisible, setDialogLocationVisible] = useState(false);
    const [selectFactoryCode, setSelectFactoryCode] = useState(null);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [qrDetail, setQrDetail] = useState(null);
    const [selectedProductId, setSelectedProductId] = useState(null);
    const [visibleLead, setVisibleLead] = useState(false);
    const [visibleCategoryMaterial, setVisibleCategoryMaterial] =
        useState(false);
    const [visible1, setVisible1] = useState(false);
    const [visible2, setVisible2] = useState(false);
    const [visible3, setVisible3] = useState(false);
    const [noChange, setNoChange] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedIntendFinishDate, setSelectedIntendFinishDate] =
        useState(null);
    const [selectedFactoryCode, setSelectedFactoryCode] = useState(null);
    const [selectedTeamWorking, setSelectedTeamWorking] = useState(null);
    const [assignment, setAssignment] = useState(false);

    const listPermissions =
        JSON.parse(localStorage.getItem("userPermissions")) || {};

    const handleTeamProgress = (rowData) => {
        setSelectFactoryCode(rowData.factoryCode);
        setDialogLocationVisible(true);
    };
    const onPageChange = (event) => {
        const newFirst = event.first;
        const newRows = event.rows;

        setFirst(newFirst);
        setRows(newRows);
    };

    const paginatedData = data?.slice(first, first + rows);

    const handleRowSelectLead = (rowData) => {
        setSelectedProductId(rowData.id);
        setVisibleLead(true);
    };
    const handleRowSelectCategoryMaterial = (rowData) => {
        setSelectedProductId(rowData.id);
        setVisibleCategoryMaterial(true);
    };
    const handleRowSelect = (rowData) => {
        setSelectedProductId(rowData.id);
        setVisible1(true);
        setNoChange(rowData.notEditPlan);
    };

    const handleAssignment = (rowData) => {
        setSelectedProductId(rowData.id);
        setSelectedCategory(rowData.categoryName);
        setSelectedIntendFinishDate(rowData.intendFinishDate);
        setSelectedFactoryCode(rowData.factoryCode);
        setSelectedTeamWorking(rowData.teamWorking);
        setAssignment(true);
    };
    const handleShowHistory = (rowData) => {
        setSelectedProductId(rowData.id);
        setSelectedFactoryCode(rowData.factoryCode);
        setVisible2(true);
    };

    const handleDetailScanned = (detail) => {
        setQrDetail(detail);
    };

    return (
        <Dialog
            visible={visible}
            onHide={() => {
                setFirst(0);
                onHide();
            }}
            className="responsive-dialog"
            header="Lọc theo hạng mục"
            modal
            style={{ width: "80vw", height: "80vh" }}
        >
            <div className="p-3 border-round-md">
                <div>
                    <DataTable
                        rowClassName="custom-row-class-factory-inprogress"
                        size="small"
                        value={paginatedData}
                        emptyMessage="Không có dữ liệu"
                        scrollable
                        scrollHeight="550px"
                    >
                        <Column
                            header="STT"
                            body={(rowData, options) => (
                                <div className="text-center">
                                    {first + options.rowIndex + 1}
                                </div>
                            )}
                            style={{ width: "3%", minWidth: "60px" }}
                            alignHeader={"center"}
                            align={"center"}
                            frozen={isDesktop}
                        ></Column>
                        <Column
                            header="Tên công trình"
                            field="Building.name"
                            style={{ width: "5%", minWidth: "200px" }}
                            filter
                            filterPlaceholder="Tìm kiếm tên CT..."
                            frozen={isDesktop}
                        ></Column>
                        <Column
                            header="Mã ĐH"
                            field="orderCode"
                            style={{ width: "5%", minWidth: "100px" }}
                            filter
                            filterPlaceholder="Tìm kiếm mã ĐH..."
                            frozen={isDesktop}
                        ></Column>
                        <Column
                            header="Mã nhà máy"
                            field="factoryCode"
                            style={{ width: "8%", minWidth: "150px" }}
                            filter
                            frozen
                            filterPlaceholder="Tìm kiếm mã nhà máy..."
                        ></Column>
                        <Column
                            header="Hạng mục"
                            field="categoryName"
                            style={{ width: "8%", minWidth: "200px" }}
                            filter
                            filterPlaceholder="Tìm kiếm mã HM..."
                            frozen={isDesktop}
                        ></Column>
                        <Column
                            header="Hình ảnh"
                            body={imageTemplate}
                            style={{ width: "5%", minWidth: "70px" }}
                            align={"center"}
                            frozen
                        ></Column>
                        <Column
                            header="SL"
                            field="quantity"
                            style={{ width: "3%", minWidth: "60px" }}
                            body={QuantityTemplate}
                            alignHeader={"center"}
                            align={"center"}
                        ></Column>
                        <Column
                            header="ĐVT"
                            field="unit"
                            style={{ width: "3%", minWidth: "60px" }}
                            filter
                            filterPlaceholder="Tìm kiếm ĐVT..."
                        ></Column>
                        <Column
                            header="Mã SP"
                            field="productCode"
                            style={{ width: "5%", minWidth: "100px" }}
                            filter
                            filterPlaceholder="Tìm kiếm mã SP..."
                        ></Column>

                        <Column
                            header="Vật tư chính"
                            field="materialName"
                            style={{ width: "8%", minWidth: "300px" }}
                            filter
                            filterPlaceholder="Tìm kiếm vật tư chính..."
                        ></Column>
                        {listPermissions["Kế hoạch"]?.includes(
                            "Sửa thông tin"
                        ) && (
                            <Column
                                header="Leadtime vật tư chính"
                                body={(rowData) => (
                                    <div className="text-center">
                                        <i
                                            className="pi pi-calendar cursor-pointer"
                                            onClick={() =>
                                                handleRowSelectLead(rowData)
                                            }
                                        ></i>
                                        <p>
                                            {formatDate(rowData.firstMaterial)}
                                        </p>
                                    </div>
                                )}
                                field="keHoach"
                                style={{
                                    width: "5%",
                                    minWidth: "100px",
                                }}
                                alignHeader={"center"}
                                align={"center"}
                            ></Column>
                        )}
                        <Column
                            header="Hạng mục gia công"
                            field="categoryMaterial"
                            style={{ width: "8%", minWidth: "165px" }}
                            filter
                            filterPlaceholder="Tìm kiếm HMGC..."
                        ></Column>
                        {listPermissions["Kế hoạch"]?.includes(
                            "Sửa thông tin"
                        ) && (
                            <Column
                                header="Leadtime hạng mục gia công"
                                body={(rowData) => (
                                    <div className="text-center">
                                        <i
                                            className="pi pi-calendar cursor-pointer"
                                            onClick={() =>
                                                handleRowSelectCategoryMaterial(
                                                    rowData
                                                )
                                            }
                                        ></i>
                                        <p>
                                            {formatDate(rowData.firstMachining)}
                                        </p>
                                    </div>
                                )}
                                field="keHoach"
                                style={{
                                    width: "5%",
                                    minWidth: "100px",
                                }}
                                alignHeader={"center"}
                                align={"center"}
                            ></Column>
                        )}

                        <Column
                            header="Kế hoạch hoàn thành"
                            field="intendFinishDate"
                            body={(rowData) =>
                                formatDate(rowData.intendFinishDate)
                            }
                            style={{ width: "8%", minWidth: "175px" }}
                            alignHeader={"center"}
                            sortable
                            align={"center"}
                        ></Column>
                        <Column
                            header="Trạng thái"
                            field="status"
                            body={statusTemplate}
                            style={{ width: "12%", minWidth: "150px" }}
                            alignHeader={"center"}
                            filter
                            filterElement={statusFilterTemplate}
                            pt={{
                                filterButtonbar: {
                                    className: "hidden",
                                },
                            }}
                            align={"center"}
                        ></Column>
                        <Column
                            header="Tiến độ giao hàng"
                            field="totalActualDelivery"
                            body={QuantityTemplate}
                            style={{ width: "8%", minWidth: "160px" }}
                            filter
                            filterPlaceholder="Tìm kiếm tiến độ..."
                            alignHeader={"center"}
                            align={"center"}
                        ></Column>
                        {listPermissions["Kế hoạch"]?.includes(
                            "Sửa thông tin"
                        ) && (
                            <Column
                                header="Kế hoạch"
                                body={(rowData) => (
                                    <div className="text-center">
                                        <i
                                            className="pi pi-calendar cursor-pointer"
                                            onClick={() =>
                                                handleRowSelect(rowData)
                                            }
                                        ></i>
                                    </div>
                                )}
                                field="keHoach"
                                style={{
                                    width: "5%",
                                    minWidth: "100px",
                                    height: "60px",
                                }}
                                alignHeader={"center"}
                                align={"center"}
                            ></Column>
                        )}
                        {listPermissions["Kế hoạch"]?.includes(
                            "Sửa thông tin"
                        ) && (
                            <Column
                                header="Phân công sản xuất"
                                body={(rowData) => (
                                    <div className="text-center">
                                        <i
                                            className="pi pi-calendar cursor-pointer"
                                            onClick={() =>
                                                handleAssignment(rowData)
                                            }
                                        ></i>
                                    </div>
                                )}
                                field="assignment"
                                style={{
                                    width: "5%",
                                    minWidth: "100px",
                                }}
                                alignHeader={"center"}
                                align={"center"}
                            ></Column>
                        )}

                        <Column
                            header="Lịch sử giao hàng"
                            body={(rowData) => (
                                <div className="text-center py-2">
                                    <i
                                        className="pi pi-calendar cursor-pointer"
                                        onClick={() =>
                                            handleShowHistory(rowData)
                                        }
                                    ></i>
                                </div>
                            )}
                            field="keHoach"
                            style={{ width: "8%", minWidth: "80px" }}
                            alignHeader={"center"}
                            align={"center"}
                        ></Column>
                        <Column
                            header="Ghi chú"
                            field="productNote"
                            style={{ width: "6%", minWidth: "100px" }}
                        ></Column>
                        <Column
                            header="Định vị"
                            body={(rowData) => (
                                <div className="text-center">
                                    <i
                                        className="pi pi-map-marker cursor-pointer"
                                        onClick={() =>
                                            handleTeamProgress(rowData)
                                        }
                                    ></i>
                                    <p>
                                        {rowData.status !==
                                            "Hoàn thành đúng tiến độ" &&
                                        rowData.status !==
                                            "Hoàn thành trễ tiến độ"
                                            ? rowData.teamWorking
                                            : "Đã giao"}
                                    </p>
                                </div>
                            )}
                            style={{
                                width: "15%",
                                minWidth: "150px",
                            }}
                            alignHeader={"center"}
                            align={"center"}
                        ></Column>
                        <Column
                            header="In QR"
                            body={(rowData) => (
                                <div className="text-center">
                                    <Button
                                        onClick={() => {
                                            setQrDetail(rowData);
                                            setVisible3(true);
                                        }}
                                        icon="pi pi-qrcode"
                                        rounded
                                        text
                                        severity="secondary"
                                        aria-label="QR"
                                        style={{ color: "black" }}
                                    />
                                </div>
                            )}
                            style={{ width: "8%", minWidth: "100px" }}
                            alignHeader={"center"}
                            align={"center"}
                        ></Column>
                        {/* <Column
                            field="action"
                            body={actionBodyTemplate}
                        ></Column> */}
                    </DataTable>
                    <Paginator
                        first={first}
                        rows={rows}
                        totalRecords={data?.length}
                        rowsPerPageOptions={[10, 20, 30, 100, 200, 500]}
                        onPageChange={onPageChange}
                    />
                    <ModalShowPlan
                        isShow={visible1}
                        onHide={() => setVisible1(false)}
                        productId={selectedProductId}
                        noChange={noChange}
                    />
                    <ModalAssignment
                        isShow={assignment}
                        onHide={() => setAssignment(false)}
                        category={selectedCategory}
                        intendFinishDate={selectedIntendFinishDate}
                        factoryCode={selectedFactoryCode}
                        teamWorking={selectedTeamWorking}
                    />
                    <ModalShowPlanLead
                        isShow={visibleLead}
                        onHide={() => setVisibleLead(false)}
                        productId={selectedProductId}
                    />
                    <ModalShowCategoryMaterial
                        isShow={visibleCategoryMaterial}
                        onHide={() => setVisibleCategoryMaterial(false)}
                        productId={selectedProductId}
                    />
                    <ModalHistory
                        isShow={visible2}
                        onHide={() => setVisible2(false)}
                        productId={selectedProductId}
                        factoryCode={selectedFactoryCode}
                    />
                    <QR
                        isShow={visible3}
                        hiden={() => setVisible3(false)}
                        detail={qrDetail}
                        onDetailScanned={handleDetailScanned}
                    />
                    <Intersection
                        visible={dialogLocationVisible}
                        onHide={() => setDialogLocationVisible(false)}
                        factoryCode={selectFactoryCode}
                    />
                </div>
            </div>
        </Dialog>
    );
};

export default ModalFilterCategoryPlan;
