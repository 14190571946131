import Header from './Header';
import SidebarMenu from './SidebarMenu';

function PrivateLayout({ children }) {
  return (
    <div className='flex flex-row surface-200'>
      <div className='hidden xl:flex bg-white'>
        <SidebarMenu />
      </div>
      <div className='flex flex-column flex-auto h-screen w-full overflow-hidden'>
        <div>
          <Header />
        </div>
        <div className='p-2 md:p-4 overflow-auto h-full'>{children}</div>
      </div>
    </div>
  );
}

export default PrivateLayout;
