import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { formatDate2 } from "../../components/utils";

pdfMake.vfs = pdfFonts.pdfMake ? pdfFonts.pdfMake.vfs : pdfFonts.vfs;

const compressImage = async (imageFile, maxWidth, maxHeight, quality) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = URL.createObjectURL(imageFile);

        img.onload = () => {
            const canvas = document.createElement("canvas");
            let width = img.width;
            let height = img.height;

            if (width > height) {
                if (width > maxWidth) {
                    height *= maxWidth / width;
                    width = maxWidth;
                }
            } else {
                if (height > maxHeight) {
                    width *= maxHeight / height;
                    height = maxHeight;
                }
            }

            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, width, height);

            canvas.toBlob(
                (blob) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onloadend = () => resolve(reader.result);
                    reader.onerror = reject;
                },
                "image/jpeg",
                quality
            );
        };

        img.onerror = (error) => reject(error);
    });
};

const getBase64FromUrl = async (url) => {
    try {
        const response = await fetch(url);
        const blob = await response.blob();

        if (!blob.type.startsWith("image/")) {
            throw new Error("Invalid image format");
        }

        const compressedBase64 = await compressImage(blob, 300, 300, 0.7); // Thay đổi maxWidth, maxHeight, quality tùy ý
        return compressedBase64;
    } catch (error) {
        console.error("Error converting image to base64:", error);
        return null;
    }
};

export const generatePdf = async (dataDVi) => {
    if (!dataDVi || !dataDVi.data) {
        console.error("Data is null or undefined");
        return;
    }
    const reversedEvents = dataDVi.data.slice().reverse();
    const images = {};
    for (const event of reversedEvents) {
        for (const production of event.Productions) {
            if (production.images) {
                for (const image of production.images) {
                    try {
                        images[image] = await getBase64FromUrl(image);
                    } catch (error) {
                        console.error(`Failed to load image: ${image}`, error);
                    }
                }
            }
        }
    }

    const groupImagesIntoRows = (images, maxPerRow) => {
        const rows = [];
        for (let i = 0; i < images.length; i += maxPerRow) {
            rows.push(images.slice(i, i + maxPerRow));
        }
        return rows;
    };

    const docDefinition = {
        pageMargins: [40, 60, 40, 60],
        content: [
            { text: "Báo cáo Định vị", style: "header", margin: [0, 0, 0, 20] },
            ...reversedEvents.flatMap((event, index) => [
                {
                    text: `${index + 1}: ${event.name}`,
                    style: "subheader",
                    margin: [0, 10, 0, 5],
                },
                ...event.Productions.map((production, prodIndex) => ({
                    stack: [
                        {
                            text:
                                event === reversedEvents[reversedEvents.length - 1]
                                    ? `Thời gian bắt đầu:${formatDate2(
                                          production.timeStart
                                      )}`
                                    : `Thời gian: ${
                                          production.timeEnd
                                              ? `${formatDate2(
                                                    production.timeStart
                                                )} - ${formatDate2(
                                                    production.timeEnd
                                                )}`
                                              : `${formatDate2(
                                                    production.timeStart
                                                )} - đang làm`
                                      }`,
                            margin: [0, 5, 0, 5],
                        },
                        {
                            text: `Số lượng: ${
                                parseInt(event.totalEnd) !== 0
                                    ? event.totalEnd
                                    : event.totalStart
                            }`,
                            margin: [0, 5, 0, 5],
                        },
                        production.note
                            ? {
                                  text: `Ghi chú: ${production.note}`,
                                  margin: [0, 5, 0, 5],
                              }
                            : null,
                        production.images && production.images.length > 0
                            ? {
                                  stack: groupImagesIntoRows(
                                      production.images.map((image) =>
                                          images[image]
                                              ? {
                                                    image: images[image],
                                                    width: 120, // Đảm bảo tất cả ảnh có cùng chiều rộng
                                                    height: 120, // Đảm bảo tất cả ảnh có cùng chiều cao
                                                    margin: [0, 0, 10, 10],
                                                }
                                              : {
                                                    text: "",
                                                    margin: [0, 5, 0, 5],
                                                }
                                      ),
                                      4
                                  ).map((row) => ({
                                      columns: row,
                                      columnGap: 10,
                                  })),
                              }
                            : { text: "", margin: [0, 5, 0, 5] },
                    ].filter(Boolean),
                })),
            ]),
        ],
        styles: {
            header: {
                fontSize: 18,
                bold: true,
                alignment: "center",
            },
            subheader: {
                fontSize: 14,
                bold: true,
            },
        },
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);

    // Mở PDF trong tab mới
    pdfDocGenerator.open();

    // Tải xuống PDF
    pdfDocGenerator.download("BaoCaoDinhVi.pdf");
};
