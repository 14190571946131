import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
    dashboardTeamsData: null,
    dashboardTeamsDetailData: null,
    dashboardTeamsWorkingData: null,
    allTeamWorkingData: [],
    currentPageTeamWorking: 1,
    allDetailWorkingData: [],
    currentPageDetailWorking: 1,
    error: null,
    loading: false,
    excelDataTeam: null,
    errorExcelDataTeam: null,
};

const dashboardTeamsSlice = createSlice({
    name: "dashboardTeams",
    initialState,
    reducers: {
        getDashboardTeams: (state) => {
            state.loading = true;
        },
        getDashboardTeamsSuccess: (state, action) => {
            state.loading = false;
            state.dashboardTeamsData = action.payload;
            state.error = null;
        },
        getDashboardTeamsFail: (state, action) => {
            state.loading = false;
            state.dashboardTeamsData = null;
            state.error = action.payload;
        },
        getDashboardTeamsDetail: (state) => {
            state.loading = true;
        },
        getDashboardTeamsDetailSuccess: (state, action) => {
            state.loading = false;
            state.dashboardTeamsDetailData = action.payload;
            state.error = null;
        },
        getDashboardTeamsDetailFail: (state, action) => {
            state.loading = false;
            state.dashboardTeamsDetailData = null;
            state.error = action.payload;
        },
        getDashboardAllTeamsDetail: (state) => {
            state.loading = true;
        },
        getDashboardAllTeamsDetailSuccess: (state, action) => {
            state.loading = false;
            state.allDetailWorkingData = action.payload;

            if (
                state.currentPageDetailWorking < action.payload.data.totalPages
            ) {
                state.currentPageDetailWorking += 1;
            }
            state.error = null;
        },
        getDashboardAllTeamsDetailFail: (state, action) => {
            state.loading = false;
            state.allDetailWorkingData = null;
            state.error = action.payload;
        },
        getDashboardTeamsWorking: (state) => {
            state.loading = true;
        },
        getDashboardTeamsWorkingSuccess: (state, action) => {
            state.loading = false;
            state.dashboardTeamsWorkingData = action.payload;
            state.error = null;
        },
        getDashboardTeamsWorkingFail: (state, action) => {
            state.loading = false;
            state.dashboardTeamsWorkingData = null;
            state.error = action.payload;
        },
        getDashboardAllTeamsWorking: (state) => {
            state.loading = true;
        },
        getDashboardAllTeamsWorkingSuccess: (state, action) => {
            state.loading = false;
            state.allTeamWorkingData = action.payload;

            if (state.currentPageTeamWorking < action.payload.data.totalPages) {
                state.currentPageTeamWorking += 1;
            }
            state.error = null;
        },
        getDashboardAllTeamsWorkingFail: (state, action) => {
            state.loading = false;
            state.allTeamWorkingData = null;
            state.error = action.payload;
        },
        downloadExcelTeam: (state, action) => {
            state.loading = true;
        },
        downloadExcelTeamSuccess: (state, action) => {
            state.loading = false;
            state.excelDataTeam = action.payload;
            state.errorExcelDataFactory = null;
        },
        downloadExcelTeamFail: (state, action) => {
            state.loading = false;
            state.excelDataTeam = null;
            state.errorExcelDataTeam = action.payload;
        },
        setExcelDataTeamNull: (state) => {
            state.excelDataTeam = null;
            state.errorExcelDataTeam = null;
        },
        resetPaginationTeam: (state) => {
            state.currentPageTeamWorking = 1;
            state.allTeamWorkingData = [];
            state.currentPageDetailWorking = 1;
            state.allDetailWorkingData = [];
        },
    },
});

export const {
    getDashboardTeams,
    getDashboardTeamsSuccess,
    getDashboardTeamsFail,
    getDashboardTeamsDetail,
    getDashboardTeamsDetailFail,
    getDashboardTeamsDetailSuccess,
    getDashboardTeamsWorking,
    getDashboardTeamsWorkingFail,
    getDashboardTeamsWorkingSuccess,
    downloadExcelTeam,
    downloadExcelTeamFail,
    downloadExcelTeamSuccess,
    setExcelDataTeamNull,
    getDashboardAllTeamsWorking,
    getDashboardAllTeamsWorkingFail,
    getDashboardAllTeamsWorkingSuccess,
    getDashboardAllTeamsDetail,
    getDashboardAllTeamsDetailFail,
    getDashboardAllTeamsDetailSuccess,
    resetPaginationTeam,
} = dashboardTeamsSlice.actions;

export default dashboardTeamsSlice.reducer;
