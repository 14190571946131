import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import {
  clearImportExcelPlanData,
  downloadExcel2,
  importExcelData,
  setDataNull2,
} from '../../store/order/slice';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import UploadFile from '../../components/UploadFile';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { site } from '../../api/url_helper';
import '../../assets/scss/theme-base/index.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import { FILE_TYPES } from '../../constants/fileTypes';

const ModalImportExcel = ({ visible, onHide, id }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const toast = useRef(null);
  const [successCount, setSuccessCount] = useState(null);
  const [errorCount, setErrorCount] = useState(null);
  const [errorFileUrl, setErrorFileUrl] = useState(null);

  const [isResultDialogVisible, setIsResultDialogVisible] = useState(false);

  const { uploadResult, errorUpload, planSampleExcel, errorPlanSampleExcel } =
    useSelector(
      (state) => ({
        uploadResult: state.Production.importData,
        errorUpload: state.Production.errorImportData,
        planSampleExcel: state.Production.excelData2,
        errorPlanSampleExcel: state.Production.errorExcelData2,
      }),
      shallowEqual
    );

  const handleDownloadClick = () => {
    setLoading(true);
    dispatch(downloadExcel2({ type: 'plan' }));
  };

  const showToast = (severity, summary, detail) => {
    toast.current?.show({ severity, summary, detail, life: 3000 });
  };

  useEffect(() => {
    if (planSampleExcel) {
      setLoading(false);
      const { file } = planSampleExcel;
      if (file) {
        const fileUrl = `${site}/${file}`;
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', 'Sample data.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        dispatch(setDataNull2());
        showToast('success', 'Thành công', 'Tải thành công');
      }
    } else if (errorPlanSampleExcel) {
      setLoading(false);
      showToast(
        'error',
        'Thất bại',
        errorPlanSampleExcel.response.data.message
      );
      dispatch(setDataNull2());
    }
  }, [planSampleExcel, errorPlanSampleExcel, dispatch]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {},
    onSubmit: () => {
      setLoading(true);
      dispatch(
        importExcelData({
          files: Array.from(selectedFiles),
        })
      );
    },
  });

  useEffect(() => {
    if (uploadResult) {
      setLoading(false);
      setIsResultDialogVisible(true);
      setSuccessCount(uploadResult?.successOrder);
      setErrorCount(uploadResult?.errorOrder);
      const file = uploadResult?.file;
      if (file) {
        const fileUrl = `${site}/${file}`;
        setErrorFileUrl(fileUrl);
      }
      formik.resetForm();
      dispatch(clearImportExcelPlanData());
    } else if (errorUpload) {
      setLoading(false);
      const file = errorUpload?.file;
      if (file) {
        const fileUrl = `${site}/${file}`;
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', 'Error data.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        dispatch(clearImportExcelPlanData());
      }
      showToast('error', 'Thất bại', errorUpload?.response?.data?.message);
    }
  }, [uploadResult, errorUpload, dispatch, formik, onHide]);

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        visible={visible}
        onHide={onHide}
        className='responsive-dialog'
        header='Nhập từ Excel'
        style={{ width: '40%' }}>
        {loading ? (
          <div className='flex justify-content-center align-items-center'>
            <ProgressSpinner />
          </div>
        ) : (
          <>
            <p className='font-bold'>Các bước</p>
            <div className='bg-yellow-100 px-5 py-3 line-height-4 border-round-md my-4'>
              <span>
                1. Tải file mẫu và nhập dữ liệu (
                <span
                  className='text-blue-500 font-bold underline cursor-pointer'
                  onClick={handleDownloadClick}>
                  Tải file mẫu
                </span>
                )
              </span>
              <p>2. Nhập dữ liệu chính xác theo các trường.</p>
              <p className='text-red-500'>
                Lưu ý: Không thay đổi tên và thứ tự các cột.
              </p>
            </div>
            <UploadFile
              onUpload={() => {}}
              getFiles={setSelectedFiles}
              clearFiles={false}
              isMultiple={false}
              fileTypes={FILE_TYPES.excel}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '40px',
                gap: '1rem',
              }}>
              <Button
                label='Hủy'
                className='px-5 py-2 bg-white border-none text-400'
                severity='secondary'
                onClick={onHide}
              />
              <Button
                label='Nhập'
                className='px-5 py-2 bg-green-700'
                disabled={selectedFiles.length === 0}
                onClick={formik.handleSubmit}
                type='submit'
              />
            </div>
          </>
        )}
      </Dialog>
      <Dialog
        visible={isResultDialogVisible}
        onHide={() => setIsResultDialogVisible(false)}
        className='responsive-dialog'
        header='Kết quả nhập dữ liệu'
        style={{ width: '40%' }}>
        <div className='surface-100 border-round-md p-3 line-height-4'>
          <div className='font-bold text-green-700'>
            Thành công: {successCount}
          </div>
          <span className='text-red-500 font-bold'>
            Thất bại: {errorCount}
            <a
              href={errorFileUrl}
              className='text-blue-500 underline cursor-pointer ml-2'
              download='Error data.xlsx'>
              Tải file
            </a>
          </span>
        </div>
      </Dialog>
    </>
  );
};

export default ModalImportExcel;
