import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import ModelDelete from "../../components/ModalDelete";
import { DataTable } from "primereact/datatable";
import ActionMenu2 from "../../components/ActionMenu2";
import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { formatDate } from "../../components/utils";
import { Calendar } from "primereact/calendar";
import {
    getStep,
    setStep,
    updateStep,
    deleteStep,
    setDataNull2,
} from "../../store/order/slice";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { getDataAll } from "../../store/teams/slice";
import { Dropdown } from "primereact/dropdown";

function ModalAssignment({
    isShow,
    onHide,
    category,
    intendFinishDate,
    factoryCode,
    teamWorking,
}) {
    const toast = useRef(null);
    const dispatch = useDispatch();
    const [showInputs, setShowInputs] = useState(false);
    const [rowData, setRowData] = useState();
    const [isEditMode, setIsEditMode] = useState(false);
    const [modalDelete, setModalDelete] = useState(false);

    const {
        stepData,
        addStepData,
        errorStepPlan,
        updateStepData,
        errorUpdateStep,
        deleteStepData,
        errorDeleteStep,
        teamsData,
    } = useSelector(
        (state) => ({
            stepData: state.Production.stepData,
            addStepData: state.Production.addStepData,
            errorStepPlan: state.Production.errorStepPlan,
            updateStepData: state.Production.updateStepData,
            errorUpdateStep: state.Production.errorUpdateStep,
            deleteStepData: state.Production.deleteStepData,
            errorDeleteStep: state.Production.errorDeleteStep,
            teamsData: state.Teams.teamsData,
        }),
        shallowEqual
    );

    useEffect(() => {
        if (isShow && factoryCode) {
            dispatch(getStep({ page: 1, limit: 100, factoryCode }));
        }
    }, [dispatch, isShow, factoryCode]);

    const validationSchema = Yup.object({
        teamName: Yup.string().required("Giá trị này là bắt buộc"),
        quantity: Yup.string().required("Giá trị này là bắt buộc"),
        startDate: Yup.string().required("Giá trị này là bắt buộc"),
        endDate: Yup.string().required("Giá trị này là bắt buộc"),
    });

    useEffect(() => {
        if (isShow)
            dispatch(
                getDataAll({
                    page: 1,
                    limit: 100,
                })
            );
    }, [dispatch, isShow]);

    const teamOptions = teamsData?.data?.items?.map((team) => ({
        label: team.name,
        value: team.name,
    }));

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            teamName: "",
            quantity: "",
            startDate: "",
            endDate: "",
            factoryCode: factoryCode,
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            const formattedValues = {
                ...values,
                startDate: values.startDate
                    ? values.startDate.toISOString()
                    : "",
                endDate: values.endDate ? values.endDate.toISOString() : "",
            };
            if (isEditMode && rowData) {
                dispatch(updateStep({ id: rowData.id, data: formattedValues }));
            } else {
                dispatch(setStep(formattedValues));
            }
            setShowInputs(false);
            setIsEditMode(false);
            formik.resetForm();
        },
    });

    useEffect(() => {
        if (deleteStepData || updateStepData || addStepData) {
            dispatch(getStep({ page: 1, limit: 100, factoryCode }));
            dispatch(setDataNull2());
        }
    }, [deleteStepData, updateStepData, addStepData, dispatch]);

    const handleDelete = (rowData) => {
        setModalDelete(true);
        setRowData(rowData);
    };

    const acceptDelete = () => {
        if (rowData && rowData !== undefined) {
            const id = rowData.id;
            if (id !== undefined) {
                dispatch(deleteStep({ id }));
            }
            setModalDelete(false);
        }
    };

    useEffect(() => {
        if (addStepData) {
            toast.current?.show({
                severity: "success",
                summary: "Thành công",
                detail: "Thêm thành công",
                life: 3000,
            });
            dispatch(setDataNull2());
        }
    }, [dispatch, addStepData]);

    useEffect(() => {
        if (errorStepPlan) {
            toast.current?.show({
                severity: "error",
                summary: "Thất bại",
                detail: errorStepPlan?.response?.data?.message,
                life: 3000,
            });
        }
    }, [dispatch, errorStepPlan]);

    useEffect(() => {
        if (updateStepData) {
            toast.current?.show({
                severity: "success",
                summary: "Thành công",
                detail: "Sửa thành công",
                life: 3000,
            });
            dispatch(setDataNull2());
        }
    }, [dispatch, updateStepData]);

    useEffect(() => {
        if (errorUpdateStep) {
            toast.current?.show({
                severity: "error",
                summary: "Thất bại",
                detail: errorUpdateStep?.response?.data?.message,
                life: 3000,
            });
        }
    }, [dispatch, errorUpdateStep]);

    useEffect(() => {
        if (deleteStepData) {
            toast.current?.show({
                severity: "success",
                summary: "Thành công",
                detail: "Xóa thành công",
                life: 3000,
            });
            dispatch(setDataNull2());
        }
    }, [dispatch, deleteStepData]);

    useEffect(() => {
        if (errorDeleteStep) {
            toast.current?.show({
                severity: "error",
                summary: "Thất bại",
                detail: errorDeleteStep.response.data.message,
                life: 3000,
            });
            dispatch(setDataNull2());
        }
    }, [dispatch, errorDeleteStep]);

    const actionBodyTemplate = (rowData) => {
        return (
            <ActionMenu2
                onDelete={() => handleDelete(rowData)}
                onEdit={() => handleEdit(rowData)}
            />
        );
    };

    const handleEdit = (rowData) => {
        setRowData(rowData);
        setIsEditMode(true);
        formik.setValues({
            teamName: rowData.teamName,
            quantity: rowData.quantity,
            startDate: new Date(rowData.startDate),
            endDate: new Date(rowData.endDate),
            factoryCode: factoryCode,
            note: rowData.note,
        });
        setShowInputs(true);
    };

    return (
        <Dialog
            header="Phân công sản xuất"
            visible={isShow}
            style={{ width: "50vw" }}
            className="responsive-dialog"
            onHide={() => {
                formik.resetForm();
                onHide();
                setIsEditMode(false);
                setShowInputs(false);
                dispatch(setDataNull2());
            }}
            footer={
                <div>
                    <Button
                        label="Hủy"
                        severity="secondary"
                        className="p-button-text"
                        onClick={() => {
                            formik.resetForm();
                            onHide();
                            setIsEditMode(false);
                            setShowInputs(false);
                            dispatch(setDataNull2());
                        }}
                    />
                    <Button
                        label="Lưu"
                        type="button"
                        onClick={() => formik.handleSubmit()}
                    />
                </div>
            }
        >
            <Toast ref={toast} />
            <div className="flex justify-content-between pb-2">
                <div>
                    Hạng mục : <span className="font-bold">{category}</span>
                </div>
                <div>
                    Kế hoạch hoàn thành :
                    <span className="font-bold">
                        {formatDate(intendFinishDate)}
                    </span>
                </div>
            </div>
            <DataTable
                emptyMessage="Không có dữ liệu"
                value={stepData?.data?.items || []}
                tableStyle={{ minWidth: "20rem" }}
            >
                <Column
                    field="teamName"
                    header="Tổ đội sản xuất"
                    body={(rowData) => {
                        const style =
                            rowData.teamName === teamWorking
                                ? { color: "red" }
                                : {};
                        return <span style={style}>{rowData.teamName}</span>;
                    }}
                ></Column>
                <Column field="quantity" header="SL"></Column>
                <Column
                    field="startDate"
                    header="Từ ngày"
                    alignHeader={"center"}
                    body={(rowData) => formatDate(rowData.startDate)}
                    align={"center"}
                ></Column>
                <Column
                    field="endDate"
                    header="Đến ngày"
                    alignHeader={"center"}
                    body={(rowData) => formatDate(rowData.endDate)}
                    align={"center"}
                ></Column>
                <Column
                    field="note"
                    header="Ghi chú"
                    style={{ width: "20%" }}
                    alignHeader={"center"}
                    align={"center"}
                ></Column>
                <Column
                    field="action"
                    body={actionBodyTemplate}
                    style={{ height: "40px" }}
                />
            </DataTable>
            <Button
                label="Thêm mới"
                icon="pi pi-plus"
                onClick={() => setShowInputs(true)}
                className="mt-3"
            />
            {showInputs && (
                <div className="mt-3">
                    <div className="flex mb-3 justify-content-between">
                        <div
                            className="flex flex-column"
                            style={{ width: "48%" }}
                        >
                            <label htmlFor="teamName">Tổ đội sản xuất</label>
                            <Dropdown
                                id="teamName"
                                name="teamName"
                                className="mt-2 w-full"
                                value={formik.values.teamName}
                                options={teamOptions}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div
                            className="flex flex-column"
                            style={{ width: "48%" }}
                        >
                            <label htmlFor="quantity">SL</label>
                            <InputText
                                className="mt-2"
                                id="quantity"
                                name="quantity"
                                value={formik.values.quantity}
                                onChange={formik.handleChange}
                            />
                        </div>
                    </div>

                    <div className="flex justify-content-between gap-2">
                        <div
                            className="flex flex-column"
                            style={{ width: "48%" }}
                        >
                            <label htmlFor="startDate">Từ ngày</label>
                            <Calendar
                                id="startDate"
                                name="startDate"
                                className="mt-2"
                                dateFormat="dd/mm/yy"
                                value={formik.values.startDate}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div
                            className="flex flex-column"
                            style={{ width: "48%" }}
                        >
                            <label htmlFor="endDate">Đến ngày</label>
                            <Calendar
                                id="endDate"
                                name="endDate"
                                className="mt-2"
                                dateFormat="dd/mm/yy"
                                value={formik.values.endDate}
                                onChange={formik.handleChange}
                            />
                        </div>
                    </div>
                    <div
                        className="flex flex-column mt-3"
                        style={{ width: "48%" }}
                    >
                        <label htmlFor="endDate">Ghi chú</label>
                        <InputText
                            id="note"
                            name="note"
                            value={formik.values.note}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder="Ghi chú"
                            className="mt-2"
                        />
                    </div>
                </div>
            )}
            <ModelDelete
                visible={modalDelete}
                setVisible={setModalDelete}
                accept={acceptDelete}
            />
        </Dialog>
    );
}

export default ModalAssignment;
