import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    dashboardPlanData: null,
    unitData: null,
    historyData: null,
    error: null,
    loading: false,
    allData: [],
    currentPage: 1,
};

const dashboardPlanSlice = createSlice({
    name: "dashboardPlan",
    initialState,
    reducers: {
        getDashboardPlan: (state) => {
            state.loading = true;
        },
        getDashboardPlanSuccess: (state, action) => {
            state.loading = false;
            state.dashboardPlanData = action.payload;
            state.error = null;
        },
        getDashboardPlanFail: (state, action) => {
            state.loading = false;
            state.dashboardPlanData = null;
            state.error = action.payload;
        },
        getAllDashboardPlan: (state) => {
            state.loading = true;
        },
        getAllDashboardPlanSuccess: (state, action) => {
            state.loading = false;
            state.allData = action.payload;

            if (state.currentPage < action.payload.data.result.totalPages) {
                state.currentPage += 1;
            }
            state.error = null;
        },
        getAllDashboarPlanFail: (state, action) => {
            state.loading = false;
            state.allData = null;
            state.error = action.payload;
        },
        getUnit: (state) => {
            state.loading = true;
        },
        getUnitSuccess: (state, action) => {
            state.loading = false;
            state.unitData = action.payload;
            state.error = null;
        },
        getUnitFail: (state, action) => {
            state.loading = false;
            state.unitData = null;
            state.error = action.payload;
        },
        getHistory: (state) => {
            state.loading = true;
        },
        getHistorySuccess: (state, action) => {
            state.loading = false;
            state.historyData = action.payload;
            state.error = null;
        },
        getHistoryFail: (state, action) => {
            state.loading = false;
            state.historyData = null;
            state.error = action.payload;
        },
        resetPaginationPlan: (state) => {
            state.allData = [];
            state.currentPage = 1;
            state.error = null;
        },
    },
});

export const {
    getDashboardPlan,
    getDashboardPlanFail,
    getDashboardPlanSuccess,
    getAllDashboarPlanFail,
    getAllDashboardPlan,
    getAllDashboardPlanSuccess,
    getUnit,
    getUnitFail,
    getUnitSuccess,
    getHistory,
    getHistoryFail,
    getHistorySuccess,
    resetPaginationPlan,
} = dashboardPlanSlice.actions;

export default dashboardPlanSlice.reducer;
