import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import {
  downloadExcel4,
  importExcelData4,
  setDataNull2,
} from '../../store/order/slice';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import UploadFile from '../../components/UploadFile';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { site } from '../../api/url_helper';
import { ProgressSpinner } from 'primereact/progressspinner';

const ModalExcel = ({ visible, onHide, id }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [clearFiles, setClearFiles] = useState(false);
  const dispatch = useDispatch();
  const toast = useRef(null);
  const [loading, setLoading] = useState(false);
  const [successOrder, setSuccessOrder] = useState(null);
  const [errorOrder, setErrorOrder] = useState(null);
  const [errorFileUrl, setErrorFileUrl] = useState(null);

  const [isResultDialogVisible, setIsResultDialogVisible] = useState(false);

  const { excelData4, importData4, errorImportData4, errorExcelData4 } =
    useSelector(
      (state) => ({
        excelData4: state.Production.excelData4,
        importData4: state.Production.importData4,
        errorImportData4: state.Production.errorImportData4,
        errorExcelData4: state.Production.errorExcelData4,
      }),
      shallowEqual
    );

  const handleDownloadClick = () => {
    setLoading(true);
    if (id) {
      dispatch(downloadExcel4({ type: 'quantity', building_id: id }));
    } else {
      dispatch(downloadExcel4({ type: 'quantity' }));
    }
  };

  const showToast = (severity, summary, detail) => {
    toast.current?.show({ severity, summary, detail, life: 3000 });
  };

  useEffect(() => {
    if (excelData4) {
      setLoading(false);
      const { file } = excelData4;
      if (file) {
        const fileUrl = `${site}/${file}`;
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', 'Sample data.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        dispatch(setDataNull2());
        showToast('success', 'Thành công', 'Tải thành công');
      }
    } else if (errorExcelData4) {
      setLoading(false);
      showToast('error', 'Thất bại', errorExcelData4.response.data.message);
      dispatch(setDataNull2());
    }
  }, [excelData4, errorExcelData4, dispatch]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {},
    onSubmit: () => {
      setLoading(true);
      dispatch(importExcelData4({ files: Array.from(selectedFiles) }));
    },
  });

  useEffect(() => {
    if (importData4) {
      setLoading(false);
      setIsResultDialogVisible(true);
      setSuccessOrder(importData4?.success);
      setErrorOrder(importData4?.error);
      const file = importData4?.file;
      if (file) {
        const fileUrl = `${site}/${file}`;
        setErrorFileUrl(fileUrl);
      }
      showToast('success', 'Thành công', 'Nhập thành công');
      formik.resetForm();
      onHide();
    } else if (errorImportData4) {
      setLoading(false);
      const file = errorImportData4?.file;
      if (file) {
        const fileUrl = `${site}/${file}`;
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', 'Error data.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      showToast('error', 'Thất bại', errorImportData4?.response?.data?.message);
      dispatch(setDataNull2());
    }
  }, [importData4, errorImportData4, dispatch, formik, onHide]);

  const header = <div>Thêm từ Excel</div>;

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        visible={visible}
        className='responsive-dialog'
        onHide={() => {
          onHide();
        }}
        header={header}
        style={{ width: '40%' }}>
        {loading && (
          <div className='flex justify-content-center align-items-center'>
            <ProgressSpinner />
          </div>
        )}
        {!loading && (
          <>
            <p className='font-bold'>Các bước</p>
            <div className='bg-yellow-100 px-5 py-3 line-height-4 border-round-md my-4'>
              <span>
                1. Tải file mẫu và nhập dữ liệu (
                <a
                  className='text-blue-500 font-bold underline cursor-pointer'
                  onClick={handleDownloadClick}>
                  Tải file mẫu
                </a>
                )
              </span>
              <span className='text-red-500 font-bold'></span>
              <p>2. Nhập dữ liệu chính xác theo các trường.</p>
              <p className='text-red-500'>
                Lưu ý: Không thay đổi tên và thứ tự các cột.
              </p>
            </div>
            <UploadFile
              onUpload={() => {}}
              getFiles={setSelectedFiles}
              clearFiles={clearFiles}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '40px',
                gap: '1rem',
              }}>
              <div className='card'>
                <Button
                  label='Hủy'
                  className='px-5 py-2 bg-white border-none text-400'
                  severity='secondary'
                  onClick={() => {
                    onHide();
                  }}
                />
              </div>
              <div className='card'>
                <Button
                  label='Nhập'
                  className='px-5 py-2 bg-green-700'
                  // severity="info"
                  onClick={formik.handleSubmit}
                  type='submit'
                />
              </div>
            </div>
          </>
        )}
      </Dialog>
      <Dialog
        visible={isResultDialogVisible}
        onHide={() => setIsResultDialogVisible(false)}
        className='responsive-dialog'
        header='Kết quả nhập dữ liệu'
        style={{ width: '40%' }}>
        <div className='surface-100 border-round-md p-3 line-height-4'>
          <div className='font-bold text-green-700'>
            Thành công: {successOrder}
          </div>
          <span className='text-red-500 font-bold'>
            Thất bại: {errorOrder}
            <a
              href={errorFileUrl}
              className='text-blue-500 underline cursor-pointer ml-2'
              download='Error data.xlsx'>
              Tải file
            </a>
          </span>
        </div>
      </Dialog>
    </>
  );
};

export default ModalExcel;
