import React, { useEffect, useState, useContext, useRef } from "react";
import HeaderReport from "../../layout/HeaderReport/HeaderReport";
import HeaderReportTechnical from "./HeaderReportTechnical";
import TableReportTechnical from "./TableReportTechnical";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
    getDashboardTechnical,
    getDashboardTechnicalDetail,
    getAllDashboardTechnicalDetail,
} from "../../store/dashboardTechnical/slice";
import { exportTechnicalPDF } from "./ExportTechnicalPDF";
import FormatDate from "../Report/FormatDate";
import { DataContext } from "../../contexts/data/DataProvider";
import {
    downloadExcelQuantity,
    setExcelDataQuantityNull,
} from "../../store/dashboardQuantity/slice";
import { formatDateApi } from "../../feature/formatDateApi";
import { site } from "../../api/url_helper";
import { Toast } from "primereact/toast";
import { resetPaginationTechnical } from "../../store/dashboardTechnical/slice";
import { useLocation } from "react-router-dom";
import axios from "axios";

export default function ReportTechnical() {
    const dispatch = useDispatch();
    const location = useLocation();

    const toast = useRef(null);
    const [building, setBuilding] = useState(null);
    const [orderCode, setOrderCode] = useState("");
    const [category, setCategory] = useState("");
    const [factoryCode, setFactoryCode] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [nameBuilding, setNameBuilding] = useState("");
    const [handleChildClick, setHandleChildClick] = useState(() => () => {});
    const [pagination, setPagination] = useState({ first: 0, rows: 10 });
    const [tabMenuActive, setTabMenuActive] = useState(0);
    const downloadLink = useRef(null);
    const [excelFileUrl, setExcelFileUrl] = useState("");
    const [selectedStatusReportTechnical, setSelectedStatusReportTechnical] =
        useState([]);
    const [allData, setAllData] = useState([]);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [page, setPage] = useState(1);
    const token = localStorage.getItem("accessToken");

    useEffect(() => {
        dispatch(resetPaginationTechnical());
    }, [location.pathname, dispatch]);

    const handleTabMenuChange = (newTabMenuActive) => {
        setTabMenuActive(newTabMenuActive);
    };

    const handlePaginationChange = (first, rows) => {
        setPagination({ first, rows });
    };

    // const childClickHandler = useCallback((handler) => {
    //     setHandleChildClick(() => handler);
    // }, []);

    // const handleClickInParent = () => {
    //     if (handleChildClick) {
    //         handleChildClick();
    //     }
    // };
    const {
        buildingData,
        exportExcelQuantity,
        exportExcelQuantityError,
        currentPage,
        allDataTechnical,
    } = useSelector(
        (state) => ({
            exportExcelQuantityError: state.DashboardQuantity.errorExcelData,
            exportExcelQuantity: state.DashboardQuantity.excelData,
            buildingData: state.Production.dataBuilding,
            currentPage: state.DashboardTechnical.currentPage,
            allDataTechnical: state.DashboardTechnical.allData,
        }),
        shallowEqual
    );

    useEffect(() => {
        if (
            building ||
            orderCode ||
            category ||
            factoryCode ||
            startDate ||
            endDate ||
            selectedStatusReportTechnical
        ) {
            setAllData([]);
        }
    }, [
        building,
        orderCode,
        category,
        factoryCode,
        startDate,
        endDate,
        selectedStatusReportTechnical,
    ]);

    // useEffect(() => {
    //     if (allDataTechnical?.data?.products?.items) {
    //         setAllData((prevData) => {
    //             const existingIds = new Set(prevData.map((item) => item.id));

    //             const newItems = allDataTechnical.data.products.items.filter(
    //                 (item) => !existingIds.has(item.id)
    //             );

    //             return [...prevData, ...newItems];
    //         });
    //     }
    // }, [
    //     allDataTechnical,
    //     currentPage,
    //     startDate,
    //     endDate,
    //     building,
    //     orderCode,
    //     category,
    //     factoryCode,
    //     selectedStatusReportTechnical,
    // ]);

    useEffect(() => {
        const isLoaded =
            allData.length === allDataTechnical?.data?.products?.totalItems;

        if (isLoaded) {
            setIsDataLoaded(true);
        } else {
            setIsDataLoaded(false);
        }
    }, [allDataTechnical, allData]);

    // useEffect(() => {
    //     let status = "Tất cả";
    //     if (dashboardTechnicalDetailData) {
    //         status =
    //             dashboardTechnicalDetailData.data.listStatus[tabMenuActive]
    //                 ?.label;
    //     }

    //     if (startDate && endDate) {
    //         dispatch(
    //             getAllDashboardTechnicalDetail({
    //                 page: currentPage,
    //                 limit: 100,
    //                 status,
    //                 timeStartReq: startDate,
    //                 timeEndReq: endDate,
    //                 building,
    //                 orderCode,
    //                 category,
    //                 factoryCode,
    //                 filterStatus: JSON.stringify(selectedStatusReportTechnical),
    //             })
    //         );
    //     }
    // }, [
    //     dispatch,
    //     currentPage,
    //     building,
    //     orderCode,
    //     category,
    //     factoryCode,
    //     startDate,
    //     endDate,
    //     pagination,
    //     tabMenuActive,
    //     selectedStatusReportTechnical,
    // ]);

    const exportExcelHandle = () => {
        const id = buildingData?.data?.items.find(
            (item) => item.buildingCode === building
        )?.id;

        if (id) {
            dispatch(
                downloadExcelQuantity({
                    type: "technical",
                    building_id: id,
                    timeStartReq: formatDateApi(startDate),
                    timeEndReq: formatDateApi(endDate),
                })
            );
        } else {
            dispatch(
                downloadExcelQuantity({
                    type: "technical",
                    timeStartReq: formatDateApi(startDate),
                    timeEndReq: formatDateApi(endDate),
                })
            );
        }
    };

    useEffect(() => {
        if (exportExcelQuantity) {
            const url = `${site}/${exportExcelQuantity.file}`;
            setExcelFileUrl(url);
            toast.current.show({
                severity: "success",
                summary: "Thành công",
                detail: "Xuất excel thành công.",
                life: 3000,
            });
            dispatch(setExcelDataQuantityNull());

            // Tự động tải file
            downloadLink.current.href = url;
            downloadLink.current.download = "exported_data.xlsx";
            downloadLink.current.click();
        }
    }, [dispatch, exportExcelQuantity]);

    useEffect(() => {
        if (exportExcelQuantityError) {
            toast.current.show({
                severity: "error",
                summary: "Thất bại",
                detail: exportExcelQuantityError.response.data.message,
                life: 3000,
            });
            dispatch(setExcelDataQuantityNull());
        }
    }, [dispatch, exportExcelQuantityError]);

    const { buildingName } = useContext(DataContext);
    useEffect(() => {
        if (buildingName) setNameBuilding(buildingName);
    }, [buildingName]);

    const dashboardTechnicalData = useSelector(
        (state) => state.DashboardTechnical.dashboardTechnicalData,
        shallowEqual
    );

    const dashboardTechnicalDetailData = useSelector(
        (state) => state.DashboardTechnical.dashboardTechnicalDetailData,
        shallowEqual
    );

    useEffect(() => {
        if (startDate && endDate) {
            dispatch(
                getDashboardTechnical({
                    timeStartReq: startDate,
                    timeEndReq: endDate,
                    building,
                    orderCode,
                    category,
                    factoryCode,
                })
            );
        }
    }, [
        dispatch,
        endDate,
        startDate,
        building,
        orderCode,
        category,
        factoryCode,
    ]);

    useEffect(() => {
        let status = "Tất cả";
        if (dashboardTechnicalDetailData) {
            status =
                dashboardTechnicalDetailData.data.listStatus[tabMenuActive]
                    ?.label;
        }

        if (startDate && endDate) {
            dispatch(
                getDashboardTechnicalDetail({
                    page:
                        (pagination.first + pagination.rows) / pagination.rows,
                    limit: pagination.rows,
                    status,
                    timeStartReq: startDate,
                    timeEndReq: endDate,
                    building,
                    orderCode,
                    category,
                    factoryCode,
                    filterStatus: JSON.stringify(selectedStatusReportTechnical),
                })
            );
        }
    }, [
        dispatch,
        building,
        orderCode,
        category,
        factoryCode,
        startDate,
        endDate,
        // limit,
        pagination,
        tabMenuActive,
        selectedStatusReportTechnical,
    ]);

    // const handleExportClick = (type) => {
    //     if (!isDataLoaded) {
    //         toast.current.show({
    //             severity: "warn",
    //             summary: "Cảnh báo",
    //             detail: "Vui lòng đợi dữ liệu được tải đầy đủ.",
    //             life: 3000,
    //         });
    //         return;
    //     }
    //     exportTechnicalPDF(
    //         type,
    //         FormatDate(startDate),
    //         FormatDate(endDate),
    //         dashboardTechnicalData?.data,
    //         allData,
    //         nameBuilding
    //     );
    // };
    const handleExportClick = async (type) => {
        if (startDate && endDate) {
            try {
                // Fetch the first page's data to get the total pages for pagination
                const response = await axios.get(
                    `${site}/api/dashboard/technical-statistics/details`,
                    {
                        params: {
                            page: page,
                            limit: 100,
                            timeStartReq: startDate,
                            timeEndReq: endDate,
                            building,
                            orderCode,
                            category,
                            factoryCode,
                            filterStatus: JSON.stringify(
                                selectedStatusReportTechnical
                            ),
                        },
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json", // Fix header key
                        },
                        withCredentials: true,
                    }
                );

                const totalPages = response?.data?.data?.products?.totalPages;

                const allDataArray = [];
                const requests = [];

                // Fetch data for all pages concurrently
                for (let page = 1; page <= totalPages; page++) {
                    requests.push(
                        axios.get(
                            `${site}/api/dashboard/technical-statistics/details`,
                            {
                                params: {
                                    page: page,
                                    limit: 100,
                                    timeStartReq: startDate,
                                    timeEndReq: endDate,
                                    building,
                                    orderCode,
                                    category,
                                    factoryCode,
                                    filterStatus: JSON.stringify(
                                        selectedStatusReportTechnical
                                    ),
                                },
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                    "Content-Type": "application/json", // Fix header key
                                },
                                withCredentials: true,
                            }
                        )
                    );
                }

                const responses = await Promise.all(requests);

                responses.forEach((res) => {
                    allDataArray.push(...res.data?.data?.products.items);
                });

                // Trigger the export logic
                exportTechnicalPDF(
                    type,
                    FormatDate(startDate),
                    FormatDate(endDate),
                    dashboardTechnicalData?.data,
                    allDataArray,
                    nameBuilding
                );
            } catch (error) {
                console.error("Error fetching data:", error);
                toast.current.show({
                    severity: "error",
                    summary: "Lỗi",
                    detail: "Có lỗi xảy ra khi tải dữ liệu.",
                    life: 3000,
                });
            }
        } else {
            console.log("Start Date or End Date is missing");
        }
    };

    return (
        <div>
            <Toast ref={toast} />
            <a ref={downloadLink} style={{ display: "none" }} />
            <HeaderReport
                building={building}
                orderCode={orderCode}
                category={category}
                factoryCode={factoryCode}
                setBuilding={setBuilding}
                setOrderCode={setOrderCode}
                setCategory={setCategory}
                setFactoryCode={setFactoryCode}
                setStartDateOutput={setStartDate}
                setEndDateOutput={setEndDate}
                exportClick={(e) => handleExportClick("save")}
                printReport={(e) => handleExportClick("print")}
                // exportExcel={exportExcelHandle}
                exportExcel={exportExcelHandle}
            />
            <HeaderReportTechnical
                dashboardTechnicalData={dashboardTechnicalData}
            />
            <TableReportTechnical
                dashboardTechnicalDetailData={dashboardTechnicalDetailData}
                // onButtonClick={childClickHandler}
                onPaginationChange={handlePaginationChange}
                onTabMenuChange={handleTabMenuChange}
                selectedStatusReportTechnical={selectedStatusReportTechnical}
                setSelectedStatusReportTechnical={
                    setSelectedStatusReportTechnical
                }
            />
        </div>
    );
}
