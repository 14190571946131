import FormatDate from "../Report/FormatDate";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake ? pdfFonts.pdfMake.vfs : pdfFonts.vfs;


const defaultImageUrl = "https://th.bing.com/th/id/OIP.CF-nc_bSeMzAbjG7KsjXlQHaHa?rs=1&pid=ImgDetMain";

const compressImage = async (imageFile, maxWidth, maxHeight, quality) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = URL.createObjectURL(imageFile);

        img.onload = () => {
            const canvas = document.createElement('canvas');
            let width = img.width;
            let height = img.height;

            if (width > height) {
                if (width > maxWidth) {
                    height *= maxWidth / width;
                    width = maxWidth;
                }
            } else {
                if (height > maxHeight) {
                    width *= maxHeight / height;
                    height = maxHeight;
                }
            }

            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0, width, height);

            canvas.toBlob((blob) => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
            }, 'image/jpeg', quality);
        };

        img.onerror = (error) => reject(error);
    });
};

const getBase64FromUrl = async (url) => {
    try {
        const response = await fetch(url);
        const blob = await response.blob();

        if (!blob.type.startsWith('image/')) {
            throw new Error('Invalid image format');
        }

        const compressedBase64 = await compressImage(blob, 300, 300, 0.7);
        return compressedBase64;
    } catch (error) {
        console.error('Error converting image to base64, using default image:', error);

        // Sử dụng ảnh mặc định khi có lỗi
        try {
            const defaultResponse = await fetch(defaultImageUrl);
            const defaultBlob = await defaultResponse.blob();
            const defaultCompressedBase64 = await compressImage(defaultBlob, 300, 300, 0.7);
            return defaultCompressedBase64;
        } catch (defaultError) {
            console.error('Error loading default image:', defaultError);
            return null;
        }
    }
};

const prepareDataTableWithImages = async (datatable) => {
    const updatedDataTable = await Promise.all(datatable.map(async (product) => {
        const base64Image = await getBase64FromUrl(product.categoryImage);
        return {
            ...product,
            categoryImage: base64Image
        };
    }));
    return updatedDataTable;
};

export const exportQuantityPDF = async (datatable) => {
    const updatedDataTable = await prepareDataTableWithImages(datatable);

    const docDefinition = {
        pageSize: { width: 1500, height: 900 },
        pageMargins: [40, 60, 40, 60],
        content: [
            { text: "Báo cáo sản lượng", style: "header", margin: [0, 0, 0, 20] },
            {
                table: {
                    headerRows: 1,
                    widths: [
                        "auto", "auto", 100, 100, "auto", 50, "auto", 100, 100, 100, 100, 100, 100, 100, 180,
                    ],
                    body: [
                        [
                            { text: "STT", fillColor: "#2980BA", color: "#ffffff", bold: true },
                            { text: "Tên CT", fillColor: "#2980BA", color: "#ffffff", bold: true },
                            { text: "Mã ĐH", fillColor: "#2980BA", color: "#ffffff", bold: true },
                            { text: "Hạng mục", fillColor: "#2980BA", color: "#ffffff", bold: true },
                            { text: "Hình ảnh", fillColor: "#2980BA", color: "#ffffff", bold: true },
                            { text: "Số lượng", fillColor: "#2980BA", color: "#ffffff", bold: true },
                            { text: "ĐVT", fillColor: "#2980BA", color: "#ffffff", bold: true },
                            { text: "Mã sản phẩm", fillColor: "#2980BA", color: "#ffffff", bold: true },
                            { text: "Mã nhà máy", fillColor: "#2980BA", color: "#ffffff", bold: true },
                            { text: "Kế hoạch hoàn thành", fillColor: "#2980BA", color: "#ffffff", bold: true },
                            { text: "Trạng thái", fillColor: "#2980BA", color: "#ffffff", bold: true },
                            { text: "Ngày ra BOM", fillColor: "#2980BA", color: "#ffffff", bold: true },
                            { text: "Ngày bắt đầu SX", fillColor: "#2980BA", color: "#ffffff", bold: true },
                            { text: "Hoàn thành thực tế", fillColor: "#2980BA", color: "#ffffff", bold: true },
                            { text: "Ghi chú", fillColor: "#2980BA", color: "#ffffff", bold: true },
                        ],
                        ...updatedDataTable.map((product, index) => [
                            { text: index + 1 },
                            { text: product.Building.name },
                            { text: product.orderCode },
                            { text: product.categoryName },
                            { image: product.categoryImage, width: 50, height: 50, fit: [50, 50] },
                            { text: product.quantity },
                            { text: product.unit },
                            { text: product.productCode },
                            { text: product.factoryCode },
                            { text: FormatDate(product.intendFinishDate) },
                            { text: product.status },
                            { text: FormatDate(product.bomProductionDate) },
                            { text: FormatDate(product.startedDate) },
                            { text: FormatDate(product.endedDate) },
                            { text: product.productNote },
                        ]),
                    ],
                },
            },
        ],
        styles: {
            header: {
                fontSize: 18,
                bold: true,
                alignment: "center",
            },
        },
        defaultStyle: {
            font: "Roboto",
        },
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.download("SanXuat.pdf"); // Tải xuống PDF
    pdfDocGenerator.open(); // Mở PDF trực tiếp
};
