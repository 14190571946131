import React, { useState, useEffect, useRef } from "react";
import { TabMenu } from "primereact/tabmenu";
import tabMenuTemplate from "../../assets/templete/tabMenuTemplete";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { statusTemplate } from "../../components/utils";
import FormatDate from "../Report/FormatDate";
import { site } from "../../api/url_helper";
import LoadingSpinner from "../Report/LoadingSpinner";
import "../../assets/scss/theme-base/dashboard.css";
import { Button } from "primereact/button";
import Intersection from "../Plan/intersection";
import { imageTemplate } from "../Report/ProductInprogress";
import { FilterMatchMode } from "primereact/api";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Toast } from "primereact/toast";
import { Paginator } from "primereact/paginator";
import { setExcelDataQuantityNull } from "../../store/dashboardQuantity/slice";
import { statusFilterTemplate } from "../../constants/status";
import ModalFilterCategoryReportTechnical from "./ModalFilterCategoryReportTechnical";
import QR from "../Plan/QR";
import CustomMultiSelect from "../../constants/CustomMultiSelect";

export default function TableReportTechnical({
    dashboardTechnicalDetailData,
    onButtonClick,
    onPaginationChange,
    onTabMenuChange,
    selectedStatusReportTechnical,
    setSelectedStatusReportTechnical,
}) {
    const dispatch = useDispatch();
    const [tabMenuActive, setTabmenuActive] = useState(0);
    const [dataTable, setDataTable] = useState([]);
    const [dialogLocationVisible, setDialogLocationVisible] = useState(false);
    const [selectFactoryCode, setSelectFactoryCode] = useState(null);
    const toast = useRef(null);
    const downloadLink = useRef(null);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [qrDetail, setQrDetail] = useState(null);
    const [visible3, setVisible3] = useState(false);

    const handleStatusChange = (newStatuses) => {
        setSelectedStatusReportTechnical(newStatuses);
    };

    // const { startTime, endTime, buildingId } = useContext(DataContext);

    // console.log("excel", startTime, endTime, buildingId);

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        "Building.buildingCode": {
            value: null,
            matchMode: FilterMatchMode.CONTAINS,
        },
        orderCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
        categoryName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        factoryCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
        unit: { value: null, matchMode: FilterMatchMode.CONTAINS },
        productCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
        quantity: { value: null, matchMode: FilterMatchMode.CONTAINS },
        intendFinishDate: { value: null, matchMode: FilterMatchMode.DATE_IS },
        status: { value: [], matchMode: FilterMatchMode.IN },
    });

    const handleTeamProgress = (rowData) => {
        setSelectFactoryCode(rowData.factoryCode);
        setDialogLocationVisible(true);
        setSelectedRow(rowData);
    };

    useEffect(() => {
        setDataTable(dashboardTechnicalDetailData?.data?.products?.items);
    }, [dashboardTechnicalDetailData]);

    useEffect(() => {
        const updateDataTable = () => {
            const status =
                dashboardTechnicalDetailData?.data?.listStatus[tabMenuActive]
                    ?.label;
            const filteredData =
                status === "Tất cả"
                    ? dashboardTechnicalDetailData?.data?.products?.items
                    : dashboardTechnicalDetailData?.data?.products?.items.filter(
                          (item) => item.status === status
                      );
            setDataTable(filteredData);
        };
        updateDataTable();
    }, [tabMenuActive]);

    //export excel

    const { buildingData, exportExcelQuantity, exportExcelQuantityError } =
        useSelector(
            (state) => ({
                exportExcelQuantityError:
                    state.DashboardQuantity.errorExcelData,
                exportExcelQuantity: state.DashboardQuantity.excelData,
                buildingData: state.Production.dataBuilding,
            }),
            shallowEqual
        );

    useEffect(() => {
        if (exportExcelQuantity) {
            const url = `${site}/${exportExcelQuantity.file}`;
            toast.current.show({
                severity: "success",
                summary: "Thành công",
                detail: "Xuất excel thành công.",
                life: 3000,
            });
            dispatch(setExcelDataQuantityNull());

            // Tự động tải file
            downloadLink.current.href = url;
            downloadLink.current.download = "exported_data.xlsx";
            downloadLink.current.click();
        }
    }, [dispatch, exportExcelQuantity]);

    useEffect(() => {
        if (exportExcelQuantityError) {
            toast.current.show({
                severity: "error",
                summary: "Thất bại",
                detail: exportExcelQuantityError.response.data.message,
                life: 3000,
            });
            dispatch(setExcelDataQuantityNull());
        }
    }, [dispatch, exportExcelQuantityError]);

    // useEffect(() => {
    //     if (onButtonClick) {
    //         onButtonClick(exportExcelHandle);
    //     }
    // }, [onButtonClick]);

    useEffect(() => {
        if (onTabMenuChange) {
            onTabMenuChange(tabMenuActive);
        }
    }, [tabMenuActive, onTabMenuChange]);

    const onPageChange = (event) => {
        const newFirst = event.first;
        const newRows = event.rows;

        setFirst(newFirst);
        setRows(newRows);
        if (onPaginationChange) {
            onPaginationChange(newFirst, newRows);
        }
    };

    const handleDetailScanned = (detail) => {
        setQrDetail(detail);
    };

    const handleSelectionChange = (e) => {
        const selected = e.value || [];
        const currentPageItems = dataTable || [];

        // Loại bỏ các phần tử thuộc trang hiện tại khỏi selectedProducts
        const filteredProducts =
            selectedProducts?.filter(
                (item) => !currentPageItems.some((row) => row.id === item.id)
            ) || [];

        // Gộp các phần tử không bị xóa với phần tử vừa chọn
        const updatedSelection = [...filteredProducts, ...selected];

        // Loại bỏ phần tử trùng lặp
        const uniqueSelection = Array.from(
            new Map(updatedSelection.map((item) => [item.id, item])).values()
        );

        setSelectedProducts(uniqueSelection);
    };
    return (
        <div>
            <Toast ref={toast} />
            <a ref={downloadLink} style={{ display: "none" }} />
            <div className="bg-white border-round-md shadow-1 px-3 mt-3">
                <div className="flex md:justify-content-between md:align-items-center flex-column md:flex-row gap-4">
                    <TabMenu
                        model={dashboardTechnicalDetailData?.data?.listStatus?.map(
                            (item, index) => ({
                                ...item,
                                template: () =>
                                    tabMenuTemplate(
                                        item,
                                        index,
                                        tabMenuActive,
                                        setTabmenuActive
                                    ),
                            })
                        )}
                        activeIndex={tabMenuActive}
                        onTabChange={(e) => setTabmenuActive(e.index)}
                        pt={{ menuitem: "p-0" }}
                        style={{ maxWidth: "70%" }}
                    />
                    <div className="flex gap-4">
                        <CustomMultiSelect
                            value={selectedStatusReportTechnical}
                            onChange={handleStatusChange}
                            className="max-w-14rem"
                        />
                        <Button
                            label="Lọc theo hạng mục"
                            onClick={() => setOpenModal(true)}
                        ></Button>
                    </div>
                </div>
                {dashboardTechnicalDetailData &&
                dashboardTechnicalDetailData.data ? (
                    <div>
                        <DataTable
                            value={dataTable}
                            // paginator
                            // rows={10}
                            // rowsPerPageOptions={[10, 25, 50, 100, 500]}
                            rowClassName="custom-row-class"
                            size="small"
                            emptyMessage="Không có dữ liệu"
                            filters={filters}
                            onFilter={(e) => setFilters(e.filters)}
                            scrollable
                            scrollHeight="50vh"
                            selectionMode="checkbox"
                            selection={selectedProducts || []}
                            onSelectionChange={handleSelectionChange}
                            dataKey="id"
                        >
                            <Column selectionMode="multiple"></Column>
                            <Column
                                header="STT"
                                body={(rowData, options) => (
                                    <div className="text-center">
                                        {first + options.rowIndex + 1}
                                    </div>
                                )}
                                style={{ width: "3%" }}
                                alignHeader={"center"}
                                align={"center"}
                            ></Column>
                            <Column
                                header="Tên công trình"
                                field="Building.name"
                                style={{ minWidth: "150px" }}
                                filter
                            ></Column>
                            <Column
                                header="Mã ĐH"
                                style={{ minWidth: "100px" }}
                                filter
                                field="orderCode"
                            ></Column>
                            <Column
                                header="Mã nhà máy"
                                field="factoryCode"
                                style={{ minWidth: "120px" }}
                                filter
                            ></Column>
                            <Column
                                header="Hạng mục"
                                style={{
                                    lineHeight: "1.5",
                                    minWidth: "200px",
                                }}
                                field="categoryName"
                                filter
                            ></Column>
                            <Column
                                header="Hình ảnh"
                                style={{ minWidth: "100px" }}
                                body={imageTemplate}
                                alignHeader={"center"}
                                align={"center"}
                            ></Column>
                            <Column
                                header="SL"
                                field="quantity"
                                style={{ minWidth: "70px" }}
                            ></Column>
                            <Column
                                header="ĐVT"
                                field="unit"
                                filter
                                style={{ minWidth: "50px" }}
                            ></Column>
                            <Column
                                header="Mã SP"
                                field="productCode"
                                style={{ minWidth: "100px" }}
                                filter
                            ></Column>
                            <Column
                                header="Kế hoạch hoàn thành"
                                field="intendFinishDate"
                                style={{ minWidth: "120px" }}
                                body={(rowData) => (
                                    <div
                                        style={{
                                            backgroundColor: `${rowData.color}`,
                                            padding: "4px 0",
                                            margin: "0 26px",
                                            borderRadius: "5px",
                                        }}
                                    >
                                        {FormatDate(rowData.intendFinishDate)}
                                    </div>
                                )}
                                sortable
                                align="center"
                                alignHeader="center"
                            ></Column>
                            <Column
                                header="Trạng thái"
                                field="status"
                                body={statusTemplate}
                                alignHeader={"center"}
                                align={"center"}
                                style={{ minWidth: "150px" }}
                                // filter
                                // filterElement={statusFilterTemplate}
                                // pt={{
                                //     filterButtonbar: { className: "hidden" },
                                // }}
                            ></Column>
                            <Column
                                header="Ngày đặt vật tư"
                                field="materialOrderingDate"
                                style={{ minWidth: "150px" }}
                                body={(rowData) =>
                                    FormatDate(rowData.materialOrderingDate)
                                }
                                sortable
                                align="center"
                                alignHeader="center"
                            ></Column>
                            <Column
                                header="Đã về"
                                field="materialCheck"
                                style={{ width: "8%", minWidth: "100px" }}
                                body={(rowData) =>
                                    rowData?.materialCheck ? (
                                        <i className="pi pi-check text-red-500 text-xl font-semibold"></i>
                                    ) : (
                                        <div></div>
                                    )
                                }
                                align={"center"}
                            ></Column>
                            <Column
                                header="Ngày đặt hàng gia công"
                                field="machiningOrderingDate"
                                style={{ minWidth: "150px" }}
                                body={(rowData) =>
                                    rowData.notMachining
                                        ? "Không cần hàng gia công"
                                        : FormatDate(
                                              rowData.machiningOrderingDate
                                          )
                                }
                                sortable
                                align="center"
                                alignHeader="center"
                            ></Column>
                            <Column
                                header="Đã về"
                                field="machiningCheck"
                                style={{ width: "8%", minWidth: "100px" }}
                                body={(rowData) =>
                                    rowData?.machiningCheck ? (
                                        <i className="pi pi-check text-red-500 text-xl font-semibold"></i>
                                    ) : (
                                        <div></div>
                                    )
                                }
                                align={"center"}
                            ></Column>
                            <Column
                                header="Ngày ra BOM"
                                field="bomProductionDate"
                                style={{ minWidth: "120px" }}
                                body={(rowData) =>
                                    FormatDate(rowData.bomProductionDate)
                                }
                                sortable
                                align="center"
                                alignHeader="center"
                            ></Column>
                            <Column
                                header="Định vị"
                                body={(rowData) => (
                                    <div className="text-center">
                                        <Button
                                            onClick={() =>
                                                handleTeamProgress(rowData)
                                            }
                                            icon="pi pi-map-marker"
                                            rounded
                                            text
                                            severity="secondary"
                                            aria-label="Bookmark"
                                            style={{ color: "black" }}
                                        />
                                        <p>
                                            {rowData.status !==
                                                "Hoàn thành đúng tiến độ" &&
                                            rowData.status !==
                                                "Hoàn thành trễ tiến độ"
                                                ? rowData.teamWorking
                                                : "Đã giao"}
                                        </p>
                                    </div>
                                )}
                                alignHeader={"center"}
                                align={"center"}
                                style={{ minWidth: "100px" }}
                            ></Column>
                            <Column
                                header="In QR"
                                body={(rowData) => (
                                    <div className="text-center">
                                        <Button
                                            onClick={() => {
                                                setQrDetail(rowData);
                                                setVisible3(true);
                                            }}
                                            icon="pi pi-qrcode"
                                            rounded
                                            text
                                            severity="secondary"
                                            aria-label="QR"
                                            style={{ color: "black" }}
                                        />
                                    </div>
                                )}
                                style={{ width: "8%", minWidth: "100px" }}
                                alignHeader={"center"}
                                align={"center"}
                            ></Column>
                            <Column
                                header="Ghi chú"
                                field="technicalNote"
                                style={{ minWidth: "200px" }}
                            ></Column>
                        </DataTable>
                        <Paginator
                            first={first}
                            rows={rows}
                            totalRecords={
                                dashboardTechnicalDetailData?.data?.products
                                    .totalItems
                            }
                            rowsPerPageOptions={[10, 20, 30, 100, 200, 500]}
                            onPageChange={onPageChange}
                        />
                        {dialogLocationVisible && (
                            <Intersection
                                visible={dialogLocationVisible}
                                onHide={() => setDialogLocationVisible(false)}
                                factoryCode={selectFactoryCode}
                                rowData={selectedRow}
                            />
                        )}
                        <ModalFilterCategoryReportTechnical
                            visible={openModal}
                            onHide={() => setOpenModal(false)}
                            data={selectedProducts}
                        />
                        <QR
                            isShow={visible3}
                            hiden={() => setVisible3(false)}
                            detail={qrDetail}
                            onDetailScanned={handleDetailScanned}
                        />
                    </div>
                ) : (
                    <LoadingSpinner />
                )}
            </div>
        </div>
    );
}
