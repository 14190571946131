import FormatDate from "../Report/FormatDate";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import html2canvas from "html2canvas";
import logo from "../../assets/images/logo.png";
import { formatDate2 } from "../../components/utils";

pdfMake.vfs = pdfFonts.pdfMake ? pdfFonts.pdfMake.vfs : pdfFonts.vfs;
const defaultImageUrl =
    "https://th.bing.com/th/id/OIP.CF-nc_bSeMzAbjG7KsjXlQHaHa?rs=1&pid=ImgDetMain";

const getBase64Image = (blob) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = (error) => {
            console.error("Error reading file as Base64:", error);
            reject(error);
        };
        reader.readAsDataURL(blob);
    });
};
const fetchImageAsBase64 = async (imagePath) => {
    const response = await fetch(imagePath);
    const blob = await response.blob();
    const base64Image = await getBase64Image(blob);
    return base64Image;
};

const compressImage = async (imageFile, maxWidth, maxHeight, quality) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = URL.createObjectURL(imageFile);

        img.onload = () => {
            const canvas = document.createElement("canvas");
            let width = img.width;
            let height = img.height;

            if (width > height) {
                if (width > maxWidth) {
                    height *= maxWidth / width;
                    width = maxWidth;
                }
            } else {
                if (height > maxHeight) {
                    width *= maxHeight / height;
                    height = maxHeight;
                }
            }

            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, width, height);

            canvas.toBlob(
                (blob) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onloadend = () => resolve(reader.result);
                    reader.onerror = reject;
                },
                "image/jpeg",
                quality
            );
        };

        img.onerror = (error) => reject(error);
    });
};
const getBase64FromUrl = async (url) => {
    try {
        const response = await fetch(url);
        const blob = await response.blob();

        if (!blob.type.startsWith("image/")) {
            console.warn("Invalid image format. Expected an image.");
            return null;
        }

        const compressedBase64 = await compressImage(blob, 300, 300, 0.7);
        return compressedBase64;
    } catch (error) {
        console.error("Error converting image to base64:", error);
        try {
            const defaultResponse = await fetch(defaultImageUrl);
            const defaultBlob = await defaultResponse.blob();
            const defaultCompressedBase64 = await compressImage(
                defaultBlob,
                300,
                300,
                0.7
            );
            return defaultCompressedBase64;
        } catch (defaultError) {
            console.error("Error loading default image:", defaultError);
            return null;
        }
    }
};

export const exportQualityPDF = async (data) => {
    if (!data || data.length === 0) {
        console.warn("No data available to export.");
        return;
    }
    const imagePath = logo;

    const logoBase64 = await fetchImageAsBase64(imagePath);
    const images = {};
    // // Tải tất cả các ảnh từ URL và lưu chúng dưới dạng Base64
    for (const event of data) {
        for (const product of event.Products) {
            console.log("product", product.Qualities);
            for (const quality of product.Qualities) {
                console.log("quality", quality.images);
                for (const image of quality.images) {
                    try {
                        images[image] = await getBase64FromUrl(image);
                    } catch (error) {
                        console.error(`Failed to load image: ${image}`, error);
                    }
                }
            }
        }
    }

    const groupImagesIntoRows = (images, maxPerRow) => {
        const rows = [];
        for (let i = 0; i < images.length; i += maxPerRow) {
            rows.push(images.slice(i, i + maxPerRow));
        }
        return rows;
    };

    const docDefinition = {
        pageSize: "A4",
        pageMargins: [40, 60, 40, 60],
        content: [
            {
                image: logoBase64,
                width: 30,
                absolutePosition: { x: 40, y: 30 },
            },
            {
                text: "Báo cáo chất lượng",
                style: "header",
                fontSize: 24,
                margin: [0, 0, 0, 10],
            },
            {
                text: data[0]?.name,
                alignment: "center",
                fontSize: 16,
                margin: [0, 0, 0, 20],
            },
            ...data[0]?.Products?.flatMap((product) => [
                {
                    text: `Tên hạng mục: ${product.categoryName}`,
                    style: "subheader",
                    margin: [0, 10, 0, 0],
                    fontSize: 14,
                },
                ...product?.Qualities.map((item) => ({
                    stack: [
                        {
                            text: `Tên tổ đội: ${item.teamName}`,
                            margin: [0, 10, 0, 5],
                        },
                        {
                            text: `Thời gian bắt đầu: ${formatDate2(
                                item.createdAt
                            )}`,
                            margin: [0, 0, 0, 5],
                        },
                        {
                            text: `Ghi chú: ${item.note}`,
                            margin: [0, 0, 0, 5],
                        },
                        {
                            text: `Mã nhà máy: ${item.factoryCode}`,
                            margin: [0, 0, 0, 5],
                        },
                        item.images.length > 0
                            ? {
                                  stack: groupImagesIntoRows(
                                      item.images.map((image) =>
                                          images[image]
                                              ? {
                                                    image: images[image],
                                                    width: 120, // Đảm bảo tất cả ảnh có cùng chiều rộng
                                                    height: 120, // Đảm bảo tất cả ảnh có cùng chiều cao
                                                    margin: [0, 0, 10, 10],
                                                }
                                              : {
                                                    text: "",
                                                    margin: [0, 5, 0, 5],
                                                }
                                      ),
                                      4
                                  ).map((row) => ({
                                      columns: row,
                                      columnGap: 10,
                                  })),
                              }
                            : { text: "", margin: [0, 5, 0, 5] },
                        ,
                    ].filter(Boolean),
                })),
            ]),
        ],
        styles: {
            header: {
                fontSize: 18,
                bold: true,
                alignment: "center",
            },
        },
        defaultStyle: {
            font: "Roboto",
        },
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);

    pdfDocGenerator.download("quality.pdf");
};
