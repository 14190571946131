import FormatDate from '../Report/FormatDate';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import html2canvas from 'html2canvas';
import logo from '../../assets/images/logo.png';
import QRCodeWithBase64 from '../Report/QRCodeWithBase64';
import ReactDOM from 'react-dom';
import ValueQRCodeUrl from '../Report/ValueQR';

pdfMake.vfs = pdfFonts.pdfMake ? pdfFonts.pdfMake.vfs : pdfFonts.vfs;
const defaultImageUrl =
  'https://th.bing.com/th/id/OIP.CF-nc_bSeMzAbjG7KsjXlQHaHa?rs=1&pid=ImgDetMain';

const getBase64Image = (img) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(img);
  });
};
const fetchImageAsBase64 = async (imagePath) => {
  const response = await fetch(imagePath);
  const blob = await response.blob();
  const base64Image = await getBase64Image(blob);
  return base64Image;
};

const compressImage = async (imageFile, maxWidth, maxHeight, quality) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = URL.createObjectURL(imageFile);

    img.onload = () => {
      const canvas = document.createElement('canvas');
      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }
      }

      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, width, height);

      canvas.toBlob(
        (blob) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
        },
        'image/jpeg',
        quality
      );
    };

    img.onerror = (error) => reject(error);
  });
};

const getBase64FromUrl = async (url) => {
  try {
    const response = await fetch(url);
    const blob = await response.blob();

    if (!blob.type.startsWith('image/')) {
      throw new Error('Invalid image format');
    }

    const compressedBase64 = await compressImage(blob, 300, 300, 0.7);
    return compressedBase64;
  } catch (error) {
    console.error('Error converting image to base64:', error);
    // Sử dụng ảnh mặc định khi có lỗi
    try {
      const defaultResponse = await fetch(defaultImageUrl);
      const defaultBlob = await defaultResponse.blob();
      const defaultCompressedBase64 = await compressImage(
        defaultBlob,
        300,
        300,
        0.7
      );
      return defaultCompressedBase64;
    } catch (defaultError) {
      console.error('Error loading default image:', defaultError);
      return null;
    }
  }
};
const generateQRCodeBase64 = (value) => {
  return new Promise((resolve) => {
    const qrContainer = document.createElement('div');
    ReactDOM.render(
      <QRCodeWithBase64
        value={value}
        size={200}
        onRenderAsImage={resolve}
      />,
      qrContainer
    );
  });
};

const prepareDataTableWithImages = async (datatable) => {
  const updatedDataTable = await Promise.all(
    datatable.map(async (product) => {
      const base64Image = await getBase64FromUrl(product.categoryImage);
      const qrImage = await generateQRCodeBase64(ValueQRCodeUrl(product));
      return {
        ...product,
        categoryImage: base64Image,
        qrCode: qrImage,
      };
    })
  );
  return updatedDataTable;
};

export const exportConstructionPDF = async (
  action,
  startDate,
  endDate,
  datatableFactory,
  productInLate,
  productInprogress,
  productIncomplete,
  buildingName
) => {
  const updatedDataTable = await prepareDataTableWithImages(productInprogress);

  const imagePath = logo;

  const logoBase64 = await fetchImageAsBase64(imagePath);
  const donutChartElement = document.getElementById('donut-chart');
  const headerPdfElement = document.getElementById('header-pdf');

  const captureElementAsImage = async (element) => {
    if (!element) return null;
    const canvas = await html2canvas(element);
    return canvas.toDataURL('image/png');
  };

  const [donutChartImgData, headerPdfImgData] = await Promise.all([
    captureElementAsImage(donutChartElement),
    captureElementAsImage(headerPdfElement),
  ]);
  const element = document.getElementById('donut-chart');

  // html2canvas(element).then((canvas) => {
  // const imgData = canvas.toDataURL("image/png");
  const docDefinition = {
    pageSize: { width: 1200, height: 900 },
    pageMargins: [40, 60, 40, 60],
    content: [
      {
        image: logoBase64,
        width: 40,
        absolutePosition: { x: 40, y: 50 },
      },
      { text: buildingName, absolutePosition: { x: 100, y: 60 } },
      {
        text: 'Báo cáo công trình',
        style: 'header',
        fontSize: 28,
        margin: [0, 0, 0, 20],
      },
      {
        text: `Từ ngày: ${startDate} ~ Đến ngày: ${endDate}`,
        alignment: 'center',
        fontSize: 14,
        margin: [0, 0, 0, 0],
      },
      {
        image: headerPdfImgData,
        margin: [0, 0, 0, 20],
        alignment: 'center',
      },
      {
        image: donutChartImgData,
        margin: [0, 20, 0, 0],
        alignment: 'center',
      },
      // {
      //     text: `Tổng số hạng mục: ${datatableFactory?.countTotalCategories}`,
      //     style: "subheader",

      //     fontSize: 18,
      //     margin: [400, 0, 0, 0],
      // },
      // {
      //     text: `Chưa đặt vật tư: ${datatableFactory?.countProductsWithoutMaterialOrdering}`,
      //     style: "subheader",
      //     margin: [400, 0, 0, 0],
      //     fontSize: 18,
      // },
      // {
      //     text: `Chưa đặt hàng gia công: ${datatableFactory?.countProductsWithoutMachiningOrdering}`,
      //     style: "subheader",
      //     margin: [400, 0, 0, 0],

      //     fontSize: 18,
      // },
      // {
      //     text: `Chưa có BOM: ${datatableFactory?.countProductsWithoutBomProduction}`,
      //     style: "subheader",
      //     margin: [400, 0, 0, 10],

      //     fontSize: 18,
      // },

      {
        text: 'Theo dõi sản xuất',
        style: 'subheader',
        margin: [0, 20, 0, 5],
        bold: true,
        fontSize: 14,
        pageBreak: 'before',
      },
      {
        table: {
          headerRows: 1,
          dontBreakRows: true,
          keepWithHeaderRows: 1,
          widths: ['auto', 130, 80, 80, 50, 50, 120, 100, 80, 50, 150, 100],
          body: [
            [
              {
                text: 'STT',
                bold: true,
                fillColor: '#2980BA',
                color: '#ffffff',
                alignment: 'center',
              },
              {
                text: 'Hạng mục',

                bold: true,
                fillColor: '#2980BA',
                color: '#ffffff',
              },
              {
                text: 'Mã công trình',
                bold: true,
                alignment: 'center',
                fillColor: '#2980BA',
                color: '#ffffff',
              },
              {
                text: 'Mã nhà máy',
                alignment: 'center',
                bold: true,
                fillColor: '#2980BA',
                color: '#ffffff',
              },
              {
                text: 'ĐVT',
                bold: true,
                alignment: 'center',
                fillColor: '#2980BA',
                color: '#ffffff',
              },
              {
                text: 'Số lượng',
                alignment: 'center',
                bold: true,
                alignment: 'center',
                fillColor: '#2980BA',
                color: '#ffffff',
              },
              {
                text: 'Kế hoạch hoàn thành',
                bold: true,
                alignment: 'center',
                fillColor: '#2980BA',
                color: '#ffffff',
              },
              {
                text: 'Định vị',
                bold: true,
                alignment: 'center',
                fillColor: '#2980BA',
                color: '#ffffff',
              },
              {
                text: 'Trạng thái',
                bold: true,
                alignment: 'center',
                fillColor: '#2980BA',
                color: '#ffffff',
              },
              {
                text: 'Hình ảnh',
                bold: true,
                alignment: 'center',
                fillColor: '#2980BA',
                color: '#ffffff',
              },
              {
                text: 'Ghi chú',
                bold: true,
                alignment: 'center',
                fillColor: '#2980BA',
                color: '#ffffff',
              },
              {
                text: 'QR Code', // Cột QR
                bold: true,
                fillColor: '#2980BA',
                color: '#ffffff',
                alignment: 'center',
              },
            ],
            ...updatedDataTable?.map((product, index) => [
              { text: index + 1, alignment: 'center' },
              { text: product.categoryName },
              {
                text: product.Building.buildingCode,
                alignment: 'center',
              },
              {
                text: product.factoryCode,
                alignment: 'center',
              },
              { text: product.unit, alignment: 'center' },
              { text: product.quantity, alignment: 'center' },
              {
                text: FormatDate(product.intendFinishDate),
                alignment: 'center',
              },
              { text: product.teamWorking, alignment: 'center' },
              { text: product.status, alignment: 'center' },
              {
                image: product.categoryImage,
                width: 50,
                height: 50,
                fit: [50, 50],
                alignment: 'center',
              },
              { text: product.technicalNote },
              {
                image: product.qrCode,
                width: 60,
                height: 60,
                fit: [60, 60],
                alignment: 'center',
                margin: [0, 10, 0, 10],
              },
            ]),
          ],
        },
      },
    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        alignment: 'center',
      },
    },
    defaultStyle: {
      font: 'Roboto',
    },
  };

  const pdfDocGenerator = pdfMake.createPdf(docDefinition);

  if (action === 'print') {
    pdfDocGenerator.getBlob((blob) => {
      const url = URL.createObjectURL(blob);
      const newWindow = window.open(url, '_blank');
      if (newWindow) {
        newWindow.onload = () => {
          newWindow.print();
        };
      }
    });
  } else if (action === 'save') {
    pdfDocGenerator.download('construction.pdf');
  }
  // });
};
