import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";

export const statuses = [
    { label: "Chưa có BOM", value: "Chưa có BOM" },
    { label: "Chưa sản xuất", value: "Chưa sản xuất" },
    { label: "Đang sản xuất", value: "Đang sản xuất" },
    { label: "Hoàn thành đúng tiến độ", value: "Hoàn thành đúng tiến độ" },
    { label: "Hoàn thành trễ tiến độ", value: "Hoàn thành trễ tiến độ" },
    { label: "Trễ tiến độ", value: "Trễ tiến độ" },
];

export const statusFilterTemplate = (options) => {
    return (
        <MultiSelect
            value={options.value}
            options={statuses}
            onChange={(e) => options.filterApplyCallback(e.value)}
            placeholder="Chọn trạng thái"
            className="p-column-filter"
            showClear
            display="chip"
            style={{ minWidth: "12rem" }}
        />
    );
};
