import { call, put, takeEvery } from "redux-saga/effects";
import {
    getCategory,
    addNewCategory,
    updateCategory,
    deleteCategory,
} from "../../api/backend_helper";
import {
    getDataAll,
    getDataAllFail,
    getDataAllSuccess,
    addCategory,
    addCategoryFail,
    addCategorySuccess,
    updateCategory as updateCategoryAction,
    updateCategoryFail,
    updateCategorySuccess,
    deleteCategory as deleteCategoryAction,
    deleteCategoryFail,
    deleteCategorySuccess,
} from "./slice";

function* getCategoryData(action) {
    try {
        const query = action.payload;
        const data = yield call(getCategory, query);
        yield put(getDataAllSuccess(data));
    } catch (error) {
        yield put(getDataAllFail(error.message));
    }
}

function* createCategorySaga(action) {
    try {
        const data = yield call(addNewCategory, action.payload);
        yield put(addCategorySuccess(data));
    } catch (error) {
        yield put(addCategoryFail(error.message));
    }
}

function* modifyCategory(action) {
    try {
        const data = yield call(
            updateCategory,
            action.payload.id,
            action.payload
        );
        yield put(updateCategorySuccess(data));
    } catch (error) {
        yield put(updateCategoryFail(error.message));
    }
}

function* removeCategory(action) {
    try {
        yield call(deleteCategory, action.payload);
        yield put(deleteCategorySuccess(action.payload));
    } catch (error) {
        yield put(deleteCategoryFail(error.message));
    }
}

function* CategorySaga() {
    yield takeEvery("category/getDataAll", getCategoryData);
    yield takeEvery(addCategory.type, createCategorySaga);
    yield takeEvery(updateCategoryAction.type, modifyCategory);
    yield takeEvery(deleteCategoryAction.type, removeCategory);
}

export default CategorySaga;
