import { call, put, takeLatest } from 'redux-saga/effects';
import {
  downloadSampleFileFailure,
  downloadSampleFileSuccess,
  uploadPlanExcelFailure,
  uploadPlanExcelSuccess,
} from './slice';
import { downloadPlanExcel, uploadPlanExcel } from '../../api/backend_helper';

function* onDownloadSampleFile(action) {
  try {
    const response = yield call(downloadPlanExcel, action.payload);
    yield put(downloadSampleFileSuccess(response));
  } catch (error) {
    yield put(downloadSampleFileFailure(error));
  }
}

function* onUploadPlanExcel(action) {
  try {
    const { type, file } = action.payload;
    const formData = new FormData();
    formData.append('file', file);
    const response = yield call(uploadPlanExcel, type, formData);
    yield put(uploadPlanExcelSuccess(response));
  } catch (error) {
    yield put(uploadPlanExcelFailure(error));
  }
}

export default function* ExcelSaga() {
  yield takeLatest('excel/downloadSampleFileRequest', onDownloadSampleFile);
  yield takeLatest('excel/uploadPlanExcelRequest', onUploadPlanExcel);
}
