import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import * as Yup from "yup";
import { useFormik } from "formik";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Dialog } from "primereact/dialog";
import { addQr, getOrder, setDataNull } from "../../store/order/slice";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
import "../../assets/scss/theme-base/ModalProjectDelivery.css";
import { Dropdown } from "primereact/dropdown";

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const ModalProjectDelivery2 = ({ visible, onHide }) => {
    const dispatch = useDispatch();
    const [isShow, setIsShow] = useState(true);
    const query = useQuery();
    const factoryCode = query.get("factoryCode") || "";
    const categoryName = query.get("categoryName") || "";
    const quantity = query.get("quantity") || "";
    const unit = query.get("unit") || "";
    const toast = useRef(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isFactoryCodeSelected, setIsFactoryCodeSelected] = useState(false);
    const navigate = useNavigate();

    const { addQrData, errorQr, orderData } = useSelector(
        (state) => ({
            addQrData: state.Production.addQrData,
            errorQr: state.Production.errorQr,
            orderData: state.Production.orderData,
        }),
        shallowEqual
    );

    // Validation schema
    const validationSchema = Yup.object().shape({
        quantityMade: Yup.string().required("Số lượng thực hiện là bắt buộc"),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            factoryCode: factoryCode,
            categoryName: categoryName,
            quantity: quantity,
            unit: unit,
            quantityMade: "",
            deliveryNote: "",
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            const data = {
                factoryCode: values.factoryCode,
                quantityMade: values.quantityMade,
                deliveryNote: values.deliveryNote,
            };
            // dispatch(setDispatchingCompany(data, selectedFile));
            dispatch(addQr(data));
        },
    });

    useEffect(() => {
        if (isDropdownOpen) {
            dispatch(
                getOrder({
                    page: 1,
                    limit: orderData?.data?.products?.totalItems,
                    current: "plan",
                })
            );
        }
    }, [dispatch, isDropdownOpen, orderData?.data?.products?.totalItems]);

    const getFactoryCodeOptions = () => {
        if (
            !orderData ||
            !orderData.data ||
            !orderData.data.products ||
            !orderData.data.products.items
        )
            return [];
        return orderData.data.products.items.map((item) => ({
            label: item.factoryCode,
            value: item.factoryCode,
        }));
    };

    const getQuantityByFactoryCode = (factoryCode) => {
        if (
            !orderData ||
            !orderData.data ||
            !orderData.data.products ||
            !orderData.data.products.items
        )
            return "";
        const item = orderData.data.products.items.find(
            (item) => item.factoryCode === factoryCode
        );
        return item ? item.quantity : "";
    };

    const getCategoryNameByFactoryCode = (factoryCode) => {
        if (
            !orderData ||
            !orderData.data ||
            !orderData.data.products ||
            !orderData.data.products.items
        )
            return "";
        const item = orderData.data.products.items.find(
            (item) => item.factoryCode === factoryCode
        );
        return item ? item.categoryName : "";
    };

    const getUnitByFactoryCode = (factoryCode) => {
        if (
            !orderData ||
            !orderData.data ||
            !orderData.data.products ||
            !orderData.data.products.items
        )
            return "";
        const item = orderData.data.products.items.find(
            (item) => item.factoryCode === factoryCode
        );
        return item ? item.unit : "";
    };

    const getRemainingByFactoryCode = (factoryCode) => {
        if (
            !orderData ||
            !orderData.data ||
            !orderData.data.products ||
            !orderData.data.products.items
        ) {
            return "";
        }
        const item = orderData.data.products.items.find(
            (item) => item.factoryCode === factoryCode
        );

        return item.totalActualDelivery
            ? item.quantity - item.totalActualDelivery
            : item.quantity;
    };

    const handleFactoryCodeChange = (e) => {
        const selectedFactoryCode = e.value;
        formik.setFieldValue("factoryCode", selectedFactoryCode);
        const selectedQuantity = getQuantityByFactoryCode(selectedFactoryCode);
        formik.setFieldValue("quantity", selectedQuantity);
        const selectedCategoryName =
            getCategoryNameByFactoryCode(selectedFactoryCode);
        formik.setFieldValue("categoryName", selectedCategoryName);
        const selectedUnit = getUnitByFactoryCode(selectedFactoryCode);
        formik.setFieldValue("unit", selectedUnit);
        const remaining = getRemainingByFactoryCode(selectedFactoryCode);
        formik.setFieldValue("remaining", remaining);
        setIsFactoryCodeSelected(true);
    };

    useEffect(() => {
        if (errorQr) {
            toast.current?.show({
                severity: "error",
                summary: "Thất bại",
                detail: errorQr?.response?.data?.message,
                life: 3000,
            });
            dispatch(setDataNull());
        }
        if (addQrData) {
            toast.current?.show({
                severity: "success",
                summary: "Thành công",
                detail: "Giao thành công",
                life: 3000,
            });
            formik.resetForm();
            dispatch(setDataNull());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorQr, addQrData]);

    const handleDropdownOpen = () => {
        setIsDropdownOpen(true);
    };

    const handleClose = () => {
        setIsShow(false);
        navigate("/plan");
    };

    return (
        <>
            <Toast ref={toast} />
            <Dialog
                header="Giao công trình"
                visible={visible}
                className="responsive-dialog"
                style={{ width: "50vw" }}
                onHide={handleClose}
            >
                <div className="bg-white p-3 border-round-md">
                    <div className="flex flex-column mb-4 gap-3">
                        <div className="flex flex-column w-12 mb-3">
                            <label className="mb-2">Mã nhà máy</label>
                            <Dropdown
                                id="factoryCode"
                                name="factoryCode"
                                value={formik.values.factoryCode}
                                options={getFactoryCodeOptions()}
                                onChange={handleFactoryCodeChange}
                                onClick={handleDropdownOpen}
                                filter
                                style={{
                                    height: "40px",
                                    border: "1px solid #ddd",
                                }}
                                className={
                                    formik.touched.factoryCode &&
                                    formik.errors.factoryCode
                                        ? "p-invalid "
                                        : "border-300"
                                }
                            />
                            {formik.touched.factoryCode &&
                            formik.errors.factoryCode ? (
                                <div style={{ color: "red" }}>
                                    {formik.errors.factoryCode.toString()}
                                </div>
                            ) : null}
                        </div>
                        <div className="flex flex-column w-12 mb-3">
                            <label className="mb-2">Hạng mục</label>
                            <InputText
                                id="categoryName"
                                name="categoryName"
                                value={formik.values.categoryName}
                                onChange={formik.handleChange}
                                style={{ height: "40px" }}
                                className={
                                    formik.touched.categoryName &&
                                    formik.errors.categoryName
                                        ? "p-invalid"
                                        : ""
                                }
                                readOnly
                            />
                            {formik.touched.categoryName &&
                            formik.errors.categoryName ? (
                                <div style={{ color: "red" }}>
                                    {formik.errors.categoryName.toString()}
                                </div>
                            ) : null}
                        </div>
                        <div className="flex flex-column w-12 mb-3">
                            <label className="mb-2">Số lượng đơn hàng</label>
                            <InputText
                                id="quantity"
                                name="quantity"
                                value={formik.values.quantity}
                                onChange={formik.handleChange}
                                style={{ height: "40px" }}
                                className={
                                    formik.touched.quantity &&
                                    formik.errors.quantity
                                        ? "p-invalid"
                                        : ""
                                }
                                readOnly
                            />
                            {formik.touched.quantity &&
                            formik.errors.quantity ? (
                                <div style={{ color: "red" }}>
                                    {formik.errors.quantity.toString()}
                                </div>
                            ) : null}
                        </div>
                        <div className="flex flex-column w-12 mb-3">
                            <label className="mb-2">ĐVT</label>
                            <InputText
                                id="unit"
                                name="unit"
                                value={formik.values.unit}
                                onChange={formik.handleChange}
                                style={{ height: "40px" }}
                                className={
                                    formik.touched.unit && formik.errors.unit
                                        ? "p-invalid"
                                        : ""
                                }
                                readOnly
                            />
                            {formik.touched.unit && formik.errors.unit ? (
                                <div style={{ color: "red" }}>
                                    {formik.errors.unit.toString()}
                                </div>
                            ) : null}
                        </div>

                        <div className="flex flex-column w-12 mb-3">
                            <label className="mb-2">Số lượng giao</label>
                            <InputText
                                id="quantityMade"
                                name="quantityMade"
                                value={formik.values.quantityMade}
                                onChange={formik.handleChange}
                                style={{ height: "40px" }}
                                className={
                                    formik.touched.quantityMade &&
                                    formik.errors.quantityMade
                                        ? "p-invalid"
                                        : ""
                                }
                            />
                            {formik.touched.quantityMade &&
                            formik.errors.quantityMade ? (
                                <div style={{ color: "red" }}>
                                    {formik.errors.quantityMade.toString()}
                                </div>
                            ) : null}
                        </div>
                        <div className="flex flex-column w-12 mb-3">
                            <label className="mb-2">Số lượng còn lại</label>
                            <InputText
                                id="remaining"
                                name="remaining"
                                value={formik.values.remaining}
                                onChange={formik.handleChange}
                                style={{ height: "40px" }}
                                className={
                                    formik.touched.remaining &&
                                    formik.errors.remaining
                                        ? "p-invalid"
                                        : ""
                                }
                                readOnly
                            />
                            {formik.touched.remaining &&
                            formik.errors.remaining ? (
                                <div style={{ color: "red" }}>
                                    {formik.errors.remaining.toString()}
                                </div>
                            ) : null}
                        </div>
                        <div className="flex flex-column w-12 mb-3">
                            <label className="mb-2">Ghi chú</label>
                            <InputText
                                id="deliveryNote"
                                name="deliveryNote"
                                value={formik.values.deliveryNote}
                                onChange={formik.handleChange}
                                style={{ height: "40px" }}
                                className={
                                    formik.touched.deliveryNote &&
                                    formik.errors.deliveryNote
                                        ? "p-invalid"
                                        : ""
                                }
                            />
                            {formik.touched.deliveryNote &&
                            formik.errors.deliveryNote ? (
                                <div style={{ color: "red" }}>
                                    {formik.errors.deliveryNote.toString()}
                                </div>
                            ) : null}
                        </div>
                        <div className="flex mb-3">
                            <Button
                                label="Giao công trình"
                                onClick={formik.handleSubmit}
                                type="submit"
                            />
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    );
};

export default ModalProjectDelivery2;
