import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { getAuth, logout } from '../store/auth/slice';

const Authmiddleware = (props) => {
  const navigate = useNavigate();
  const token = localStorage.getItem('accessToken');
  const dispatch = useDispatch();
  const { auth } = useSelector(
    (state) => ({
      auth: state.Auth.authen.data,
      
    }),
    shallowEqual
  );

  useEffect(() => {
    const getAuthentication = async () => {
      try {
        if (!token) {
          navigate('/');
        }
      } catch (error) {
        if (token) {
          dispatch(getAuth());
        }
      }
    };

    getAuthentication();
  }, [token, dispatch, navigate]);

  useEffect(() => {
    if (auth && !auth.success) {
      dispatch(logout());
    }
  }, [auth, dispatch, navigate]);

  return <React.Fragment>{props.children}</React.Fragment>;
};

export default Authmiddleware;
