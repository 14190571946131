import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    dashboardTechnicalData: null,
    dashboardTechnicalDetailData: null,
    error: null,
    loading: false,
    allData: [],
    currentPage: 1,
};

const dashboardTechnicalSlice = createSlice({
    name: "dashboardTechnical",
    initialState,
    reducers: {
        getDashboardTechnical: (state) => {
            state.loading = true;
        },
        getDashboardTechnicalSuccess: (state, action) => {
            state.loading = false;
            state.dashboardTechnicalData = action.payload;
            state.error = null;
        },
        getDashboardTechnicalFail: (state, action) => {
            state.loading = false;
            state.dashboardTechnicalData = null;
            state.error = action.payload;
        },
        getDashboardTechnicalDetail: (state) => {
            state.loading = true;
        },
        getDashboardTechnicalDetailSuccess: (state, action) => {
            state.loading = false;
            state.dashboardTechnicalDetailData = action.payload;
            state.error = null;
        },
        getDashboardTechnicalDetailFail: (state, action) => {
            state.loading = false;
            state.dashboardTechnicalDetailData = null;
            state.error = action.payload;
        },
        getAllDashboardTechnicalDetail: (state) => {
            state.loading = true;
        },
        getAllDashboardTechnicalDetailSuccess: (state, action) => {
            state.loading = false;
            state.allData = action.payload;

            if (state.currentPage < action.payload.data.products.totalPages) {
                state.currentPage += 1;
            }
            state.error = null;
        },
        getAllDashboardTechnicalDetailFail: (state, action) => {
            state.loading = false;
            state.allData = null;
            state.error = action.payload;
        },
        resetPaginationTechnical: (state) => {
            state.allData = [];
            state.currentPage = 1;
            state.error = null;
        },
    },
});

export const {
    getDashboardTechnical,
    getDashboardTechnicalSuccess,
    getDashboardTechnicalFail,
    getDashboardTechnicalDetail,
    getDashboardTechnicalDetailFail,
    getDashboardTechnicalDetailSuccess,
    getAllDashboardTechnicalDetailFail,
    getAllDashboardTechnicalDetail,
    getAllDashboardTechnicalDetailSuccess,
    resetPaginationTechnical,
} = dashboardTechnicalSlice.actions;

export default dashboardTechnicalSlice.reducer;
