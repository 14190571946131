import React, { useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import "../../assets/scss/theme-base/ModelAddConstruction.css";
import { Paginator } from "primereact/paginator";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import Intersection from "../Plan/intersection";
import FormatDate from "../Report/FormatDate";
import { DataTable } from "primereact/datatable";
import { Image } from "primereact/image";
import { statusFilterTemplate } from "../../constants/status";
import {
    formatNumber,
    QuantityTemplate,
    statusTemplate,
} from "../../components/utils";
import ModalAddPrice from "./ModalAddPrice";
import ActualComplete from "./ActualComplete";
import ModalAddComplete from "./ModalAddComplete";
import ModalAddPlan from "./ModalAddPlan";

const defaultImageUrl = "https://static.thenounproject.com/png/1269202-200.png";

export const imageTemplate = (rowData) => {
    return (
        <Image
            src={`${rowData?.categoryImage}` || defaultImageUrl}
            alt="Category Image"
            imageStyle={{ borderRadius: "20%", width: "40px", height: "40px" }}
            preview
            onError={(e) => (e.target.src = defaultImageUrl)}
        />
    );
};
export const teamImageTemplate = (rowData) => {
    return rowData.teamImage ? (
        <Image
            src={`${rowData?.teamImage}` || defaultImageUrl}
            alt="Category Image"
            imageStyle={{ borderRadius: "20%", width: "40px", height: "40px" }}
            preview
            onError={(e) => (e.target.src = defaultImageUrl)}
        />
    ) : (
        <></>
    );
};

const ModalFilterCategoryQuantity = ({ visible, onHide, data }) => {
    const [dialogLocationVisible, setDialogLocationVisible] = useState(false);
    const [selectFactoryCode, setSelectFactoryCode] = useState(null);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [selectedProductId, setSelectedProductId] = useState(null);
    const [selectedProductPrice, setSelectedProductPrice] = useState(null);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [visible1, setVisible1] = useState(false);
    const [visible2, setVisible2] = useState(false);
    const [visible3, setVisible3] = useState(false);
    const [visible4, setVisible4] = useState(false);

    const listPermissions =
        JSON.parse(localStorage.getItem("userPermissions")) || {};

    const handleTeamProgress = (rowData) => {
        setSelectFactoryCode(rowData.factoryCode);
        setDialogLocationVisible(true);
    };
    const onPageChange = (event) => {
        const newFirst = event.first;
        const newRows = event.rows;

        setFirst(newFirst);
        setRows(newRows);
    };
    const priceTemplate = (rowData) => {
        return formatNumber(rowData.price);
    };
    const paginatedData = data?.slice(first, first + rows);
    const width = window.innerWidth;

    const handleRowSelect = (rowData) => {
        setSelectedProductId(rowData.id);
        setSelectedProductPrice(rowData.price);
        setVisible1(true);
    };
    const handleRowSelect2 = (rowData) => {
        setSelectedProductId(rowData.id);
        setSelectedProductPrice(rowData.price);
        setVisible2(true);
    };
    const handleRowSelect4 = (rowData) => {
        setSelectedProductId(rowData.id);
        setSelectedProductPrice(rowData.price);
        setVisible4(true);
    };

    return (
        <Dialog
            visible={visible}
            onHide={() => {
                setFirst(0);
                onHide();
            }}
            className="responsive-dialog"
            header="Lọc theo hạng mục"
            modal
            style={{ width: "80vw", height: "80vh" }}
        >
            <div className="p-3 border-round-md">
                <div>
                    <DataTable
                        rowClassName="custom-row-class-factory-inprogress"
                        size="small"
                        value={paginatedData}
                        emptyMessage="Không có dữ liệu"
                        scrollable
                        scrollHeight="550px"
                    >
                        <Column
                            header="STT"
                            body={(rowData, options) => (
                                <div className="text-center">
                                    {first + options.rowIndex + 1}
                                </div>
                            )}
                            frozen
                            style={{
                                width: "3%",
                                minWidth: "60px",
                                height: "40px",
                            }}
                            alignHeader="center"
                            align="center"
                        />
                        <Column
                            header="Tên công trình"
                            field="Building.name"
                            style={{ minWidth: "200px" }}
                            filter
                            frozen
                            filterPlaceholder="Tìm kiếm mã công trình"
                        />
                        <Column
                            header="Hạng mục"
                            field="categoryName"
                            style={{ minWidth: "150px" }}
                            filter
                            frozen={width > 768}
                            filterPlaceholder="Tìm kiếm theo hạng mục"
                        />
                        <Column
                            header="Hình ảnh"
                            body={imageTemplate}
                            style={{ minWidth: "100px" }}
                            align="center"
                            frozen={width > 768}
                        />
                        <Column
                            header="Mã SP"
                            field="productCode"
                            style={{ minWidth: "100px" }}
                            frozen
                            filter
                            filterPlaceholder="Tìm kiếm mã sản phẩm"
                        />
                        <Column
                            header="Mã nhà máy"
                            field="factoryCode"
                            filterPlaceholder="Tìm kiếm mã nhà máy"
                            style={{ minWidth: "120px" }}
                            frozen
                            filter
                        />
                        <Column
                            header="Số lượng"
                            field="quantity"
                            body={QuantityTemplate}
                            style={{ minWidth: "90px" }}
                        />
                        <Column
                            header="ĐVT"
                            field="unit"
                            style={{ minWidth: "50px" }}
                            filter
                            filterPlaceholder="Tìm kiếm đơn vị tính"
                        />

                        <Column
                            header="Trạng thái"
                            field="status"
                            body={statusTemplate}
                            style={{ minWidth: "150px" }}
                            filter
                            filterElement={statusFilterTemplate}
                            pt={{
                                filterButtonbar: {
                                    className: "hidden",
                                },
                            }}
                            alignHeader="center"
                            align="center"
                        />
                        <Column
                            header="Đơn giá"
                            field="price"
                            style={{ minWidth: "100px" }}
                            body={priceTemplate}
                            filter
                            filterPlaceholder="Tìm kiếm đơn giá"
                        />
                        <Column
                            header="Sản lượng kế hoạch"
                            body={(rowData) => (
                                <div className="text-center">
                                    <i
                                        className="pi pi-calendar cursor-pointer"
                                        onClick={() => handleRowSelect(rowData)}
                                    ></i>
                                </div>
                            )}
                            field="keHoach"
                            style={{ minWidth: "150px" }}
                            alignHeader="center"
                            align="center"
                        />
                        <Column
                            header="Sản lượng hoàn thành"
                            body={(rowData) => (
                                <div className="text-center py-2">
                                    <i
                                        className="pi pi-calendar cursor-pointer"
                                        onClick={() =>
                                            handleRowSelect2(rowData)
                                        }
                                    ></i>
                                </div>
                            )}
                            field="keHoach"
                            style={{ minWidth: "160px" }}
                            alignHeader="center"
                            align="center"
                        />
                        <Column
                            header="Sản lượng thực tế"
                            body={(rowData) => (
                                <div className="text-center py-2">
                                    <i
                                        className="pi pi-calendar cursor-pointer"
                                        onClick={() =>
                                            handleRowSelect4(rowData)
                                        }
                                    ></i>
                                </div>
                            )}
                            field="keHoach"
                            style={{ minWidth: "160px" }}
                            alignHeader="center"
                            align="center"
                        />
                        <Column
                            header="Ghi chú"
                            field="priceNote"
                            style={{ minWidth: "150px" }}
                        />
                        <Column
                            header="Định vị"
                            body={(rowData) => (
                                <div className="text-center">
                                    <i
                                        onClick={() =>
                                            handleTeamProgress(rowData)
                                        }
                                        className="pi pi-map-marker cursor-pointer"
                                        style={{ color: "black" }}
                                    ></i>
                                    <p>{rowData.teamWorking}</p>
                                </div>
                            )}
                            style={{ minWidth: "150px" }}
                            alignHeader="center"
                            align="center"
                        />
                    </DataTable>
                    <Paginator
                        first={first}
                        rows={rows}
                        totalRecords={data?.length}
                        rowsPerPageOptions={[10, 20, 30, 100, 200, 500]}
                        onPageChange={onPageChange}
                    />
                    <ModalAddPlan
                        price={selectedProductPrice}
                        isShow={visible1}
                        productId={selectedProductId}
                        hiden={() => setVisible1(false)}
                    />
                    <ModalAddComplete
                        price={selectedProductPrice}
                        productId={selectedProductId}
                        isShow={visible2}
                        hiden={() => setVisible2(false)}
                    />
                    <Intersection
                        visible={dialogLocationVisible}
                        onHide={() => setDialogLocationVisible(false)}
                        factoryCode={selectFactoryCode}
                    />
                    <ActualComplete
                        isShow={visible4}
                        price={selectedProductPrice}
                        productId={selectedProductId}
                        hiden={() => setVisible4(false)}
                    />
                    <ModalAddPrice
                        isShow={visible3}
                        rowData={selectedRowData}
                        hiden={() => setVisible3(false)}
                    />
                </div>
            </div>
        </Dialog>
    );
};

export default ModalFilterCategoryQuantity;
