import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  permissionData: null,
  error: null,
  loading: false,
  allPermissions: null,
  allPermissionsError: null,
  groupPermissionInsert: null,
  groupPermissionError: null,
  groupPermissionUpdate: null,
  groupPermissionUpdateError: null,
  deleteGroupPermissionData: null,
  deleteGroupPermissionError: null,
  dataPermissionByGroupId: null,
  errorPermissionByGroupId: null,
  dataInsertPermissionToGroup: null,
  errorInsertPermissionToGroup: null,
  permissionBuilding: {
    data: null,
    error: null,
    loading: false,
  },
  resultSetBuidingPermission: {
    data: null,
    error: null,
    loading: false,
  },
};

const permissionSlice = createSlice({
  name: 'permission',
  initialState,
  reducers: {
    getPermission: (state) => {
      state.loading = true;
    },
    getPermissionSuccess: (state, action) => {
      state.loading = false;
      state.permissionData = action.payload;
      state.error = null;
    },
    getPermissionFail: (state, action) => {
      state.loading = false;
      state.permissionData = [];
      state.error = action.payload;
    },
    getAllPermissions: (state) => {
      state.loading = true;
    },
    getAllPermissionsSuccess: (state, action) => {
      state.loading = false;
      state.allPermissions = action.payload;
      state.allPermissionsError = null;
    },
    getAllPermissionsFail: (state, action) => {
      state.loading = false;
      state.allPermissions = [];
      state.allPermissionsError = action.payload;
    },
    addGroupPermission: (state) => {
      state.loading = true;
    },
    addGroupPermissionSuccess: (state, action) => {
      state.loading = false;
      state.groupPermissionInsert = action.payload;
      state.groupPermissionError = null;
    },
    addGroupPermissionFail: (state, action) => {
      state.loading = false;
      state.groupPermissionInsert = null;
      state.groupPermissionError = action.payload;
    },
    updateGroupPermission: (state) => {
      state.loading = true;
    },
    updateGroupPermissionSuccess: (state, action) => {
      state.loading = false;
      state.groupPermissionUpdate = action.payload;
      state.groupPermissionUpdateError = null;
    },
    updateGroupPermissionFail: (state, action) => {
      state.loading = false;
      state.groupPermissionUpdate = null;
      state.groupPermissionUpdateError = action.payload;
    },
    deleteGroupPermission: (state) => {
      state.loading = true;
    },
    deleteGroupPermissionSuccess: (state, action) => {
      state.loading = false;
      state.deleteGroupPermissionData = action.payload;
      state.deleteGroupPermissionError = null;
    },
    deleteGroupPermissionFail: (state, action) => {
      state.loading = false;
      state.deleteGroupPermissionData = null;
      state.deleteGroupPermissionError = action.payload;
    },
    getPermissionByGroupId: (state) => {
      state.loading = true;
    },
    getPermissionByGroupIdSuccess: (state, action) => {
      state.loading = false;
      state.dataPermissionByGroupId = action.payload;
      state.errorPermissionByGroupId = null;
    },
    getPermissionByGroupIdFail: (state, action) => {
      state.loading = false;
      state.dataPermissionByGroupId = null;
      state.errorPermissionByGroupId = action.payload;
    },
    setPermissionToGroup: (state) => {
      state.loading = true;
    },
    setPermissionToGroupSuccess: (state, action) => {
      state.loading = false;
      state.dataInsertPermissionToGroup = action.payload;
      state.errorInsertPermissionToGroup = null;
    },
    setPermissionToGroupFail: (state, action) => {
      state.loading = false;
      state.dataInsertPermissionToGroup = null;
      state.errorInsertPermissionToGroup = action.payload;
    },
    getPermissionBuildingRequest: (state) => {
      state.permissionBuilding.loading = true;
    },
    getPermissionBuildingSuccess: (state, action) => {
      state.permissionBuilding.loading = false;
      state.permissionBuilding.data = action.payload;
    },
    getPermissionBuildingFail: (state, action) => {
      state.permissionBuilding.loading = false;
      state.permissionBuilding.error = action.payload;
    },
    setBuildingPermissionRequest: (state) => {
      state.resultSetBuidingPermission.loading = true;
    },
    setBuildingPermissionSuccess: (state, action) => {
      state.resultSetBuidingPermission.loading = false;
      state.resultSetBuidingPermission.data = action.payload;
    },
    setBuildingPermissionFail: (state, action) => {
      state.resultSetBuidingPermission.loading = false;
      state.resultSetBuidingPermission.error = action.payload;
    },
    clearPermission: (state) => {
      state.resultSetBuidingPermission.data = null;
      state.resultSetBuidingPermission.error = null;
    },
    setPermissionNull: (state) => {
      state.groupPermissionInsert = null;
      state.groupPermissionError = null;
      state.deleteGroupPermissionData = null;
      state.deleteGroupPermissionError = null;
    },
  },
});

export const {
  getPermission,
  getPermissionSuccess,
  getPermissionFail,
  getAllPermissions,
  getAllPermissionsSuccess,
  getAllPermissionsFail,
  addGroupPermission,
  addGroupPermissionSuccess,
  addGroupPermissionFail,
  updateGroupPermission,
  updateGroupPermissionSuccess,
  updateGroupPermissionFail,
  setPermissionNull,
  deleteGroupPermission,
  deleteGroupPermissionSuccess,
  deleteGroupPermissionFail,
  getPermissionByGroupId,
  getPermissionByGroupIdSuccess,
  getPermissionByGroupIdFail,
  setPermissionToGroup,
  setPermissionToGroupSuccess,
  setPermissionToGroupFail,
  getPermissionBuildingRequest,
  getPermissionBuildingSuccess,
  getPermissionBuildingFail,
  setBuildingPermissionRequest,
  setBuildingPermissionFail,
  setBuildingPermissionSuccess,
  clearPermission,
} = permissionSlice.actions;
export default permissionSlice.reducer;
