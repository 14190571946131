import React, { useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import "../../assets/scss/theme-base/ModelAddConstruction.css";
import { Paginator } from "primereact/paginator";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import Intersection from "../Plan/intersection";
import { DataTable } from "primereact/datatable";
import { Image } from "primereact/image";
import { statusFilterTemplate } from "../../constants/status";
import { statusTemplate } from "../../components/utils";
import FormatDate from "../Report/FormatDate";

const defaultImageUrl = "https://static.thenounproject.com/png/1269202-200.png";

function formatQuantity(quantity) {
    if (quantity !== undefined && quantity !== null) {
        let formatted = quantity.toString();

        formatted = formatted.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return formatted;
    } else {
        return 0;
    }
}

export const imageTemplate = (rowData) => {
    return (
        <Image
            src={`${rowData?.categoryImage}` || defaultImageUrl}
            alt="Category Image"
            imageStyle={{ borderRadius: "20%", width: "40px", height: "40px" }}
            preview
            onError={(e) => (e.target.src = defaultImageUrl)}
        />
    );
};
export const teamImageTemplate = (rowData) => {
    return rowData.teamImage ? (
        <Image
            src={`${rowData?.teamImage}` || defaultImageUrl}
            alt="Category Image"
            imageStyle={{ borderRadius: "20%", width: "40px", height: "40px" }}
            preview
            onError={(e) => (e.target.src = defaultImageUrl)}
        />
    ) : (
        <></>
    );
};

const ModalFilterCategoryReportQuantity = ({ visible, onHide, data }) => {
    const [dialogLocationVisible, setDialogLocationVisible] = useState(false);
    const [selectFactoryCode, setSelectFactoryCode] = useState(null);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [selectedProducts, setSelectedProducts] = useState(null);

    console.log("data", data);

    const handleTeamProgress = (rowData) => {
        setSelectFactoryCode(rowData.factoryCode);
        setDialogLocationVisible(true);
    };
    const onPageChange = (event) => {
        const newFirst = event.first;
        const newRows = event.rows;

        setFirst(newFirst);
        setRows(newRows);
    };

    const paginatedData = data?.slice(first, first + rows);

    return (
        <Dialog
            visible={visible}
            onHide={() => {
                setFirst(0);
                onHide();
            }}
            className="responsive-dialog"
            header="Lọc theo hạng mục"
            modal
            style={{ width: "80vw", height: "80vh" }}
        >
            <div className="p-3 border-round-md">
                <div>
                    <DataTable
                        rowClassName="custom-row-class-factory-inprogress"
                        size="small"
                        value={paginatedData}
                        emptyMessage="Không có dữ liệu"
                        scrollable
                        scrollHeight="550px"
                    >
                        <Column
                            header="STT"
                            body={(rowData, options) => (
                                <div className="text-center">
                                    {first + options.rowIndex + 1}
                                </div>
                            )}
                            style={{ width: "3%" }}
                            alignHeader={"center"}
                            align={"center"}
                        ></Column>
                        <Column
                            header="Tên công trình"
                            field="Building.name"
                            style={{ minWidth: "150px" }}
                            filter
                        ></Column>
                        <Column
                            header="Mã ĐH"
                            style={{ minWidth: "100px" }}
                            field="orderCode"
                            filter
                        ></Column>
                        <Column
                            header="Mã nhà máy"
                            field="factoryCode"
                            style={{ minWidth: "130px" }}
                            filter
                        ></Column>
                        <Column
                            header="Hạng mục"
                            style={{
                                lineHeight: "1.5",
                                minWidth: "200px",
                            }}
                            field="categoryName"
                            filter
                        ></Column>
                        <Column
                            header="Hình ảnh"
                            style={{ minWidth: "100px" }}
                            body={imageTemplate}
                            alignHeader={"center"}
                            align={"center"}
                        ></Column>
                        <Column
                            header="SL"
                            field="quantity"
                            style={{ minWidth: "70px" }}
                        ></Column>
                        <Column
                            header="ĐVT"
                            field="unit"
                            style={{ minWidth: "50px" }}
                            filter
                        ></Column>
                        <Column
                            header="Mã sản phẩm"
                            field="productCode"
                            style={{ minWidth: "130px" }}
                            filter
                        ></Column>

                        <Column
                            header="Kế hoạch hoàn thành"
                            field="intendFinishDate"
                            style={{ minWidth: "120px" }}
                            body={(rowData) => (
                                <span>
                                    {FormatDate(rowData.intendFinishDate)}
                                </span>
                            )}
                            sortable
                            align="center"
                            alignHeader="center"
                        ></Column>
                        <Column
                            header="Trạng thái"
                            field="status"
                            body={statusTemplate}
                            alignHeader={"center"}
                            align={"center"}
                            style={{ minWidth: "100px" }}
                            filter
                            filterElement={statusFilterTemplate}
                            pt={{
                                filterButtonbar: { className: "hidden" },
                            }}
                        ></Column>
                        <Column
                            header="Định vị"
                            body={(rowData) => (
                                <div className="text-center">
                                    <Button
                                        onClick={() =>
                                            handleTeamProgress(rowData)
                                        }
                                        icon="pi pi-map-marker"
                                        rounded
                                        text
                                        severity="secondary"
                                        aria-label="Bookmark"
                                        style={{ color: "black" }}
                                    />
                                    <p>{rowData.teamWorking}</p>
                                </div>
                            )}
                            alignHeader={"center"}
                            align={"center"}
                            style={{ minWidth: "100px" }}
                        ></Column>
                        <Column
                            header="Số lượng kế hoạch"
                            field="totalProductionPlan"
                            body={(rowData) => (
                                <div>
                                    {formatQuantity(
                                        rowData.totalProductionPlan
                                    )}
                                </div>
                            )}
                            style={{ minWidth: "120px" }}
                            alignHeader={"right"}
                            align={"right"}
                            footer={
                                <div>
                                    {formatQuantity(
                                        data?.reduce(
                                            (sum, item) =>
                                                sum +
                                                (item.totalProductionPlan || 0),
                                            0
                                        )
                                    )}
                                </div>
                            }
                        ></Column>
                        <Column
                            header="Sản lượng kế hoạch"
                            alignHeader={"right"}
                            style={{ minWidth: "200px" }}
                            align={"right"}
                            body={(rowData) => (
                                <div>
                                    {(
                                        (rowData?.totalProductionPlan || 0) *
                                        (rowData?.price || 0)
                                    )?.toLocaleString("vi-VN")}
                                </div>
                            )}
                            footer={
                                <div>
                                    {data
                                        ?.reduce(
                                            (sum, item) =>
                                                sum +
                                                (item.totalProductionPlan ||
                                                    0) *
                                                    (item.price || 0),
                                            0
                                        )
                                        ?.toLocaleString("vi-VN")}
                                </div>
                            }
                        ></Column>
                        <Column
                            header="Chưa thực hiện"
                            field="notImplement"
                            body={(rowData) => (
                                <div>
                                    {(
                                        (rowData?.TempProduct?.notImplemented ||
                                            0) * (rowData?.price || 0)
                                    )?.toLocaleString("vi-VN")}
                                </div>
                            )}
                            alignHeader={"right"}
                            align={"right"}
                            style={{ minWidth: "200px" }}
                            footer={
                                <div>
                                    {data
                                        ?.reduce(
                                            (sum, item) =>
                                                sum +
                                                (item?.TempProduct
                                                    ?.notImplemented || 0) *
                                                    (item?.price || 0),
                                            0
                                        )
                                        ?.toLocaleString("vi-VN")}
                                </div>
                            }
                        ></Column>
                        <Column
                            header="Đang thực hiện"
                            field="TempProduct.working"
                            body={(rowData) => (
                                <div>
                                    {(
                                        (rowData?.TempProduct?.working || 0) *
                                        (rowData?.price || 0)
                                    )?.toLocaleString("vi-VN")}
                                </div>
                            )}
                            alignHeader={"right"}
                            align={"right"}
                            style={{ minWidth: "200px" }}
                            footer={
                                <div>
                                    {data
                                        ?.reduce(
                                            (sum, item) =>
                                                sum +
                                                (item?.TempProduct?.working ||
                                                    0) *
                                                    (item?.price || 0),
                                            0
                                        )
                                        ?.toLocaleString("vi-VN")}
                                </div>
                            }
                        ></Column>
                        <Column
                            header="Hoàn thành"
                            field="TempProduct.complete"
                            body={(rowData) => (
                                <div style={{ paddingRight: "10px" }}>
                                    {(
                                        (rowData?.TempProduct?.complete || 0) *
                                        (rowData?.price || 0)
                                    )?.toLocaleString("vi-VN")}
                                </div>
                            )}
                            alignHeader={"right"}
                            align={"right"}
                            style={{
                                minWidth: "200px",
                                paddingRight: "10px",
                            }}
                            footer={
                                <div>
                                    {data
                                        ?.reduce(
                                            (sum, item) =>
                                                sum +
                                                (item?.TempProduct?.complete ||
                                                    0) *
                                                    (item?.price || 0),
                                            0
                                        )
                                        ?.toLocaleString("vi-VN")}
                                </div>
                            }
                        ></Column>
                    </DataTable>
                    <Paginator
                        first={first}
                        rows={rows}
                        totalRecords={data?.length}
                        rowsPerPageOptions={[10, 20, 30, 100, 200, 500]}
                        onPageChange={onPageChange}
                    />
                    <Intersection
                        visible={dialogLocationVisible}
                        onHide={() => setDialogLocationVisible(false)}
                        factoryCode={selectFactoryCode}
                    />
                </div>
            </div>
        </Dialog>
    );
};

export default ModalFilterCategoryReportQuantity;
