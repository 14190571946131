import React, { useState, useEffect, useContext, useRef } from "react";
import "./ReportPlan.scss";
import HeaderReport from "../../layout/HeaderReport/HeaderReport";
import { TabMenu } from "primereact/tabmenu";
import tabMenuTemplate from "../../assets/templete/tabMenuTemplete";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { statusTemplate } from "../../components/utils";
import FormatDate from "../Report/FormatDate";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
    getDashboardPlan,
    getAllDashboardPlan,
} from "../../store/dashboardPlan/slice";
import { site } from "../../api/url_helper";
import LoadingSpinner from "../Report/LoadingSpinner";
import "../../assets/scss/theme-base/dashboard.css";
import { Button } from "primereact/button";
import Intersection from "../Plan/intersection";
import { exportPlanPDF } from "./ExportPlanPDF";
import { imageTemplate } from "../Report/ProductInprogress";
import { DataContext } from "../../contexts/data/DataProvider";
import { FilterMatchMode } from "primereact/api";
import { Toast } from "primereact/toast";
import { Paginator } from "primereact/paginator";
import {
    downloadExcelQuantity,
    setExcelDataQuantityNull,
} from "../../store/dashboardQuantity/slice";
import { formatDateApi } from "../../feature/formatDateApi";
import { statusFilterTemplate } from "../../constants/status";
import ModalFilterCategoryReportPlan from "./ModalFilterCategoryReportPlan";
import QR from "../Plan/QR";
import CustomMultiSelect from "../../constants/CustomMultiSelect";
import { resetPaginationPlan } from "../../store/dashboardPlan/slice";
import { useLocation } from "react-router-dom";
import axios from "axios";

export default function ReportPlan() {
    const dispatch = useDispatch();
    const location = useLocation();

    const toast = useRef(null);
    const downloadLink = useRef(null);
    const [tabMenuActive, setTabmenuActive] = useState(0);
    const [dataTable, setDataTable] = useState([]);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [building, setBuilding] = useState(null);
    const [orderCode, setOrderCode] = useState("");
    const [category, setCategory] = useState("");
    const [factoryCode, setFactoryCode] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [dialogLocationVisible, setDialogLocationVisible] = useState(false);
    const [selectFactoryCode, setSelectFactoryCode] = useState(null);
    const [nameBuilding, setNameBuilding] = useState("");
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [qrDetail, setQrDetail] = useState(null);
    const [visible3, setVisible3] = useState(false);
    const [selectedStatuses, setSelectedStatuses] = useState([]);
    const [allData, setAllData] = useState([]);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [page, setPage] = useState(1);
    const token = localStorage.getItem("accessToken");

    useEffect(() => {
        dispatch(resetPaginationPlan());
    }, [location.pathname, dispatch]);

    const { currentPage, allDataPlan } = useSelector(
        (state) => ({
            currentPage: state.DashboardPlan.currentPage,
            allDataPlan: state.DashboardPlan.allData,
        }),
        shallowEqual
    );

    useEffect(() => {
        if (
            building ||
            orderCode ||
            category ||
            factoryCode ||
            startDate ||
            endDate ||
            selectedStatuses
        ) {
            setAllData([]);
        }
    }, [
        building,
        orderCode,
        category,
        factoryCode,
        startDate,
        endDate,
        selectedStatuses,
    ]);

    useEffect(() => {
        if (allDataPlan?.data?.result?.items) {
            setAllData((prevData) => {
                const existingIds = new Set(prevData.map((item) => item.id));

                const newItems = allDataPlan.data.result.items.filter(
                    (item) => !existingIds.has(item.id)
                );

                return [...prevData, ...newItems];
            });
        }
    }, [
        allDataPlan,
        currentPage,
        startDate,
        endDate,
        building,
        orderCode,
        category,
        factoryCode,
        selectedStatuses,
    ]);

    useEffect(() => {
        const isLoaded =
            allData.length === allDataPlan?.data?.result?.totalItems;

        if (isLoaded) {
            setIsDataLoaded(true);
        } else {
            setIsDataLoaded(false);
        }
    }, [allDataPlan, allData]);

    const handleStatusChange = (newStatuses) => {
        setSelectedStatuses(newStatuses);
    };

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        "Building.buildingCode": {
            value: null,
            matchMode: FilterMatchMode.CONTAINS,
        },
        orderCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
        categoryName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        factoryCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
        unit: { value: null, matchMode: FilterMatchMode.CONTAINS },
        productCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
        quantity: { value: null, matchMode: FilterMatchMode.CONTAINS },
        intendFinishDate: { value: null, matchMode: FilterMatchMode.DATE_IS },
        status: { value: [], matchMode: FilterMatchMode.IN },
    });

    const { buildingName } = useContext(DataContext);
    useEffect(() => {
        if (buildingName) setNameBuilding(buildingName);
    }, [buildingName]);

    //format số lượng
    function formatQuantity(quantity) {
        if (quantity !== undefined && quantity !== null) {
            let formatted = quantity.toString();

            formatted = formatted.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return formatted;
        } else {
            return 0;
        }
    }

    const handleTeamProgress = (rowData) => {
        setSelectFactoryCode(rowData.factoryCode);
        setDialogLocationVisible(true);
        setSelectedRow(rowData);
    };

    const DashboardPlanData = useSelector(
        (state) => state.DashboardPlan.dashboardPlanData,
        shallowEqual
    );

    // useEffect(() => {
    //     let status = "Tất cả";
    //     if (DashboardPlanData) {
    //         status = DashboardPlanData.data.listStatus[tabMenuActive]?.label;
    //     }
    //     if (startDate && endDate) {
    //         dispatch(
    //             getAllDashboardPlan({
    //                 page: currentPage,
    //                 limit: 100,
    //                 status,
    //                 timeStartReq: startDate,
    //                 timeEndReq: endDate,
    //                 building,
    //                 orderCode,
    //                 category,
    //                 factoryCode,
    //                 filterStatus: JSON.stringify(selectedStatuses),
    //             })
    //         );
    //     }
    // }, [
    //     dispatch,
    //     currentPage,
    //     building,
    //     orderCode,
    //     category,
    //     factoryCode,
    //     startDate,
    //     endDate,
    //     tabMenuActive,
    //     selectedStatuses,
    // ]);

    useEffect(() => {
        let status = "Tất cả";
        if (DashboardPlanData) {
            status = DashboardPlanData.data.listStatus[tabMenuActive]?.label;
        }
        if (startDate && endDate) {
            dispatch(
                getDashboardPlan({
                    page: (first + rows) / rows,
                    limit: rows,
                    status,
                    timeStartReq: startDate,
                    timeEndReq: endDate,
                    building,
                    orderCode,
                    category,
                    factoryCode,
                    filterStatus: JSON.stringify(selectedStatuses),
                })
            );
        }
    }, [
        dispatch,
        building,
        orderCode,
        category,
        factoryCode,
        startDate,
        endDate,
        tabMenuActive,
        rows,
        first,
        selectedStatuses,
    ]);

    useEffect(() => {
        setDataTable(DashboardPlanData?.data?.result?.items);
    }, [DashboardPlanData]);

    useEffect(() => {
        const updateDataTable = () => {
            const status =
                DashboardPlanData?.data?.listStatus[tabMenuActive]?.label;
            const filteredData =
                status === "Tất cả"
                    ? DashboardPlanData?.data?.result?.items
                    : DashboardPlanData?.data?.result?.items.filter(
                          (item) => item.status === status
                      );
            setDataTable(filteredData);
        };
        updateDataTable();
    }, [tabMenuActive]);

    //export excel

    const { buildingData, exportExcelQuantity, exportExcelQuantityError } =
        useSelector(
            (state) => ({
                exportExcelQuantityError:
                    state.DashboardQuantity.errorExcelData,
                exportExcelQuantity: state.DashboardQuantity.excelData,
                buildingData: state.Production.dataBuilding,
            }),
            shallowEqual
        );

    const exportExcelHandle = () => {
        const id = buildingData?.data?.items.find(
            (item) => item.buildingCode === building
        )?.id;

        if (id) {
            dispatch(
                downloadExcelQuantity({
                    type: "plan-statistics",
                    building_id: id,
                    timeStartReq: formatDateApi(startDate),
                    timeEndReq: formatDateApi(endDate),
                })
            );
        } else {
            dispatch(
                downloadExcelQuantity({
                    type: "plan-statistics",
                    timeStartReq: formatDateApi(startDate),
                    timeEndReq: formatDateApi(endDate),
                })
            );
        }
    };

    useEffect(() => {
        if (exportExcelQuantity) {
            const url = `${site}/${exportExcelQuantity.file}`;
            toast.current.show({
                severity: "success",
                summary: "Thành công",
                detail: "Xuất excel thành công.",
                life: 3000,
            });
            dispatch(setExcelDataQuantityNull());

            // Tự động tải file
            downloadLink.current.href = url;
            downloadLink.current.download = "exported_data.xlsx";
            downloadLink.current.click();
        }
    }, [dispatch, exportExcelQuantity]);

    useEffect(() => {
        if (exportExcelQuantityError) {
            toast.current.show({
                severity: "error",
                summary: "Thất bại",
                detail: exportExcelQuantityError.response.data.message,
                life: 3000,
            });
            dispatch(setExcelDataQuantityNull());
        }
    }, [dispatch, exportExcelQuantityError]);

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
    };

    const handleDetailScanned = (detail) => {
        setQrDetail(detail);
    };

    const handleSelectionChange = (e) => {
        const selected = e.value || [];
        const currentPageItems = dataTable || [];

        // Loại bỏ các phần tử thuộc trang hiện tại khỏi selectedProducts
        const filteredProducts =
            selectedProducts?.filter(
                (item) => !currentPageItems.some((row) => row.id === item.id)
            ) || [];

        // Gộp các phần tử không bị xóa với phần tử vừa chọn
        const updatedSelection = [...filteredProducts, ...selected];

        // Loại bỏ phần tử trùng lặp
        const uniqueSelection = Array.from(
            new Map(updatedSelection.map((item) => [item.id, item])).values()
        );

        setSelectedProducts(uniqueSelection);
    };

    // const handleExportClick = (type) => {
    //     if (!isDataLoaded) {
    //         toast.current.show({
    //             severity: "warn",
    //             summary: "Cảnh báo",
    //             detail: "Vui lòng đợi dữ liệu được tải đầy đủ.",
    //             life: 3000,
    //         });
    //         return;
    //     }
    //     exportPlanPDF(
    //         type,
    //         FormatDate(startDate),
    //         FormatDate(endDate),
    //         allData,
    //         nameBuilding
    //     );
    // };

    const handleExportClick = async (type) => {
        if (startDate && endDate) {
            try {
                // Fetch the first page's data to get the total pages for pagination
                const response = await axios.get(
                    `${site}/api/dashboard/plan-statistics`,
                    {
                        params: {
                            page: page,
                            limit: 100,
                            timeStartReq: startDate,
                            timeEndReq: endDate,
                            building,
                            orderCode,
                            category,
                            factoryCode,
                            filterStatus: JSON.stringify(selectedStatuses),
                        },
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json", // Fix header key
                        },
                        withCredentials: true,
                    }
                );

                const totalPages = response?.data?.data?.result?.totalPages;

                const allDataArray = [];
                const requests = [];

                // Fetch data for all pages concurrently
                for (let page = 1; page <= totalPages; page++) {
                    requests.push(
                        axios.get(`${site}/api/dashboard/plan-statistics`, {
                            params: {
                                page: page,
                                limit: 100,
                                timeStartReq: startDate,
                                timeEndReq: endDate,
                                building,
                                orderCode,
                                category,
                                factoryCode,
                                filterStatus: JSON.stringify(selectedStatuses),
                            },
                            headers: {
                                Authorization: `Bearer ${token}`,
                                "Content-Type": "application/json", // Fix header key
                            },
                            withCredentials: true,
                        })
                    );
                }

                const responses = await Promise.all(requests);

                responses.forEach((res) => {
                    allDataArray.push(...res.data?.data?.result.items);
                });

                // Trigger the export logic
                exportPlanPDF(
                    type,
                    FormatDate(startDate),
                    FormatDate(endDate),
                    allDataArray,
                    nameBuilding
                );
            } catch (error) {
                console.error("Error fetching data:", error);
                toast.current.show({
                    severity: "error",
                    summary: "Lỗi",
                    detail: "Có lỗi xảy ra khi tải dữ liệu.",
                    life: 3000,
                });
            }
        } else {
            console.log("Start Date or End Date is missing");
        }
    };

    return (
        <>
            <Toast ref={toast} />
            <a ref={downloadLink} style={{ display: "none" }} />
            <HeaderReport
                building={building}
                orderCode={orderCode}
                category={category}
                factoryCode={factoryCode}
                setBuilding={setBuilding}
                setOrderCode={setOrderCode}
                setCategory={setCategory}
                setFactoryCode={setFactoryCode}
                setStartDateOutput={setStartDate}
                setEndDateOutput={setEndDate}
                exportClick={(e) => handleExportClick("save")}
                printReport={(e) => handleExportClick("print")}
                exportExcel={exportExcelHandle}
            />
            <div className="bg-white border-round-md shadow-1 px-3 mt-3">
                <div className="flex md:justify-content-between md:align-items-center flex-column md:flex-row gap-4">
                    <TabMenu
                        model={DashboardPlanData?.data?.listStatus.map(
                            (item, index) => ({
                                ...item,
                                template: () =>
                                    tabMenuTemplate(
                                        item,
                                        index,
                                        tabMenuActive,
                                        setTabmenuActive
                                    ),
                            })
                        )}
                        activeIndex={tabMenuActive}
                        onTabChange={(e) => setTabmenuActive(e.index)}
                        pt={{ menuitem: "p-0" }}
                        style={{ maxWidth: "70%" }}
                    />
                    <div className="flex gap-4">
                        <CustomMultiSelect
                            value={selectedStatuses}
                            onChange={handleStatusChange}
                            className="max-w-14rem"
                        />
                        <Button
                            label="Lọc theo hạng mục"
                            onClick={() => setOpenModal(true)}
                        ></Button>
                    </div>
                </div>
                {DashboardPlanData && DashboardPlanData.data ? (
                    <div>
                        <DataTable
                            value={dataTable}
                            rowClassName="custom-row-class"
                            size="small"
                            emptyMessage="Không có dữ liệu"
                            scrollable
                            filters={filters}
                            onFilter={(e) => setFilters(e.filters)}
                            scrollHeight="65vh"
                            selectionMode="checkbox"
                            selection={selectedProducts || []}
                            onSelectionChange={handleSelectionChange}
                            dataKey="id"
                        >
                            <Column selectionMode="multiple"></Column>
                            <Column
                                header="STT"
                                body={(rowData, options) => (
                                    <div className="text-center">
                                        {first + options.rowIndex + 1}
                                    </div>
                                )}
                                style={{ width: "3%" }}
                                alignHeader={"center"}
                                align={"center"}
                            ></Column>
                            <Column
                                header="Tên công trình"
                                field="Building.name"
                                style={{ minWidth: "150px" }}
                                filter
                                filterPlaceholder="Search by name"
                            ></Column>
                            <Column
                                header="Mã ĐH"
                                field="orderCode"
                                style={{ minWidth: "100px" }}
                                filter
                            ></Column>
                            <Column
                                header="Mã nhà máy"
                                field="factoryCode"
                                style={{ minWidth: "150px" }}
                                filter
                            ></Column>
                            <Column
                                header="Hạng mục"
                                field="categoryName"
                                style={{ lineHeight: "1.5", minWidth: "200px" }}
                                filter
                            ></Column>
                            <Column
                                header="Hình ảnh"
                                body={imageTemplate}
                                alignHeader={"center"}
                                align={"center"}
                                style={{ minWidth: "100px" }}
                            ></Column>
                            <Column
                                header="SL"
                                field="quantity"
                                style={{ minWidth: "70px" }}
                            ></Column>
                            <Column
                                header="ĐVT"
                                field="unit"
                                style={{ minWidth: "50px" }}
                                filter
                            ></Column>
                            <Column
                                header="Mã sản phẩm"
                                field="productCode"
                                style={{ minWidth: "150px" }}
                                filter
                            ></Column>
                            <Column
                                header="Kế hoạch hoàn thành"
                                field="intendFinishDate"
                                style={{ minWidth: "120px" }}
                                body={(rowData) => (
                                    <div
                                        style={{
                                            backgroundColor: `${rowData.color}`,
                                            padding: "4px 0",
                                            margin: "0 26px",
                                            borderRadius: "5px",
                                        }}
                                    >
                                        {FormatDate(rowData.intendFinishDate)}
                                    </div>
                                )}
                                sortable
                                align="center"
                                alignHeader="center"
                            ></Column>
                            <Column
                                header="Trạng thái"
                                field="status"
                                body={statusTemplate}
                                alignHeader={"center"}
                                align={"center"}
                                style={{ minWidth: "150px" }}
                                // filter
                                // filterElement={statusFilterTemplate}
                                // pt={{
                                //     filterButtonbar: { className: "hidden" },
                                // }}
                            ></Column>
                            <Column
                                header="Số lượng kế hoạch"
                                field="totalProductionPlan"
                                body={(rowData) => (
                                    <div>
                                        {formatQuantity(
                                            rowData.totalProductionPlan
                                        )}
                                    </div>
                                )}
                                style={{ minWidth: "100px" }}
                                alignHeader={"center"}
                                align={"center"}
                                footer={
                                    <div className="text-base">
                                        {formatQuantity(
                                            DashboardPlanData?.data?.totals
                                                ?.totalProductionPlan
                                        )}
                                    </div>
                                }
                            ></Column>
                            <Column
                                header="Số lượng giao thực tế"
                                field="totalActualDelivery"
                                style={{ minWidth: "120px" }}
                                alignHeader={"center"}
                                align={"center"}
                            ></Column>
                            <Column
                                header="Định vị"
                                body={(rowData) => (
                                    <div className="text-center">
                                        <Button
                                            onClick={() =>
                                                handleTeamProgress(rowData)
                                            }
                                            icon="pi pi-map-marker"
                                            rounded
                                            text
                                            severity="secondary"
                                            aria-label="Bookmark"
                                            style={{ color: "black" }}
                                        />
                                        <p>
                                            {rowData.status !==
                                                "Hoàn thành đúng tiến độ" &&
                                            rowData.status !==
                                                "Hoàn thành trễ tiến độ"
                                                ? rowData.teamWorking
                                                : "Đã giao"}
                                        </p>
                                    </div>
                                )}
                                alignHeader={"center"}
                                align={"center"}
                                style={{ minWidth: "100px" }}
                            ></Column>
                            <Column
                                header="In QR"
                                body={(rowData) => (
                                    <div className="text-center">
                                        <Button
                                            onClick={() => {
                                                setQrDetail(rowData);
                                                setVisible3(true);
                                            }}
                                            icon="pi pi-qrcode"
                                            rounded
                                            text
                                            severity="secondary"
                                            aria-label="QR"
                                            style={{ color: "black" }}
                                        />
                                    </div>
                                )}
                                style={{ width: "8%", minWidth: "100px" }}
                                alignHeader={"center"}
                                align={"center"}
                            ></Column>
                            <Column
                                header="Ghi chú"
                                field="productNote"
                                style={{ minWidth: "200px" }}
                            ></Column>
                        </DataTable>
                        <div className="card">
                            <Paginator
                                first={first}
                                rows={rows}
                                totalRecords={
                                    DashboardPlanData?.data?.result?.totalItems
                                }
                                rowsPerPageOptions={[10, 20, 30, 100, 200, 500]}
                                onPageChange={onPageChange}
                            />
                        </div>
                        {dialogLocationVisible && (
                            <Intersection
                                visible={dialogLocationVisible}
                                onHide={() => setDialogLocationVisible(false)}
                                factoryCode={selectFactoryCode}
                                rowData={selectedRow}
                            />
                        )}
                        <ModalFilterCategoryReportPlan
                            visible={openModal}
                            onHide={() => setOpenModal(false)}
                            data={selectedProducts}
                        />
                        <QR
                            isShow={visible3}
                            hiden={() => setVisible3(false)}
                            detail={qrDetail}
                            onDetailScanned={handleDetailScanned}
                        />
                    </div>
                ) : (
                    <LoadingSpinner />
                )}
            </div>
        </>
    );
}
